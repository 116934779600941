@charset "UTF-8";
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

hr {
  overflow: visible;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: zero;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; }

b,
strong {
  font-weight: 600; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  margin: 0;
  padding: 0; }

legend {
  display: table;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  color: #000; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

@font-face {
  font-family: 'Muli-Regular';
  src: url(/assets/Muli-Regular.eot);
  src: url(/assets/Muli-Regular.eot?#iefix) format("embedded-opentype"), url(/assets/Muli-Regular.woff) format("woff"), url(/assets/Muli-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Muli-SemiBoldItalic';
  src: url(/assets/Muli-SemiBoldItalic.eot);
  src: url(/assets/Muli-SemiBoldItalic.eot?#iefix) format("embedded-opentype"), url(/assets/Muli-SemiBoldItalic.woff) format("woff"), url(/assets/Muli-SemiBoldItalic.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Muli-Bold';
  src: url(/assets/Muli-Bold.eot);
  src: url(/assets/Muli-Bold.eot?#iefix) format("embedded-opentype"), url(/assets/Muli-Bold.woff) format("woff"), url(/assets/Muli-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HelveticaNeueLTStd-BdCn";
  src: url(/assets/HelveticaNeueLTStd-BdCn.eot);
  src: url(/assets/HelveticaNeueLTStd-BdCn.eot?#iefix) format("embedded-opentype"), url(/assets/HelveticaNeueLTStd-BdCn.woff) format("woff"), url(/assets/HelveticaNeueLTStd-BdCn.woff2) format("woff2"), url(/assets/HelveticaNeueLTStd-BdCn.ttf) format("truetype"), url(/assets/HelveticaNeueLTStd-BdCn.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HelveticaNeueLTStd-BlkCn";
  src: url(/assets/HelveticaNeueLTStd-BlkCn.eot);
  src: url(/assets/HelveticaNeueLTStd-BlkCn.eot?#iefix) format("embedded-opentype"), url(/assets/HelveticaNeueLTStd-BlkCn.woff) format("woff"), url(/assets/HelveticaNeueLTStd-BlkCn.woff2) format("woff2"), url(/assets/HelveticaNeueLTStd-BlkCn.ttf) format("truetype"), url(/assets/HelveticaNeueLTStd-BlkCn.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HelveticaNeueLTStd-Cn";
  src: url(/assets/HelveticaNeueLTStd-Cn.eot);
  src: url(/assets/HelveticaNeueLTStd-Cn.eot?#iefix) format("embedded-opentype"), url(/assets/HelveticaNeueLTStd-Cn.woff) format("woff"), url(/assets/HelveticaNeueLTStd-Cn.woff2) format("woff2"), url(/assets/HelveticaNeueLTStd-Cn.ttf) format("truetype"), url(/assets/HelveticaNeueLTStd-Cn.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HelveticaNeueLTStd-Lt";
  src: url(/assets/HelveticaNeueLTStd-Lt.eot);
  src: url(/assets/HelveticaNeueLTStd-Lt.eot?#iefix) format("embedded-opentype"), url(/assets/HelveticaNeueLTStd-Lt.woff) format("woff"), url(/assets/HelveticaNeueLTStd-Lt.woff2) format("woff2"), url(/assets/HelveticaNeueLTStd-Lt.ttf) format("truetype"), url(/assets/HelveticaNeueLTStd-Lt.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HelveticaNeueLTStd-Md";
  src: url(/assets/HelveticaNeueLTStd-Md.eot);
  src: url(/assets/HelveticaNeueLTStd-Md.eot?#iefix) format("embedded-opentype"), url(/assets/HelveticaNeueLTStd-Md.woff) format("woff"), url(/assets/HelveticaNeueLTStd-Md.woff2) format("woff2"), url(/assets/HelveticaNeueLTStd-Md.ttf) format("truetype"), url(/assets/HelveticaNeueLTStd-Md.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "HelveticaNeueLTStd-MdCn";
  src: url(/assets/HelveticaNeueLTStd-MdCn.eot);
  src: url(/assets/HelveticaNeueLTStd-MdCn.eot?#iefix) format("embedded-opentype"), url(/assets/HelveticaNeueLTStd-MdCn.woff) format("woff"), url(/assets/HelveticaNeueLTStd-MdCn.woff2) format("woff2"), url(/assets/HelveticaNeueLTStd-MdCn.ttf) format("truetype"), url(/assets/HelveticaNeueLTStd-MdCn.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "NoeDisplay-Medium";
  src: url(/assets/NoeDisplay-Medium.eot);
  src: url(/assets/NoeDisplay-Medium.eot?#iefix) format("embedded-opentype"), url(/assets/NoeDisplay-Medium.woff) format("woff"), url(/assets/NoeDisplay-Medium.ttf) format("truetype"), url(/assets/NoeDisplay-Medium.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "NoeDisplay-MediumItalic";
  src: url(/assets/NoeDisplay-MediumItalic.eot);
  src: url(/assets/NoeDisplay-MediumItalic.eot?#iefix) format("embedded-opentype"), url(/assets/NoeDisplay-MediumItalic.woff) format("woff"), url(/assets/NoeDisplay-MediumItalic.ttf) format("truetype"), url(/assets/NoeDisplay-MediumItalic.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "NoeDisplay-Bold";
  src: url(/assets/NoeDisplay-Bold.eot);
  src: url(/assets/NoeDisplay-Bold.eot?#iefix) format("embedded-opentype"), url(/assets/NoeDisplay-Bold.woff) format("woff"), url(/assets/NoeDisplay-Bold.woff2) format("woff2"), url(/assets/NoeDisplay-Bold.ttf) format("truetype"), url(/assets/NoeDisplay-Bold.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "PlayfairDisplay-BoldItalic";
  src: url(/assets/PlayfairDisplay-BoldItalic.eot);
  src: url(/assets/PlayfairDisplay-BoldItalic.eot?#iefix) format("embedded-opentype"), url(/assets/PlayfairDisplay-BoldItalic.woff) format("woff"), url(/assets/PlayfairDisplay-BoldItalic.woff2) format("woff2"), url(/assets/PlayfairDisplay-BoldItalic.ttf) format("truetype"), url(/assets/PlayfairDisplay-BoldItalic.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "PlayfairDisplay-Bold";
  src: url(/assets/PlayfairDisplay-Bold.eot);
  src: url(/assets/PlayfairDisplay-Bold.eot?#iefix) format("embedded-opentype"), url(/assets/PlayfairDisplay-Bold.woff) format("woff"), url(/assets/PlayfairDisplay-Bold.woff2) format("woff2"), url(/assets/PlayfairDisplay-Bold.ttf) format("truetype"), url(/assets/PlayfairDisplay-Bold.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "PlayfairDisplay-Italic";
  src: url(/assets/PlayfairDisplay-Italic.eot);
  src: url(/assets/PlayfairDisplay-Italic.eot?#iefix) format("embedded-opentype"), url(/assets/PlayfairDisplay-Italic.woff) format("woff"), url(/assets/PlayfairDisplay-Italic.woff2) format("woff2"), url(/assets/PlayfairDisplay-Italic.ttf) format("truetype"), url(/assets/PlayfairDisplay-Italic.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "PlayfairDisplay-Regular";
  src: url(/assets/PlayfairDisplay-Regular.eot);
  src: url(/assets/PlayfairDisplay-Regular.eot?#iefix) format("embedded-opentype"), url(/assets/PlayfairDisplay-Regular.woff) format("woff"), url(/assets/PlayfairDisplay-Regular.woff2) format("woff2"), url(/assets/PlayfairDisplay-Regular.ttf) format("truetype"), url(/assets/PlayfairDisplay-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "ActionCondensedBold-Grade2";
  src: url(/assets/ActionCondensedBold-Grade2.eot);
  src: url(/assets/ActionCondensedBold-Grade2.eot?#iefix) format("embedded-opentype"), url(/assets/ActionCondensedBold-Grade2.woff) format("woff"), url(/assets/ActionCondensedBold-Grade2.woff2) format("woff2"), url(/assets/ActionCondensedBold-Grade2.ttf) format("truetype"), url(/assets/ActionCondensedBold-Grade2.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "DINPro-CondBlack";
  src: url(/assets/DINPro-CondBlack.eot);
  src: url(/assets/DINPro-CondBlack.eot?#iefix) format("embedded-opentype"), url(/assets/DINPro-CondBlack.woff) format("woff"), url(/assets/DINPro-CondBlack.woff2) format("woff2"), url(/assets/DINPro-CondBlack.ttf) format("truetype"), url(/assets/DINPro-CondBlack.otf) format("opentype");
  font-weight: normal;
  font-style: normal; }

html {
  font-family: "arial";
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal; }

h1,
.h1 {
  margin: 0 0 0.6875rem;
  font-size: 1.375rem; }
  h1 + *,
  .h1 + * {
    margin-top: 1.625rem; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      h1 + *,
      .h1 + * {
        margin-top: 2rem; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    h1.h2,
    .h1.h2 {
      line-height: 3.25rem; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    h1,
    .h1 {
      margin: 0 0 0.78125rem;
      font-size: 1.875rem; } }

h2,
.h2 {
  margin: 0 0 1.625rem;
  font-size: 2.25rem;
  line-height: 2.625rem; }
  h2 + p,
  .h2 + p {
    margin-top: -0.8rem; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      h2 + p,
      .h2 + p {
        margin-top: -0.795rem; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    h2,
    .h2 {
      margin: 0 0 2.3125rem;
      font-size: 3rem; } }

h3 {
  margin: 0 0 1rem;
  font-size: 1.375rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    h3 {
      margin: 0 0 0.5625rem;
      font-size: 1.75rem; } }

h4 {
  margin: 0 0 0.8125rem;
  font-size: 1.375rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    h4 {
      margin: 0 0 0.6875rem;
      font-size: 1.375rem; } }

h5 {
  margin: 0;
  font-size: 1.25rem; }

h6 {
  font-size: 1.374rem; }

p {
  margin: 0 0 1rem;
  font-size: 0.875rem;
  line-height: 1.55rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    p {
      font-size: 1rem; } }

.emphasize-text, .btn--red-stroke, .film-overview__category {
  font-size: 12px;
  letter-spacing: 0.025rem;
  text-transform: uppercase; }

.text-white {
  color: #fff; }

.cta {
  text-align: center; }

.section-description {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 47.25rem;
  font-size: 0.875rem;
  line-height: 1.35625rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .section-description {
      margin-bottom: 1rem;
      font-size: 1.125rem;
      line-height: 1.725rem; } }

.section-description--extra-margin {
  margin-bottom: 1.5rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .section-description--extra-margin {
      margin-bottom: 1.8rem; } }

.background-red {
  background: #cb352e; }

.background-red-darker {
  background: #5b1815; }

.background-pink {
  background: #faccc9; }

.background-black {
  background: #000; }

.position-relative {
  z-index: 1;
  position: relative; }

.max-width-wrapper,
.content-wrapper {
  width: 100%; }
  @media (min-width: 64.0625rem) {
    .max-width-wrapper,
    .content-wrapper {
      max-width: 75rem; } }


.content-wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding: 0 1.25rem; }
  @media (min-width: 64.0625rem) {
    
    .content-wrapper {
      padding-right: 10px;
      padding-left: 10px; } }
  @media (min-width: 80rem) {
    
    .content-wrapper {
      padding-right: 0;
      padding-left: 0; } }
  
  .content-wrapper +
  .content-wrapper {
    margin-top: 3.75rem; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      
      .content-wrapper +
      .content-wrapper {
        margin-top: 6.25rem; } }

.content-wrapper--max {
  position: relative;
  margin: auto;
  max-width: 1600px;
  padding: 0; }

.content-wrapper--static {
  position: static; }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .content-wrapper-tablet {
    padding: 0 1.25rem; } }

@media (min-width: 64.0625rem) {
  .content-wrapper-tablet {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    max-width: 75rem;
    padding: 0; } }

@media (min-width: 64.0625rem) {
  .content-wrapper-desktop {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    max-width: 75rem; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .two-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .two-col > * {
      margin-right: 1.25rem;
      margin-bottom: 1.25rem;
      width: calc(50% - 0.625rem - 1px);
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .two-col > *:nth-child(1n+1) {
        margin-right: 1.25rem; }
      .two-col > *:nth-child(2n+2) {
        margin-right: 0; }
      .two-col > *:last-child {
        margin-right: 0; } }

@media (min-width: 64rem) {
  .two-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .two-col > * {
      margin-right: 2.25rem;
      margin-bottom: 2.25rem;
      width: calc(50% - 1.125rem - 1px);
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .two-col > *:nth-child(1n+1) {
        margin-right: 2.25rem; }
      .two-col > *:nth-child(2n+2) {
        margin-right: 0; }
      .two-col > *:last-child {
        margin-right: 0; } }

.three-col > * + * {
  margin-top: 1.25rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .three-col > * + * {
      margin-top: 0; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .three-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .three-col > * {
      margin-right: 1.25rem;
      margin-bottom: 1.25rem;
      width: calc(33.3% - 0.83333rem - 1px);
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .three-col > *:nth-child(1n+1) {
        margin-right: 1.25rem; }
      .three-col > *:nth-child(2n+2) {
        margin-right: 1.25rem; }
      .three-col > *:nth-child(3n+3) {
        margin-right: 0; }
      .three-col > *:last-child {
        margin-right: 0; } }

@media (min-width: 64rem) {
  .three-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .three-col > * {
      margin-right: 2.25rem;
      margin-bottom: 2.25rem;
      width: calc(33.3% - 1.5rem - 1px);
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .three-col > *:nth-child(1n+1) {
        margin-right: 2.25rem; }
      .three-col > *:nth-child(2n+2) {
        margin-right: 2.25rem; }
      .three-col > *:nth-child(3n+3) {
        margin-right: 0; }
      .three-col > *:last-child {
        margin-right: 0; } }

.three-col-editorial > * + * {
  margin-top: 1.25rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .three-col-editorial > * + * {
      margin-top: 0; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .three-col-editorial {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .three-col-editorial > * {
      margin-right: 60px;
      margin-bottom: 0;
      width: calc(33.3% - 40px - 1px);
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .three-col-editorial > *:nth-child(1n+1) {
        margin-right: 60px; }
      .three-col-editorial > *:nth-child(2n+2) {
        margin-right: 60px; }
      .three-col-editorial > *:nth-child(3n+3) {
        margin-right: 0; }
      .three-col-editorial > *:last-child {
        margin-right: 0; } }

.three-col-editorial article {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.two-three-col > * + * {
  margin-top: 1.25rem; }
  @media (min-width: 45.625rem) {
    .two-three-col > * + * {
      margin-top: 0; } }

@media (min-width: 45.625rem) {
  .two-three-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .two-three-col > * {
      margin-right: 1.25rem;
      margin-bottom: 1.25rem;
      width: calc(50% - 0.625rem - 1px);
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .two-three-col > *:nth-child(1n+1) {
        margin-right: 1.25rem; }
      .two-three-col > *:nth-child(2n+2) {
        margin-right: 0; }
      .two-three-col > *:last-child {
        margin-right: 0; } }

@media (min-width: 64rem) {
  .two-three-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .two-three-col > * {
      margin-right: 2.25rem;
      margin-bottom: 2.25rem;
      width: calc(33.3% - 1.5rem - 1px);
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .two-three-col > *:nth-child(1n+1) {
        margin-right: 2.25rem; }
      .two-three-col > *:nth-child(2n+2) {
        margin-right: 2.25rem; }
      .two-three-col > *:nth-child(3n+3) {
        margin-right: 0; }
      .two-three-col > *:last-child {
        margin-right: 0; } }

.two-three-four-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .two-three-four-col > * {
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
    width: calc(50% - 0.625rem - 1px);
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    .two-three-four-col > *:nth-child(1n+1) {
      margin-right: 1.25rem; }
    .two-three-four-col > *:nth-child(2n+2) {
      margin-right: 0; }
    .two-three-four-col > *:last-child {
      margin-right: 0; }
  @media (min-width: 45.625rem) {
    .two-three-four-col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      .two-three-four-col > * {
        margin-right: 1.25rem;
        margin-bottom: 1.25rem;
        width: calc(33.3% - 0.83333rem - 1px);
        -ms-flex-negative: 0;
            flex-shrink: 0; }
        .two-three-four-col > *:nth-child(1n+1) {
          margin-right: 1.25rem; }
        .two-three-four-col > *:nth-child(2n+2) {
          margin-right: 1.25rem; }
        .two-three-four-col > *:nth-child(3n+3) {
          margin-right: 0; }
        .two-three-four-col > *:last-child {
          margin-right: 0; } }
  @media (min-width: 64rem) {
    .two-three-four-col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      .two-three-four-col > * {
        margin-right: 2.0625rem;
        margin-bottom: 2.0625rem;
        width: calc(25% - 1.54688rem - 1px);
        -ms-flex-negative: 0;
            flex-shrink: 0; }
        .two-three-four-col > *:nth-child(1n+1) {
          margin-right: 2.0625rem; }
        .two-three-four-col > *:nth-child(2n+2) {
          margin-right: 2.0625rem; }
        .two-three-four-col > *:nth-child(3n+3) {
          margin-right: 2.0625rem; }
        .two-three-four-col > *:nth-child(4n+4) {
          margin-right: 0; }
        .two-three-four-col > *:last-child {
          margin-right: 0; } }

.two-three-four-six-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .two-three-four-six-col > * {
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
    width: calc(50% - 0.625rem - 1px);
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    .two-three-four-six-col > *:nth-child(1n+1) {
      margin-right: 1.25rem; }
    .two-three-four-six-col > *:nth-child(2n+2) {
      margin-right: 0; }
    .two-three-four-six-col > *:last-child {
      margin-right: 0; }
  @media (min-width: 45.625rem) {
    .two-three-four-six-col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      .two-three-four-six-col > * {
        margin-right: 1.25rem;
        margin-bottom: 1.25rem;
        width: calc(33.3% - 0.83333rem - 1px);
        -ms-flex-negative: 0;
            flex-shrink: 0; }
        .two-three-four-six-col > *:nth-child(1n+1) {
          margin-right: 1.25rem; }
        .two-three-four-six-col > *:nth-child(2n+2) {
          margin-right: 1.25rem; }
        .two-three-four-six-col > *:nth-child(3n+3) {
          margin-right: 0; }
        .two-three-four-six-col > *:last-child {
          margin-right: 0; } }
  @media (min-width: 64rem) {
    .two-three-four-six-col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      .two-three-four-six-col > * {
        margin-right: 2.0625rem;
        margin-bottom: 2.0625rem;
        width: calc(25% - 1.54688rem - 1px);
        -ms-flex-negative: 0;
            flex-shrink: 0; }
        .two-three-four-six-col > *:nth-child(1n+1) {
          margin-right: 2.0625rem; }
        .two-three-four-six-col > *:nth-child(2n+2) {
          margin-right: 2.0625rem; }
        .two-three-four-six-col > *:nth-child(3n+3) {
          margin-right: 2.0625rem; }
        .two-three-four-six-col > *:nth-child(4n+4) {
          margin-right: 0; }
        .two-three-four-six-col > *:last-child {
          margin-right: 0; } }
  @media (min-width: 64.0625rem) {
    .two-three-four-six-col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      .two-three-four-six-col > * {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        width: calc(16.7% - 0.41667rem - 1px);
        -ms-flex-negative: 0;
            flex-shrink: 0; }
        .two-three-four-six-col > *:nth-child(1n+1) {
          margin-right: 0.5rem; }
        .two-three-four-six-col > *:nth-child(2n+2) {
          margin-right: 0.5rem; }
        .two-three-four-six-col > *:nth-child(3n+3) {
          margin-right: 0.5rem; }
        .two-three-four-six-col > *:nth-child(4n+4) {
          margin-right: 0.5rem; }
        .two-three-four-six-col > *:nth-child(5n+5) {
          margin-right: 0.5rem; }
        .two-three-four-six-col > *:nth-child(6n+6) {
          margin-right: 0; }
        .two-three-four-six-col > *:last-child {
          margin-right: 0; } }

.two-three-five-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .two-three-five-col > * {
    margin-right: 1.25rem;
    margin-bottom: 2rem;
    width: calc(33.3% - 0.83333rem - 1px);
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    .two-three-five-col > *:nth-child(1n+1) {
      margin-right: 1.25rem; }
    .two-three-five-col > *:nth-child(2n+2) {
      margin-right: 1.25rem; }
    .two-three-five-col > *:nth-child(3n+3) {
      margin-right: 0; }
    .two-three-five-col > *:last-child {
      margin-right: 0; }
  @media (min-width: 31.25rem) {
    .two-three-five-col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      .two-three-five-col > * {
        margin-right: 1.25rem;
        margin-bottom: 3rem;
        width: calc(25% - 0.9375rem - 1px);
        -ms-flex-negative: 0;
            flex-shrink: 0; }
        .two-three-five-col > *:nth-child(1n+1) {
          margin-right: 1.25rem; }
        .two-three-five-col > *:nth-child(2n+2) {
          margin-right: 1.25rem; }
        .two-three-five-col > *:nth-child(3n+3) {
          margin-right: 1.25rem; }
        .two-three-five-col > *:nth-child(4n+4) {
          margin-right: 0; }
        .two-three-five-col > *:last-child {
          margin-right: 0; } }
  @media (min-width: 43.75rem) {
    .two-three-five-col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      .two-three-five-col > * {
        margin-right: 0.9375rem;
        margin-bottom: 4rem;
        width: calc(20% - 0.75rem - 1px);
        -ms-flex-negative: 0;
            flex-shrink: 0; }
        .two-three-five-col > *:nth-child(1n+1) {
          margin-right: 0.9375rem; }
        .two-three-five-col > *:nth-child(2n+2) {
          margin-right: 0.9375rem; }
        .two-three-five-col > *:nth-child(3n+3) {
          margin-right: 0.9375rem; }
        .two-three-five-col > *:nth-child(4n+4) {
          margin-right: 0.9375rem; }
        .two-three-five-col > *:nth-child(5n+5) {
          margin-right: 0; }
        .two-three-five-col > *:last-child {
          margin-right: 0; } }

.two-three-five-col-films {
  margin-top: 22px;
  display: grid;
  grid-gap: 22px;
  grid-auto-rows: 319px;
  grid-template-columns: repeat(2, 154px); }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .two-three-five-col-films {
      margin-top: 80px; } }
  @media (max-width: 45.625rem) {
    .two-three-five-col-films .film-card__link {
      width: 154px; }
    .two-three-five-col-films .film-card__image {
      width: 154px;
      height: 228px; } }
  @media (min-width: 23.5rem) {
    .two-three-five-col-films {
      grid-gap: 44px;
      grid-template-columns: repeat(2, 154px); } }
  @media (min-width: 45.625rem) {
    .two-three-five-col-films {
      width: 696px;
      grid-gap: 44px;
      grid-auto-rows: 391px;
      grid-template-columns: repeat(3, 203px); } }
  @media (min-width: 60rem) {
    .two-three-five-col-films {
      width: 944px;
      grid-template-columns: repeat(4, 203px); } }
  @media (min-width: 80rem) {
    .two-three-five-col-films {
      width: 1185px;
      grid-template-columns: repeat(5, 203px); } }
  @media (min-width: 96.875rem) {
    .two-three-five-col-films {
      width: 1438px;
      grid-gap: 44px;
      grid-template-columns: repeat(6, 203px); } }

.films-all__filter-cards-grid {
  margin-top: 22px;
  display: grid;
  grid-gap: 22px;
  grid-auto-rows: 319px;
  grid-template-columns: repeat(1, 154px); }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .films-all__filter-cards-grid {
      margin-top: 82px; } }
  @media (max-width: 45.625rem) {
    .films-all__filter-cards-grid .film-card__link {
      width: 154px; }
    .films-all__filter-cards-grid .film-card__image {
      width: 154px;
      height: 228px; } }
  @media (min-width: 23.5rem) {
    .films-all__filter-cards-grid {
      grid-template-columns: repeat(1, 154px); } }
  @media (min-width: 45.625rem) {
    .films-all__filter-cards-grid {
      width: 450px;
      grid-gap: 44px;
      grid-auto-rows: 391px;
      grid-template-columns: repeat(2, 203px); } }
  @media (min-width: 60rem) {
    .films-all__filter-cards-grid {
      width: 696px;
      grid-template-columns: repeat(3, 203px); } }
  @media (min-width: 80rem) {
    .films-all__filter-cards-grid {
      width: 944px;
      grid-template-columns: repeat(4, 203px); } }
  @media (min-width: 96.875rem) {
    .films-all__filter-cards-grid {
      width: 1191px;
      grid-template-columns: repeat(5, 203px); } }

.grid + .grid {
  margin-top: 1.25rem; }
  @media (min-width: 64rem) {
    .grid + .grid {
      margin-top: 2.25rem; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; } }

.grid__column {
  width: 100%; }
  .grid__column + .grid__column {
    margin-top: 1.25rem; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .grid__column + .grid__column {
        margin-top: 0; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .grid--one-two > *:first-child {
    -ms-flex-preferred-size: 18.5rem;
        flex-basis: 18.5rem;
    -ms-flex-negative: 0;
        flex-shrink: 0; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .grid--one-two > *:last-child {
    padding-left: 1.25rem;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; } }

@media (min-width: 64rem) {
  .grid--one-two > *:last-child {
    padding-left: 2.25rem; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .grid--one-three > *:first-child {
    -ms-flex-preferred-size: 200px;
        flex-basis: 200px; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .grid--one-three > *:last-child {
    padding-left: 1.25rem;
    -ms-flex-preferred-size: 70%;
        flex-basis: 70%; } }

@media (min-width: 64rem) {
  .grid--one-three > *:last-child {
    padding-left: 2.25rem; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .grid--two-one > *:first-child {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .grid--two-one > *:last-child {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    padding-left: 1.25rem;
    -ms-flex-preferred-size: 18.5rem;
        flex-basis: 18.5rem;
    -ms-flex-negative: 0;
        flex-shrink: 0; } }

@media (min-width: 64rem) {
  .grid--two-one > *:last-child {
    padding-left: 2.25rem; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .grid--equal > * {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .grid--equal > * + * {
    padding-left: 1.25rem; } }

@media (min-width: 64rem) {
  .grid--equal > * + * {
    padding-left: 2.25rem; } }

.body.nav-opened {
  overflow: hidden; }
  @media (min-width: 64.0625rem) {
    .body.nav-opened {
      overflow: inherit; } }

.no-margin-mobile {
  margin-right: -1.25rem;
  margin-left: -1.25rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .no-margin-mobile {
      margin-right: 0;
      margin-left: 0; } }

.input-label, .input-icon {
  position: relative;
  display: inline-block;
  padding-left: 1.125rem;
  color: #000;
  font-size: 0.875rem;
  -webkit-transition: color 225ms;
  transition: color 225ms;
  cursor: pointer; }
  .input-label::before, .input-icon::before, .input-label::after, .input-icon::after {
    content: "";
    position: absolute;
    -webkit-transition: opacity 225ms 0.1s, -webkit-transform 225ms;
    transition: opacity 225ms 0.1s, -webkit-transform 225ms;
    transition: opacity 225ms 0.1s, transform 225ms;
    transition: opacity 225ms 0.1s, transform 225ms, -webkit-transform 225ms; }
  .input-label::before, .input-icon::before {
    top: 0.0625rem;
    left: 4px;
    -webkit-transform: scale(1);
            transform: scale(1);
    width: 23px;
    height: 23px;
    border: 1px solid #b0b0b0;
    border-radius: 50%;
    background: transparent;
    opacity: 1; }
  .input-label::after, .input-icon::after {
    top: 0;
    left: 4px;
    -webkit-transform: scale(0);
            transform: scale(0);
    width: 23px;
    height: 23px;
    border: 1px solid #f50000;
    background-color: #f50000;
    border-radius: 50%;
    background-image: url(/assets/check.svg);
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0; }

.input-label-tag {
  background: #fff;
  -webkit-transition: background 225ms;
  transition: background 225ms;
  cursor: pointer; }

input[type="checkbox"], input[type="radio"] {
  overflow: hidden;
  position: absolute;
  margin: -1px;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0); }

.input-checkbox:checked + .input-label, .input-checkbox:checked + .input-icon, .input-radio:checked + .input-label, .input-radio:checked + .input-icon {
  color: #000; }
  .input-checkbox:checked + .input-label::before, .input-checkbox:checked + .input-icon::before, .input-radio:checked + .input-label::before, .input-radio:checked + .input-icon::before {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  .input-checkbox:checked + .input-label::after, .input-checkbox:checked + .input-icon::after, .input-radio:checked + .input-label::after, .input-radio:checked + .input-icon::after {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }

.input-tag:checked + .input-label-tag {
  background: #cb352e;
  color: #fff; }

.btn, .btn--red-stroke, .film-filter__buttons-clear, .film-filter__buttons-toggle {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: inherit;
  text-decoration: none; }
  .btn[disabled], .btn--red-stroke[disabled], .film-filter__buttons-clear[disabled], .film-filter__buttons-toggle[disabled] {
    opacity: 0.5; }

.btn--red {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: inherit;
  text-decoration: none;
  font-family: "HelveticaNeueLTStd-MdCn";
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 50px;
  padding: 3px 20px 0 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #cb352e;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  -webkit-transition: all 225ms;
  transition: all 225ms;
  cursor: pointer; }
  .btn--red[disabled] {
    opacity: 0.5; }
  @media (min-width: 64.0625rem) {
    .btn--red:hover {
      background: #5b1815; } }

.btn--black {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: inherit;
  text-decoration: none;
  font-family: "HelveticaNeueLTStd-MdCn";
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 50px;
  padding: 3px 20px 0 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #000;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  -webkit-transition: all 225ms;
  transition: all 225ms;
  cursor: pointer; }
  .btn--black[disabled] {
    opacity: 0.5; }
  @media (min-width: 64.0625rem) {
    .btn--black:hover {
      background: #5b1815; } }

.btn--white {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: inherit;
  text-decoration: none;
  font-family: "HelveticaNeueLTStd-MdCn";
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 50px;
  padding: 3px 20px 0 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: transparent;
  color: #000;
  font-size: 15px;
  text-transform: uppercase;
  -webkit-transition: all 225ms;
  transition: all 225ms;
  cursor: pointer;
  border: 1px solid #d7d7d7; }
  .btn--white[disabled] {
    opacity: 0.5; }
  @media (min-width: 64.0625rem) {
    .btn--white:hover {
      background: #5b1815; } }
  .btn--white:hover {
    background: #f50000;
    border: 1px solid #f50000;
    color: #fff; }

.btn--red-stroke {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #cb352e;
  -webkit-transition: all 225ms;
  transition: all 225ms; }
  @media (min-width: 51.25rem) {
    .btn--red-stroke {
      padding: 1rem;
      border: 3px solid #cb352e;
      color: #fff; } }

.btn--large {
  height: 60px;
  width: 300px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .btn--large {
      width: 300px; } }

.btn--more {
  margin-top: 1.25rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .btn--more {
      margin-top: 3rem; } }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0;
  padding: 0;
  line-height: 1;
  margin-bottom: 30px; }
  .pagination li {
    list-style: none; }
  .pagination li a {
    margin: 0;
    padding: 0;
    line-height: 1;
    outline: 0;
    border: 0;
    background: none;
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    color: inherit;
    text-decoration: none;
    font-family: "HelveticaNeueLTStd-MdCn";
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 50px;
    padding: 3px 20px 0 20px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: transparent;
    color: #000;
    font-size: 15px;
    text-transform: uppercase;
    -webkit-transition: all 225ms;
    transition: all 225ms;
    cursor: pointer;
    height: 30px;
    padding: 3px 10px 0 10px;
    font-size: 13px;
    border: 1px solid #d7d7d7;
    margin: 0 6px 0 0; }
    .pagination li a[disabled] {
      opacity: 0.5; }
    @media (min-width: 64.0625rem) {
      .pagination li a:hover {
        background: #5b1815; } }
    .pagination li a:hover {
      background: #f50000;
      border: 1px solid #f50000;
      color: #fff; }
  .pagination li.active a {
    background: #fff;
    border: 1px solid #000;
    color: #000; }
    .pagination li.active a:hover {
      background: #fff;
      border: 1px solid #000;
      color: #000;
      cursor: unset; }
  .pagination li.disabled a {
    border: 1px solid lightgrey;
    color: lightgrey; }
    .pagination li.disabled a:hover {
      border: 1px solid lightgrey;
      color: lightgrey;
      background: inherit;
      cursor: unset; }

.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  overflow: hidden;
  position: relative;
  margin: 0;
  display: block;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  display: block; }

.slick-track:before,
.slick-track:after {
  content: "";
  display: table; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  float: left;
  display: none;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

TODO see how this does .slick-slide,
.slick-slide * {
  outline: none !important; }

.about-contact {
  padding-bottom: 3.8125rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .about-contact {
      padding-bottom: 3.9375rem; } }

.about-contact__header {
  font-size: 1.875rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .about-contact__header {
      margin-bottom: 1.875rem; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .about-contact__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; } }

.about-contact__copy {
  line-height: 1.7; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .about-contact__copy {
      -ms-flex-preferred-size: 34.0625rem;
          flex-basis: 34.0625rem; } }

.about-contact__copy-text {
  margin-bottom: 1.625rem;
  font-size: 0.875rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .about-contact__copy-text {
      margin-bottom: 0; } }

.about-contact__email {
  margin: 0;
  padding: 0;
  line-height: 1; }
  .about-contact__email li {
    list-style: none; }

.about-contact__email-item + .about-contact__email-item {
  margin-top: 0.8125rem; }

.about-contact__email-link {
  color: inherit;
  text-decoration: none; }
  @media (min-width: 64.0625rem) {
    .about-contact__email-link:hover {
      color: #5b1815; } }

.about-faq h1.site-header {
  font-family: "HelveticaNeueLTStd-BdCn";
  color: #000;
  font-size: 32px; }

.about-faq__faq-item {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding-top: 70px;
  padding-bottom: 40px; }
  .about-faq__faq-item + .about-faq__faq-item {
    margin-top: 30px;
    border-top: 0; }

.about-faq__faq-label {
  font-family: "PlayfairDisplay-Bold";
  margin-bottom: 40px;
  font-size: 22px; }

.about-faq-decorate {
  font-family: "PlayfairDisplay-Bold";
  float: left;
  margin-right: 40px;
  font-size: 22px;
  margin-bottom: 40px; }

.about-faq__faq-content {
  padding-top: 10px; }
  .about-faq__faq-content .about-faq-decorate {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px); }
  .about-faq__faq-content .about-faq__faq-text {
    margin-left: 40px; }
  .about-faq__faq-content p {
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 18px; }
  .about-faq__faq-content a {
    color: #f50000;
    text-decoration: none; }

.about-faq__faq-text {
  margin: 0;
  padding: 0 1.25rem 1.25rem; }

.site-header {
  font-family: "NoeDisplay-Medium";
  padding: 90px 0 70px 0;
  color: #f50000;
  font-size: 32px;
  letter-spacing: 0;
  text-align: center; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .site-header {
      font-size: 48px;
      padding-top: 150px;
      padding-bottom: 150px; } }

.about-header {
  background-color: #fff; }
  .about-header__hero-text {
    font-family: "HelveticaNeueLTStd-BlkCn";
    font-size: 48px;
    line-height: 1.1; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-header__hero-text {
        padding-left: 35px;
        font-size: 84px;
        letter-spacing: -1px; } }
  .about-header__detail-text {
    font-family: "HelveticaNeueLTStd-Lt";
    padding: 40px 0;
    color: #494949;
    font-size: 18px;
    line-height: 1.7; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-header__detail-text {
        padding-top: 80px;
        padding-bottom: 0;
        padding-left: 35px;
        -webkit-column-count: 2;
           -moz-column-count: 2;
                column-count: 2;
        -webkit-column-gap: 50px;
           -moz-column-gap: 50px;
                column-gap: 50px; } }
    .about-header__detail-text div {
      padding-bottom: 30px;
      -moz-column-break-inside: avoid;
           break-inside: avoid;
      page-break-inside: avoid;
      -webkit-column-break-inside: avoid; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .about-header__detail-text div {
          padding-bottom: 0; } }

.about-press {
  padding-bottom: 80px;
  background-color: #000; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .about-press {
      padding-bottom: 220px; } }
  .about-press__companies {
    margin: 0;
    padding: 0;
    line-height: 1;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .about-press__companies li {
      list-style: none; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-press__companies {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start; } }
  .about-press__header {
    font-family: "HelveticaNeueLTStd-BdCn";
    margin: 0 0 70px 0;
    padding-top: 60px;
    color: #fff;
    font-size: 15px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-press__header {
        padding-top: 170px;
        text-align: center; } }
  .about-press__companies-item {
    margin-bottom: 80px;
    text-align: center; }
  .about-press__companies-link {
    color: inherit;
    text-decoration: none;
    display: block;
    opacity: 0.6;
    -webkit-transition: opacity 400ms;
    transition: opacity 400ms; }
    .about-press__companies-link:hover {
      opacity: 1; }
  .about-press__companies-img {
    width: 70%; }
    @media (min-width: 31.25rem) {
      .about-press__companies-img {
        max-width: 50%; } }

.about-rating {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  background: #fff;
  text-align: center; }
  .about-rating h2 {
    font-family: "NoeDisplay-Medium";
    font-size: 35px;
    line-height: 1.3; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-rating h2 {
        font-size: 44px;
        line-height: 52px; } }
  .about-rating p {
    font-family: "HelveticaNeueLTStd-Lt";
    margin: auto;
    margin-top: 30px;
    max-width: 502px;
    color: #494949;
    font-size: 16px;
    line-height: 25px; }
  .about-rating__cherry-rating-link {
    margin-top: 32px;
    display: block;
    color: #f50000;
    font-size: 14px;
    text-decoration: none; }
  .about-rating__cherry-icon {
    margin-top: 7px;
    margin-right: 5px;
    width: 24px; }
  .about-rating__cherry-icon-large {
    margin-top: 30px;
    height: 100px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-rating__cherry-icon-large {
        margin-top: 70px; } }
    .about-rating__cherry-icon-large.icon-bowl {
      height: 90px; }
    .about-rating__cherry-icon-large.icon-two {
      height: 112px; }
    .about-rating__cherry-icon-large.icon-one {
      height: 87px; }
    .about-rating__cherry-icon-large.icon-nuts {
      height: 62px; }
  .about-rating__logo {
    margin: auto;
    display: block;
    width: 100px; }
  .about-rating__header {
    font-family: "HelveticaNeueLTStd-Md";
    margin-bottom: 100px;
    padding-top: 40px;
    font-size: 22px;
    line-height: 1.5; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-rating__header {
        font-family: "HelveticaNeueLTStd-Cn"; } }
  .about-rating__faq-item {
    margin-top: 80px;
    padding-top: 80px;
    border-top: 1px solid #ddd;
    color: #111; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-rating__faq-item {
        margin-top: 100px;
        padding-top: 100px; } }
    .about-rating__faq-item h2 {
      margin-bottom: 0; }
    .about-rating__faq-item h3 {
      font-family: "HelveticaNeueLTStd-BdCn";
      margin: 0;
      margin-top: 10px;
      color: #f50000;
      font-size: 20px; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .about-rating__faq-item h3 {
          margin-top: 0; } }
      .about-rating__faq-item h3 img {
        -webkit-transform: translateY(8px);
                transform: translateY(8px); }
    .about-rating__faq-item h4 {
      font-family: "HelveticaNeueLTStd-Md";
      margin: 50px 0 5px 0;
      font-size: 16px; }
    .about-rating__faq-item .cherry-btn {
      display: inline-block;
      padding: 12px 20px 8px 20px;
      border: 1px solid #ddd;
      color: #f50000;
      text-decoration: none; }

.about-team {
  padding-top: 50px;
  padding-bottom: 0;
  background-color: #fff; }
  .about-team__header {
    font-family: "HelveticaNeueLTStd-BdCn";
    margin-left: 22px;
    font-size: 15px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-team__header {
        margin-left: 50px; } }
    @media (min-width: 71.875rem) {
      .about-team__header {
        margin-left: 160px; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .about-team {
      padding-top: 151px; } }
  .about-team .teamPhoto {
    margin-bottom: 0;
    display: block;
    width: 100%;
    height: 370px;
    -o-object-fit: cover;
       object-fit: cover; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-team .teamPhoto {
        height: auto;
        margin-left: 50px; } }
    @media (min-width: 71.875rem) {
      .about-team .teamPhoto {
        margin-left: 120px; } }
  .about-team .team-member-wrapper {
    background-color: #000; }
  .about-team .team-member-contain {
    padding: 20px;
    padding-top: 40px;
    background-color: #d10000;
    color: #fff; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-team .team-member-contain {
        margin-left: 50px;
        padding: 80px  60px 120px 60px;
        -webkit-column-count: 2;
           -moz-column-count: 2;
                column-count: 2;
        -webkit-column-gap: 50px;
           -moz-column-gap: 50px;
                column-gap: 50px; } }
    @media (min-width: 71.875rem) {
      .about-team .team-member-contain {
        margin-left: 120px; } }
  .about-team__member-person {
    padding-top: 35px;
    padding-bottom: 10px;
    color: #fff; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-team__member-person {
        padding-top: 40px;
        padding-left: 40px;
        -moz-column-break-inside: avoid;
             break-inside: avoid;
        page-break-inside: avoid;
        -webkit-column-break-inside: avoid; } }
  .about-team__member-name {
    font-family: "HelveticaNeueLTStd-BdCn";
    font-size: 30px; }
  .about-team__member-position {
    font-family: "HelveticaNeueLTStd-BdCn";
    margin-bottom: 20px;
    font-size: 16px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .about-team__member-position {
        margin-bottom: 40px; } }
  .about-team__member-bio {
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 18px;
    line-height: 30px; }
    .about-team__member-bio a {
      color: #fff; }

.about-video {
  position: relative; }

.about-video__wrapper {
  position: relative; }
  .about-video__wrapper:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 56.25%; }

.about-video__play-button {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  visibility: visible;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  opacity: 1;
  -webkit-transition: all 225ms;
  transition: all 225ms;
  cursor: pointer; }
  .about-video__play-button[disabled] {
    opacity: 0.5; }
  .about-video__play-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); }
  .about-video__wrapper--playing .about-video__play-button {
    visibility: hidden;
    opacity: 0; }

.about-video__play-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.about-video__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.article-author {
  font-family: "HelveticaNeueLTStd-Md";
  margin-top: 3px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 13px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-author {
      margin-left: 0;
      max-width: 270px; } }

.article-author__header {
  font-family: "HelveticaNeueLTStd-Cn";
  font-size: 13px; }

.article-author__introduction p {
  font-family: "HelveticaNeueLTStd-Lt";
  font-size: 13px;
  line-height: 20px; }
  .article-author__introduction p i {
    font-family: "HelveticaNeueLTStd-Lt"; }

.article-author__introduction a {
  color: #f50000; }

.article-author__name {
  font-family: "HelveticaNeueLTStd-BdCn";
  margin-bottom: 12px;
  display: block;
  color: #000;
  font-size: 18px;
  text-decoration: none; }

.article-author__social-list {
  margin: 0;
  padding: 0;
  line-height: 1; }
  .article-author__social-list li {
    list-style: none; }

.article-author__social-item {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block; }
  .article-author__social-item i {
    font-size: 20px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-author__social-item + .article-author__social-item {
      margin-left: 20px; } }

.article-archive-wrap {
  padding-bottom: 100px; }
  .article-archive-wrap .series-header {
    font-family: "NoeDisplay-Medium";
    margin-bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 375px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-position: center;
    background-size: cover;
    color: #fff;
    font-size: 36px;
    text-align: center; }
    .article-archive-wrap .series-header.series-header-all {
      font-family: "HelveticaNeueLTStd-BdCn";
      color: #000; }
  .article-archive-wrap .btn--all-articles {
    margin: auto; }
  .article-archive-wrap img {
    width: 100%; }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .article-archive {
    display: grid;
    grid-gap: 48px;
    grid-template-columns: 240px auto; } }

.article-archive .article-series__details .article-series__name {
  font-family: "Muli-Regular";
  padding-left: 0; }
  .article-archive .article-series__details .article-series__name::before {
    opacity: 0; }

.article-archive .no-image-editorial {
  padding-left: 0;
  margin-top: 21px;
  padding-top: 15px;
  border-top: 1px solid #e7e7e7; }
  .article-archive .no-image-editorial h4 {
    font-size: 32px; }
  .article-archive .no-image-editorial .article-card {
    padding-bottom: 30px; }

.article-archive .article-card__author:hover {
  color: #f50000; }

.article-archive .article-card__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-archive .article-card__link {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      justify-items: space-between; } }
  .article-archive .article-card__link .article-card__thumbnail {
    margin: 30px 50px 20px 0;
    display: block;
    width: 90px;
    min-width: 90px;
    height: 90px;
    -ms-flex-preferred-size: 90px;
        flex-basis: 90px;
    background-position: center;
    background-size: cover;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    -webkit-transition: -webkit-filter 500ms;
    transition: -webkit-filter 500ms;
    transition: filter 500ms;
    transition: filter 500ms, -webkit-filter 500ms; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-archive .article-card__link .article-card__thumbnail {
        margin-top: 10px; } }
  .article-archive .article-card__link:hover .article-card__thumbnail {
    -webkit-transition: -webkit-filter 300ms;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    -webkit-filter: grayscale(0);
            filter: grayscale(0); }
  .article-archive .article-card__link .article-card__content-number {
    display: none; }

.article-archive .article-card--text {
  max-width: unset; }
  .article-archive .article-card--text .article-card__bottom {
    margin-top: 30px; }

.article-archive__sidebar {
  font-family: "HelveticaNeueLTStd-Cn"; }
  .article-archive__sidebar .article-series {
    margin-top: 0;
    max-width: 100%;
    border-bottom: 0; }
  .article-archive__sidebar h3 {
    margin-bottom: 48px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e7e7e7;
    font-size: 12px;
    text-transform: uppercase; }
  .article-archive__sidebar a {
    font-family: "NoeDisplay-Medium";
    margin-bottom: 20px;
    display: block;
    color: #000;
    text-decoration: none; }

.article-archive__sidebar a {
  font-family: "HelveticaNeueLTStd-Md";
  position: relative;
  display: block;
  padding-left: 40px;
  color: #000;
  font-size: 0.875rem;
  -webkit-transition: color 225ms;
  transition: color 225ms;
  cursor: pointer; }
  .article-archive__sidebar a.selected-series::before {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  .article-archive__sidebar a.selected-series::after {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  .article-archive__sidebar a:hover {
    color: #f50000; }
  .article-archive__sidebar a::before, .article-archive__sidebar a::after {
    content: "";
    position: absolute;
    -webkit-transition: opacity 225ms 0.1s, -webkit-transform 225ms;
    transition: opacity 225ms 0.1s, -webkit-transform 225ms;
    transition: opacity 225ms 0.1s, transform 225ms;
    transition: opacity 225ms 0.1s, transform 225ms, -webkit-transform 225ms; }
  .article-archive__sidebar a::before {
    top: -5px;
    left: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    width: 26px;
    height: 26px;
    border: 2px solid #b0b0b0;
    border-radius: 50%;
    background: transparent;
    background-image: url(/assets/circle-unchecked.svg);
    background-size: 26px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1; }
  .article-archive__sidebar a::after {
    top: -5px;
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    width: 26px;
    height: 26px;
    border: 2px solid #000;
    border-radius: 50%;
    background-image: url(/assets/circle-checked.svg);
    background-size: 26px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0; }

.article-series {
  font-family: "HelveticaNeueLTStd-Md";
  margin-top: 40px;
  margin-right: 30px;
  margin-bottom: 20px;
  margin-left: 30px;
  padding-right: 2px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 13px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-series {
      margin-left: 0;
      max-width: 270px; } }

.article-series__header {
  font-family: "HelveticaNeueLTStd-Cn";
  font-size: 13px; }

.article-series__description p {
  font-family: "HelveticaNeueLTStd-Lt";
  font-size: 13px;
  line-height: 20px; }
  .article-series__description p i {
    font-family: "HelveticaNeueLTStd-Lt"; }

.article-series__name {
  font-family: "Muli-Regular";
  margin-bottom: 12px;
  display: block;
  color: #f20000;
  font-size: 18px;
  text-decoration: none; }

.article-body-contain {
  margin: auto;
  padding-top: 40px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-body-contain {
      display: grid;
      width: 720px;
      grid-gap: 40px;
      grid-template-columns: 200px 400px; } }
  @media (min-width: 60rem) {
    .article-body-contain {
      display: grid;
      width: 940px;
      grid-gap: 80px;
      grid-template-columns: 300px 560px; } }
  @media (min-width: 71.875rem) {
    .article-body-contain {
      display: grid;
      width: 1100px;
      grid-gap: 120px;
      grid-template-columns: 300px 700px; } }
  .article-body-contain .sidebar h3.social-share__header {
    font-family: "HelveticaNeueLTStd-Cn";
    margin-left: 30px;
    margin-top: 40px;
    font-size: 13px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-body-contain .sidebar h3.social-share__header {
        margin-left: 0; } }
  .article-body-contain .sidebar .social-share__container {
    width: auto;
    margin-left: 30px;
    margin-right: 30px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-body-contain .sidebar .social-share__container {
        margin-left: 0;
        max-width: 270px; } }
    .article-body-contain .sidebar .social-share__container .social-share__item:last-child {
      display: none; }

.article-body {
  padding-bottom: 3rem; }
  .article-body h2 {
    font-family: "HelveticaNeueLTStd-BdCn";
    margin-top: 1.25rem;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 1.375rem;
    line-height: 1.35; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-body h2 {
        padding-right: 0;
        padding-left: 0; } }
    .article-body h2 + p {
      margin-top: 0; }
  .article-body .responsive-object {
    overflow: hidden;
    position: relative; }
    .article-body .responsive-object iframe, .article-body .responsive-object object, .article-body .responsive-object embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .article-body p {
    font-family: "HelveticaNeueLTStd-Lt";
    margin-right: 30px;
    margin-bottom: 22px;
    margin-left: 30px;
    max-width: 620px;
    font-size: 16px;
    line-height: 1.8; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-body p {
        font-size: 18px;
        margin-right: 0;
        margin-left: 0; } }
    .article-body p a {
      color: #f50000;
      text-decoration: none; }
      .article-body p a::after {
        content: ' ↗';
        font-size: 10px; }
      .article-body p a[href*="thecherrypicks.com"]::after {
        content: ''; }
  .article-body .rich-text:first-of-type p:first-of-type::first-letter {
    font-family: "NoeDisplay-Medium";
    float: left;
    margin-top: 15px;
    padding-right: 22px;
    color: #f50000;
    font-size: 50px;
    font-style: normal;
    line-height: 33px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-body .rich-text:first-of-type p:first-of-type::first-letter {
        margin-top: 21px;
        font-size: 60px; } }
  .article-body h3, .article-body h4, .article-body h5, .article-body h6 {
    margin-left: 30px;
    padding-right: 30px;
    font-family: "HelveticaNeueLTStd-BdCn"; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-body h3, .article-body h4, .article-body h5, .article-body h6 {
        margin-left: 0;
        padding-right: 0; } }
    @media (min-width: 60rem) {
      .article-body h3, .article-body h4, .article-body h5, .article-body h6 {
        max-width: 620px; } }
    .article-body h3 a, .article-body h4 a, .article-body h5 a, .article-body h6 a {
      color: #000; }
  .article-body figure {
    margin: 0;
    padding: 0;
    line-height: 1;
    margin: 45px 0; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-body figure {
        margin: 45px 15px; } }
  .article-body figcaption:not(.film-card__caption) {
    margin-top: 0.625rem;
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;
    text-align: right; }
    @media (min-width: 64.0625rem) {
      .article-body figcaption:not(.film-card__caption) {
        font-size: 0.75rem; } }
  .article-body aside.article-image-aside figure {
    margin: 0; }
  .article-body aside.article-image-aside figcaption {
    margin-left: 30px;
    text-align: left; }
    @media (min-width: 60rem) {
      .article-body aside.article-image-aside figcaption {
        text-align: right;
        position: absolute;
        top: 0;
        left: -333px;
        width: 295px; } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-body aside.article-image-aside figcaption {
        display: inline-block;
        margin-left: 0; } }
  .article-body aside.article-image-aside picture {
    top: 40px;
    margin-left: 30px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-body aside.article-image-aside picture {
        top: 50px;
        margin-left: 0; } }
  .article-body aside {
    position: relative; }
    .article-body aside picture {
      margin-left: 0; }
      @media (min-width: 60rem) {
        .article-body aside picture {
          position: absolute;
          top: 0.5rem;
          left: -333px;
          width: 295px; } }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .article-body aside picture {
          display: inline-block; } }
      .article-body aside picture img {
        height: 200px;
        display: block; }
        @media (min-width: 60rem) {
          .article-body aside picture img {
            margin: auto;
            margin-left: 145px;
            width: 150px;
            height: auto;
            -o-object-fit: cover;
               object-fit: cover; } }
    .article-body aside blockquote {
      margin: 0;
      padding: 0;
      line-height: 1;
      font-family: "PlayfairDisplay-Regular";
      margin-top: 30px;
      padding-top: 40px;
      font-size: 36px;
      line-height: 1.4;
      color: #f50000;
      border-top: 3px solid #f50000; }
      .article-body aside blockquote p {
        font-family: "PlayfairDisplay-Regular";
        font-size: 36px; }
      @media (min-width: 60rem) {
        .article-body aside blockquote {
          margin-left: 0;
          width: auto; } }
      .article-body aside blockquote.aside-paragraph {
        margin-top: 50px;
        padding-top: 0;
        border-top: 0;
        font-size: 20px;
        color: #000; }
        .article-body aside blockquote.aside-paragraph p {
          font-size: 20px; }

.article-body__intro {
  font-size: 1.25rem; }
  @media (min-width: 60rem) {
    .article-body__intro {
      margin-bottom: 1.8125rem;
      font-size: 1.5rem;
      line-height: 1.6; } }

.article-body__image picture {
  display: block; }

.article-body__image img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom; }

.rich-text p b {
  font-family: "HelveticaNeueLTStd-BdCn";
  font-weight: initial; }
  .rich-text p b i {
    font-family: "PlayfairDisplay-BoldItalic";
    font-weight: initial;
    font-style: normal; }

.article-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: -webkit-box-shadow 225ms;
  transition: -webkit-box-shadow 225ms;
  transition: box-shadow 225ms;
  transition: box-shadow 225ms, -webkit-box-shadow 225ms; }
  .carousel__item--article .article-card {
    width: 18.25rem; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .carousel__item--article .article-card {
        width: 18.5rem; }
        .carousel__item--article .article-card:hover {
          -webkit-box-shadow: none;
                  box-shadow: none; } }

.article-card__series {
  font-family: "Muli-Regular";
  color: #f50000;
  text-transform: initial;
  text-decoration: none; }

.article-card--splash {
  margin-bottom: 30px;
  height: auto;
  padding-top: 40px;
  border-top: 1px solid #ddd; }
  .article-card--splash .article-card__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .article-card--splash .article-card__content-copy {
    font-family: "HelveticaNeueLTStd-Lt";
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    font-size: 18px; }
  .article-card--splash .article-card__content-heading {
    font-family: "PlayfairDisplay-Regular"; }
  .article-card--splash .article-card__bottom {
    margin-top: 50px;
    display: block; }

.article-card__link {
  color: inherit;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.article-card--white-background {
  background: #fff; }

.article-card--black-background {
  background: #000; }

.article-card--trio {
  margin-bottom: 50px;
  padding-bottom: 0px;
  border-bottom: 1px solid #e7e7e7; }
  .article-card--trio:last-of-type {
    margin-bottom: 30px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-card--trio {
      margin-bottom: 30px;
      border-bottom: 0; }
      .article-card--trio:last-of-type {
        margin-bottom: 30px; } }
  .article-card--trio .article-card__image {
    position: relative; }
    .article-card--trio .article-card__image:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 74.07407%; }
  .article-card--trio h4 {
    margin-top: 20px;
    font-size: 35px;
    line-height: 42px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-card--trio h4 {
        margin-top: 50px;
        font-size: 24px;
        line-height: 32px; } }
  .page-stories .article-card--trio h4 {
    font-size: 26px;
    line-height: 1.2; }
  .article-card--trio .article-card__excerpt {
    margin-bottom: 20px;
    font-size: 18px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-card--trio .article-card__excerpt {
        margin-bottom: 45px; } }
  .article-card--trio .article-row-second__series {
    font-family: "Muli-Regular";
    color: #f50000;
    text-decoration: none; }

.article-card__image {
  position: relative;
  background-position: center;
  background-size: cover; }
  .article-card--splash .article-card__image {
    width: 50%;
    height: auto; }

.article-card__image-photo {
  width: 100%;
  height: 11.875rem;
  -o-object-fit: cover;
     object-fit: cover; }
  .carousel__item--article .article-card__image-photo {
    height: 6.625rem; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .carousel__item--article .article-card__image-photo {
        height: 10.4375rem; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-card--large .article-card__image-photo {
      height: 14.1875rem; } }
  .article-card--splash .article-card__image-photo {
    height: auto; }
  .article-card--short .article-card__image-photo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }

.article-card__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.25rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  .article-card--black-background .article-card__content {
    color: #fff; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-card--large .article-card__content {
      height: 12.9375rem; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-card__content {
      padding: 1.73rem 1.73rem 1.25rem 1.73rem; } }

.article-card__content-copy {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.article-card__content-number {
  font-family: "HelveticaNeueLTStd-BdCn";
  margin-bottom: 22px;
  display: none;
  font-size: 12px; }

.page-home .article-card__content-number {
  display: none; }

.article-card__content-excerpt {
  display: block; }

.article-card__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 40px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-card__bottom {
      height: 20px;
      margin-bottom: 0;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }

.article-card__read-more {
  font-family: "HelveticaNeueLTStd-Lt";
  color: #000;
  padding-bottom: 25px;
  font-size: 13px; }

.article-card__category-svg {
  width: 1.5rem;
  height: 1.5rem; }
  .article-card__category-svg + .article-card__category-svg {
    margin-left: 0.5rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-card__category-svg {
      width: 1.6875rem;
      height: 1.6875rem; } }

.article-card__excerpt {
  margin: 0; }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .article-card--short {
    max-width: 376px; } }

.article-card--short .article-card__content {
  padding: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-card--short .article-card__content {
      padding: 0; } }

.article-card--short .article-card__content-heading {
  font-family: "PlayfairDisplay-Regular";
  margin-top: 25px;
  margin-bottom: 15px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-card--short .article-card__content-heading {
      margin-top: 40px; } }

.article-card--short .article-card__content-copy {
  font-family: "HelveticaNeueLTStd-Lt";
  font-size: 18px; }

.article-card__read-more-link {
  color: #f50000;
  text-decoration: none; }

.article-card--text {
  margin-top: 30px;
  margin-bottom: 50px;
  height: auto;
  padding-bottom: 40px;
  border-bottom: 1px solid #e7e7e7; }
  .article-card--text .article-card__author {
    color: #000;
    text-decoration: none; }
  @media (max-width: 47.9375rem) {
    .article-card--text {
      margin-bottom: 2px; }
      .page-stories .article-card--text {
        padding-bottom: 10px; }
      .article-card--text:last-of-type {
        padding-bottom: 0;
        border-bottom: 0; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-card--text {
      max-width: 800px; } }
  .article-card--text .article-card__content {
    padding: 0; }
  .article-card--text .article-card__content-heading {
    font-family: "PlayfairDisplay-Regular";
    margin-bottom: 15px;
    font-size: 35px;
    line-height: 42px; }
    @media (max-width: 47.9375rem) {
      .page-stories .article-card--text .article-card__content-heading {
        margin-top: 7px;
        font-size: 26px;
        line-height: 1.2; } }
  .article-card--text .article-card__content-copy {
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 16px; }
  .article-card--text .article-card__content-number {
    margin-bottom: 40px;
    color: #1a1a1a;
    font-size: 13px; }
  .article-card--text .article-card__excerpt {
    font-size: 18px;
    line-height: 24px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-card--text .article-card__excerpt {
        font-size: 16px;
        line-height: 23px; } }
  .article-card--text .article-card__bottom {
    margin-top: 34px;
    margin-bottom: 20px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-card--text .article-card__bottom {
        margin-top: 50px;
        margin-bottom: 10px; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .article-card--large {
    max-width: 39.25rem; } }

.article-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 20px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-details {
      margin-top: 24px;
      margin-bottom: 50px;
      padding-bottom: 30px;
      height: 600px;
      height: calc(100vh - 100px);
      padding-left: 24px;
      padding-right: 40px; } }
  @media (min-width: 64rem) {
    .article-details {
      padding-left: 80px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .article-details {
      height: calc(60vh);
      padding-left: 32px; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .article-full-wrapper__full-width-header .article-details {
    height: auto;
    text-align: center; }
    .article-full-wrapper__full-width-header .article-details .article-details__caption {
      -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
              order: -1;
      text-align: left; }
    .article-full-wrapper__full-width-header .article-details .article-details__headline {
      max-width: 760px;
      margin-left: auto;
      margin-right: auto; }
    .article-full-wrapper__full-width-header .article-details .article-details__meta {
      -webkit-box-ordinal-group: 6;
          -ms-flex-order: 5;
              order: 5; } }

.article-details__meta {
  font-family: "HelveticaNeueLTStd-Lt";
  margin-top: 40px;
  font-size: 13px; }

.article-details__headline {
  font-family: "PlayfairDisplay-Regular";
  margin-top: 40px;
  max-width: 550px;
  font-size: 30px;
  line-height: 1.3; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-details__headline {
      margin-bottom: 20px;
      max-width: 620px;
      font-size: 45px;
      line-height: 60px; } }

.article-details__subheading {
  font-family: "HelveticaNeueLTStd-Lt";
  margin-bottom: 24px;
  margin-left: 3px;
  font-size: 14px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-details__subheading {
      font-size: 18px;
      line-height: 28px; } }

.article-details__caption {
  font-family: "HelveticaNeueLTStd-Lt";
  color: #555;
  font-size: 12px; }

.article-details__social-container {
  margin: 0 0 1.625rem 0; }

.artcle-embedded-carousel__container {
  padding-bottom: 20px;
  margin-bottom: 40px;
  background-color: #bbb; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .artcle-embedded-carousel__container {
      -webkit-transform: translateX(-20px);
              transform: translateX(-20px); } }
  .artcle-embedded-carousel__container .carousel__arrow {
    bottom: -10px;
    color: #000; }
  .artcle-embedded-carousel__container .film-card__link {
    -webkit-box-shadow: none;
            box-shadow: none; }
    .artcle-embedded-carousel__container .film-card__link .film-card {
      -webkit-box-shadow: none;
              box-shadow: none; }

.artcle-embedded-carousel__titleWrapper {
  font-family: "HelveticaNeueLTStd-BdCn";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0 15px 20px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.artcle-embedded-carousel__title {
  margin: 0;
  color: #fff;
  font-size: 1.75rem; }

.article-feature-image {
  margin: 0;
  padding: 0;
  line-height: 1;
  margin-bottom: 40px;
  max-height: 70vh; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-feature-image {
      margin-bottom: 0; } }

.article-feature-image__wrapper {
  position: relative;
  width: 100%; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-feature-image__wrapper {
      max-height: 70vh; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .article-feature-image__wrapper {
      height: calc(60vh); } }
  .article-feature-image__wrapper--fullwidth {
    height: auto;
    max-height: 70vh; }
  .article-feature-image__wrapper--halfwidth {
    height: auto; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-feature-image__wrapper--halfwidth {
        height: calc(100vh - 100px);
        max-height: calc(100vh - 100px); } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-feature-image__wrapper--illustration {
      text-align: center; } }

.article-feature-image__photo {
  position: relative;
  background-position: center;
  width: 100%;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover; }
  .article-feature-image__photo:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 37.5%; }
  @media (max-width: 47.9375rem) {
    .article-feature-image__photo {
      margin-left: 30px; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-feature-image__photo {
      max-height: 70vh; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .article-feature-image__photo {
      height: calc(60vh); } }
  .article-feature-image__photo--fullwidth {
    max-height: 70vh; }
  .article-feature-image__photo--halfwidth {
    height: auto;
    position: relative; }
    .article-feature-image__photo--halfwidth:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 56.25%; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-feature-image__photo--halfwidth {
        height: calc(100vh - 100px);
        max-height: calc(100vh - 100px); } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-feature-image__photo--illustration {
      width: initial; } }

.article-feature-image__caption {
  margin-top: 0.625rem;
  margin-left: 30px;
  color: #555;
  font-size: 11px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-feature-image__caption {
      margin-right: 30px;
      margin-left: 0;
      font-size: 12px;
      text-align: right; } }

.articles-featured-block-splash {
  margin-bottom: 50px;
  padding-bottom: 0;
  background-color: #fff; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .articles-featured-block-splash {
      margin-bottom: 30px;
      padding-bottom: 30px; } }
  .articles-featured-block-splash hr {
    margin: auto;
    display: block;
    max-width: 1200px;
    height: 1px;
    padding: 0;
    border: 0;
    border-top: 1px solid #e7e7e7; }
  .articles-featured-block-splash a {
    color: #000;
    text-decoration: none; }
  .articles-featured-block-splash .articles-featured__image-container {
    position: relative;
    overflow: hidden;
    position: relative;
    max-height: 70vh; }
    .articles-featured-block-splash .articles-featured__image-container:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 50%; }
  .articles-featured-block-splash .articles-featured__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; }
  .articles-featured-block-splash .articles-featured__content {
    margin-top: 50px;
    padding-bottom: 30px;
    text-align: center; }
  .articles-featured-block-splash .articles-featured__category {
    font-family: "NoeDisplay-Medium";
    margin-bottom: 30px;
    color: #f50000;
    font-size: 20px;
    text-align: left; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .articles-featured-block-splash .articles-featured__category {
        margin-top: 100px;
        text-align: center; } }
    .articles-featured-block-splash .articles-featured__category a {
      color: #f50000; }
  .articles-featured-block-splash .articles-featured__heading {
    font-family: "PlayfairDisplay-Regular";
    margin: auto;
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 42px;
    text-align: left; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .articles-featured-block-splash .articles-featured__heading {
        padding: 0 80px;
        font-size: 45px;
        line-height: 60px;
        text-align: center; } }
  .articles-featured-block-splash .articles-featured__description {
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 18px;
    line-height: 24px;
    text-align: left; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .articles-featured-block-splash .articles-featured__description {
        text-align: center; } }
  .articles-featured-block-splash .articles-featured__author {
    margin-top: 40px;
    margin-bottom: 30px;
    color: #222;
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 14px;
    text-align: left;
    display: block; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .articles-featured-block-splash .articles-featured__author {
        margin-top: 80px;
        margin-bottom: 60px;
        text-align: center; } }

.articles-featured-block-cover {
  margin-bottom: 50px;
  padding-bottom: 0;
  background-color: #fff;
  position: relative;
  color: #fff; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .articles-featured-block-cover {
      margin-bottom: 30px; } }
  .articles-featured-block-cover hr {
    margin: auto;
    display: block;
    max-width: 1200px;
    height: 1px;
    padding: 0;
    border: 0;
    border-top: 1px solid #e7e7e7; }
  .articles-featured-block-cover a {
    color: #fff;
    text-decoration: none; }
  .articles-featured-block-cover .articles-featured__image-container {
    position: relative;
    overflow: hidden;
    position: relative;
    max-height: 70vh; }
    .articles-featured-block-cover .articles-featured__image-container:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 50%; }
  .articles-featured-block-cover .articles-featured__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; }
  .articles-featured-block-cover .articles-featured__content {
    top: 0;
    left: 0;
    text-align: left;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 30px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    color: #000; }
    @media (min-width: 64rem) {
      .articles-featured-block-cover .articles-featured__content {
        color: #fff;
        position: absolute;
        padding-top: 0;
        padding-left: 30px;
        margin-top: 0;
        width: 50%;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6); } }
  .articles-featured-block-cover .flex-spacer-top {
    -webkit-box-ordinal-group: -1;
        -ms-flex-order: -2;
            order: -2; }
  .articles-featured-block-cover .articles-featured__category {
    font-family: "NoeDisplay-Medium";
    margin-bottom: 30px;
    color: #f50000;
    font-size: 20px;
    text-align: left;
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
    margin-top: 0; }
    .articles-featured-block-cover .articles-featured__category a {
      color: #f50000; }
  .articles-featured-block-cover .articles-featured__heading {
    font-family: "PlayfairDisplay-Regular";
    margin: auto;
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 42px;
    text-align: left;
    padding: 0; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .articles-featured-block-cover .articles-featured__heading {
        font-size: 40px;
        line-height: 1.2; } }
  .articles-featured-block-cover .articles-featured__description {
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 18px;
    line-height: 24px;
    text-align: left; }
  .articles-featured-block-cover .articles-featured__author {
    margin-top: 40px;
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 14px;
    text-align: left;
    display: block; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .articles-featured-block-cover .articles-featured__author {
        margin-top: 80px; } }

.article-home-featured-section + .article-home-featured-section {
  margin-top: 100px; }

.article-home-featured-wrapper {
  background-color: #fff; }

.article-home-featured {
  margin-top: 40px; }
  .article-home-featured a {
    color: initial;
    text-decoration: none; }
  .article-home-featured .article-card__series {
    font-family: "Muli-Regular";
    color: #f50000;
    display: block;
    margin: 10px 0; }
  .article-home-featured__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-home-featured__container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
  .article-home-featured__image-container {
    position: relative;
    width: calc((100vw * 0.5) - 140px);
    height: 500px; }
    @media (max-width: 47.9375rem) {
      .article-home-featured__image-container {
        display: none; } }
  .article-home-featured__image-container-left {
    position: relative;
    width: 100%;
    height: 207px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-home-featured__image-container-left {
        height: 500px;
        width: calc(((100vw - 1200px) * 0.5) - 60px); } }
    .article-home-featured__image-container-left .article-home-featured__image {
      background-position: left;
      background-size: auto 100%; }
  .article-home-featured__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover; }
  .article-home-featured__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 60px;
    padding-left: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: left; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-home-featured__content {
        width: 700px; } }
    @media (min-width: 64rem) {
      .article-home-featured__content {
        width: 800px; } }
    @media (max-width: 47.9375rem) {
      .article-home-featured__content {
        margin-right: 20px;
        margin-left: 20px;
        padding: 0;
        padding-bottom: 30px;
        border-bottom: 1px solid #e7e7e7; } }
  .article-home-featured__category {
    font-family: "NoeDisplay-Medium";
    margin-top: 30px;
    margin-bottom: 20px;
    color: #f50000; }
  .article-home-featured__heading {
    font-family: "PlayfairDisplay-Regular";
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 42px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-home-featured__heading {
        font-family: "PlayfairDisplay-Bold"; } }
  .article-home-featured__description {
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 18px;
    line-height: 24px; }
  .article-home-featured__author {
    font-family: "HelveticaNeueLTStd-Lt";
    margin-top: 44px;
    font-size: 13px; }

.article-full-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-full-wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  .article-full-wrapper header, .article-full-wrapper article {
    width: 100%; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .article-full-wrapper header, .article-full-wrapper article {
        width: 50%; } }
  .article-full-wrapper article {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

.article-full-wrapper__no-image-header header {
  display: none; }

.article-full-wrapper__no-image-header article {
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.article-full-wrapper__no-image-header .article-details {
  height: auto;
  padding-left: 0;
  margin: auto;
  padding: 28px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-full-wrapper__no-image-header .article-details {
      width: 720px;
      padding: 0;
      margin-bottom: 70px; } }
  @media (min-width: 60rem) {
    .article-full-wrapper__no-image-header .article-details {
      display: grid;
      width: 940px;
      grid-gap: 80px;
      grid-template-columns: 300px 560px; } }
  @media (min-width: 71.875rem) {
    .article-full-wrapper__no-image-header .article-details {
      display: grid;
      width: 1100px;
      grid-gap: 120px;
      grid-template-columns: 300px 700px; } }
  .article-full-wrapper__no-image-header .article-details .article-details__headline {
    font-size: 36px;
    line-height: 1.5; }
  .article-full-wrapper__no-image-header .article-details .article-details__subheading {
    font-family: 'Arial';
    font-size: 16px; }
  .article-full-wrapper__no-image-header .article-details .article-details__caption {
    display: none; }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .article-full-wrapper__full-width-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .article-full-wrapper__full-width-header header, .article-full-wrapper__full-width-header article {
    width: 100%; } }

.article-info {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.article-info__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.article-info__avatar {
  margin-right: 0.75rem;
  height: 2.8125rem;
  border-radius: 50%; }

.article-info__author {
  color: inherit;
  text-decoration: none;
  margin-bottom: 50px; }

.article-info__date {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8125rem; }

.article-info__social-container {
  display: none;
  width: 100%; }

.article-row-second-wrapper {
  background-color: #fff; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-row-second-wrapper {
      padding: 40px 0; } }

.article-row-second hr {
  margin: auto;
  margin-top: 60px;
  display: block;
  max-width: 1200px;
  height: 1px;
  padding: 0;
  border: 0;
  border-top: 1px solid #e7e7e7; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-row-second hr {
      margin-top: 100px; } }

.article-row-second__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.article-row-second__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover; }

.article-row-second__image-container {
  position: relative;
  height: 392px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  @media (max-width: 47.9375rem) {
    .article-row-second__image-container {
      width: 100%;
      -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
              order: 0; } }

.article-row-second__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 480px;
  max-width: 50%;
  padding-right: 60px;
  padding-left: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: left; }
  @media (max-width: 47.9375rem) {
    .article-row-second__content {
      min-width: auto;
      max-width: 100%;
      padding-right: 0;
      padding-bottom: 30px;
      padding-left: 0; } }

.article-row-second__category {
  font-family: "NoeDisplay-Medium";
  margin-top: 30px;
  margin-bottom: 20px;
  color: #f50000; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-row-second__category {
      margin-top: 10px; } }
  .article-row-second__category a {
    text-decoration: none; }

.article-row-second__heading {
  font-family: "PlayfairDisplay-Regular";
  margin-bottom: 20px;
  font-size: 35px;
  line-height: 42px; }

.article-row-second__series {
  font-family: "NoeDisplay-Medium";
  color: #f50000; }

.article-row-second__article-link {
  color: initial;
  text-decoration: none; }

.article-row-second__author-link {
  color: initial;
  text-decoration: none; }

.article-row-second__description {
  font-family: "HelveticaNeueLTStd-Lt";
  font-size: 18px;
  line-height: 24px; }

.article-row-second__byline {
  font-family: "HelveticaNeueLTStd-Lt";
  color: #000;
  font-size: 13px;
  text-decoration: none;
  margin-top: 10px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-row-second__byline {
      margin-top: 30px; } }

@media (min-width: 64rem) {
  .image-callout--author-articles-right {
    bottom: 0; } }

.image-callout--author-articles-left {
  z-index: 1;
  bottom: 0; }

.carousel {
  position: relative;
  margin: auto;
  padding: 0; }
  .carousel .carousel__item--film {
    padding: 0; }
  @media (min-width: 31.25rem) {
    .carousel {
      max-width: 460px; }
      .carousel .carousel__item--film {
        -webkit-transform: initial;
                transform: initial;
        padding: 15px; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .carousel {
      max-width: 666px; } }
  @media (min-width: 60rem) {
    .carousel {
      max-width: 900px; } }
  @media (min-width: 71.875rem) {
    .carousel {
      max-width: 1120px; }
      .carousel .carousel__item--film {
        -webkit-transform: initial;
                transform: initial;
        padding: 20px; } }
  @media (min-width: 96.875rem) {
    .carousel {
      max-width: 1100px;
      padding: 0; }
      .carousel .carousel__item--film {
        padding: 15px; } }
  @media (min-width: 125rem) {
    .carousel {
      max-width: 1480px;
      padding: 0; }
      .carousel .carousel__item--film {
        padding: 12px; } }

.slick-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.carousel__item--film {
  padding: 15px; }

.carousel__arrow {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  z-index: 1;
  position: absolute;
  bottom: -54px;
  height: 48px;
  border: 0;
  padding: 20px; }
  .carousel__arrow[disabled] {
    opacity: 0.5; }
  .carousel__arrow:hover.carousel__arrow--prev .carousel__arrow-svg {
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  .carousel__arrow:hover.carousel__arrow--next .carousel__arrow-svg {
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    -webkit-transform: translateX(10px);
            transform: translateX(10px); }

.carousel__arrow-svg {
  -webkit-transition: -webkit-transform 100ms;
  transition: -webkit-transform 100ms;
  transition: transform 100ms;
  transition: transform 100ms, -webkit-transform 100ms; }
  .carousel__arrow-svg path {
    stroke: #fff; }

.carousel__arrow--prev {
  left: calc(50% - 130px); }

.carousel__arrow--next {
  right: calc(50% - 130px); }

.film-collection .film-collection__view-all {
  font-family: "HelveticaNeueLTStd-MdCn";
  bottom: 36px;
  left: 33px;
  text-align: left; }
  .film-collection .film-collection__view-all:hover {
    font-family: "HelveticaNeueLTStd-BdCn";
    text-decoration: underline; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-collection .film-collection__view-all {
      right: 0;
      bottom: 57px;
      left: 0;
      text-align: center; } }

.carousel--film {
  padding: 0 0 0 22px; }
  .carousel--film .slick-active picture {
    opacity: 1;
    -webkit-transition: opacity 800ms;
    transition: opacity 800ms; }
  .carousel--film .slick-active .ratings::before {
    opacity: 1;
    -webkit-transition: opacity 500ms;
    transition: opacity 500ms;
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms; }
  .carousel--film .slick-active figcaption span,
  .carousel--film .slick-active figcaption div {
    opacity: 1;
    -webkit-transition: opacity 500ms;
    transition: opacity 500ms;
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms; }
  .carousel--film .film-card__link {
    overflow: hidden;
    width: 160px;
    height: 320px;
    background-color: #000; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .carousel--film .film-card__link {
        overflow: hidden;
        width: 189px;
        height: 360px;
        background-color: #000; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .carousel--film {
      padding: 0; } }
  .carousel--film .carousel__arrow {
    bottom: -100px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .carousel--film .carousel__arrow {
        bottom: -84px; } }
  @media (max-width: 47.9375rem) {
    .carousel--film .carousel__arrow--prev {
      right: 92px;
      left: auto; }
    .carousel--film .carousel__arrow--next {
      right: 24px; } }
  .carousel--film picture {
    width: 160px;
    height: 234px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .carousel--film picture {
        width: 189px;
        height: 276px;
        opacity: 1;
        -webkit-transition: opacity 400ms;
        transition: opacity 400ms; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .carousel--film .ratings::before {
      opacity: 1;
      -webkit-transition: opacity 400ms;
      transition: opacity 400ms; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .carousel--film figcaption span,
    .carousel--film figcaption div {
      opacity: 1;
      -webkit-transition: opacity 200ms;
      transition: opacity 200ms; } }

.cherry-check__container.cherry-check__container-mobile {
  margin-top: 0;
  border-top: 0;
  padding: 0; }
  .cherry-check__container.cherry-check__container-mobile .cherry-check-rating-reviews__icon-svg {
    width: 22px;
    margin-right: 2px; }
  .cherry-check__container.cherry-check__container-mobile .cherry-check-rating-reviews__explain .cherry-check-rating-reviews__name {
    font-family: "HelveticaNeueLTStd-Md";
    font-size: 13px;
    margin-top: 3px;
    margin-left: 2px; }
    .cherry-check__container.cherry-check__container-mobile .cherry-check-rating-reviews__explain .cherry-check-rating-reviews__name.reviews-main-name {
      font-size: 12px;
      line-height: 15px; }

.cherry-check__container {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #e7e7e7; }
  @media (max-width: 47.9375rem) {
    .cherry-check__container {
      clear: both; } }
  .cherry-check__container--long-title {
    margin-top: 3.125rem; }

.cherry-check__top-icon-wrapper {
  position: relative; }

.cherry-check__top-icon {
  width: 3rem; }

.cherry-check__check-mark {
  display: inline-block;
  width: 1rem; }

.cherry-check__title {
  margin: 0;
  display: inline-block;
  font-size: 1rem; }

.cherry-check-progress-bar__list {
  margin: 1.125rem 0;
  width: calc(100% - 5rem);
  list-style: none; }
  @media (min-width: 64.0625rem) {
    .cherry-check-progress-bar__list {
      margin: 1rem 0;
      width: 100%; } }

.cherry-check-progress-bar__item {
  margin-bottom: 1rem; }

.cherry-check-progress-bar__title {
  font-size: 0.75rem;
  text-transform: uppercase; }

.cherry-check-film-info__list {
  margin: 20px 0 0 0;
  display: block;
  padding: 27px 0 0 0;
  border-top: 1px solid #e7e7e7;
  list-style: none; }

.cherry-check-film-info__item {
  margin: 10px 0; }

.cherry-check-film-info__name {
  font-family: "HelveticaNeueLTStd-Cn";
  padding-right: 0.625rem;
  font-size: 0.875rem;
  text-transform: capitalize; }

.cherry-check-film-info__info {
  font-size: 0.875rem;
  font-family: "HelveticaNeueLTStd-Md"; }

.cherry-check-rating-reviews__container {
  position: relative;
  padding: 0; }

.cherry-check-rating-reviews__button {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  cursor: unset; }
  .cherry-check-rating-reviews__button[disabled] {
    opacity: 0.5; }
  .cherry-check-rating-reviews__button img {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    margin-top: 0px;
    margin-left: 2px;
    width: 30px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
  .cherry-check-rating-reviews__button.cherry-check-explain {
    margin-bottom: 7px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .cherry-check-rating-reviews__button.cherry-check-explain {
        margin-bottom: 24px; } }
    .cherry-check-rating-reviews__button.cherry-check-explain img {
      margin-top: 5px; }
    .cherry-check-rating-reviews__button.cherry-check-explain .cherry-check-rating-reviews__name {
      margin-bottom: 3px;
      color: #000;
      text-transform: uppercase; }
    .cherry-check-rating-reviews__button.cherry-check-explain.rating-pass .cherry-check-rating-reviews__name {
      color: #000; }
  .cherry-check-rating-reviews__button .cherry-check-rating-reviews__name {
    color: grey; }
  .cherry-check-rating-reviews__button .cherry-check-rating-reviews__paragraph {
    color: grey; }
  .cherry-check-rating-reviews__button.rating-pass .cherry-check-rating-reviews__name {
    color: #f50000; }
  .cherry-check-rating-reviews__button.rating-pass .cherry-check-rating-reviews__paragraph {
    color: #222; }
  .cherry-check-rating-reviews__button .cherry-check-rating-reviews__explain {
    -webkit-box-flex: 2;
        -ms-flex-positive: 2;
            flex-grow: 2; }

.cherry-check-rating-reviews__name {
  margin-bottom: 3px;
  padding-left: 20px;
  font-family: "HelveticaNeueLTStd-BdCn";
  font-size: 15px;
  text-align: left;
  text-transform: capitalize; }

.cherry-check-rating-reviews__paragraph {
  padding-right: 10px;
  padding-left: 20px;
  color: #000;
  font-size: 12px;
  line-height: 1.3;
  text-align: left; }

.cherry-check-film-detail__paragraph {
  margin: 0 1.375rem;
  padding-top: 1rem;
  line-height: 1.75; }

.cherry-check__tags-list {
  margin: 0;
  padding: 0;
  line-height: 1;
  padding: 1.25rem 0; }
  .cherry-check__tags-list li {
    list-style: none; }

.cherry-check__tags-item {
  display: inline-block; }

.cherry-check-detail-expand__text {
  margin-right: 0.5rem; }

.cherry-check-detail-expand__toggle {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin: 0.5rem 0;
  visibility: hidden;
  max-height: 0;
  color: #cb352e;
  font-size: 0.875rem;
  text-transform: uppercase;
  opacity: 0; }
  .cherry-check-detail-expand__toggle[disabled] {
    opacity: 0.5; }
  .cherry-check-detail-expand__toggle.cherry-check-detail-expand__toggle--visible {
    visibility: visible;
    max-height: 6.25rem;
    opacity: 1; }

.cherry-check-detail-expand__read-svg {
  -webkit-transform: rotate3d(0, 0, 1, 180deg);
          transform: rotate3d(0, 0, 1, 180deg);
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 225ms;
  transition: all 225ms; }
  .cherry-check-detail-expand__read-svg path {
    stroke: #cb352e; }
  .cherry-check-detail-expand__toggle--read-more .cherry-check-detail-expand__read-svg {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg); }

.cherry-key {
  z-index: 1;
  margin: 1.5rem 0;
  padding: 1.625rem 1.875rem; }

.cherry-key__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 64.0625rem) {
    .cherry-key__content {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .cherry-key__header {
    margin-bottom: 1rem; } }

@media (min-width: 64.0625rem) {
  .cherry-key__header {
    position: relative;
    margin-bottom: 0;
    width: 9.125rem; } }

.cherry-key__title {
  font-size: 1.625rem;
  line-height: 1.6875rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .cherry-key__title {
      margin-top: 0.4375rem;
      max-width: 16.625rem; } }

.cherry-key__link {
  color: inherit;
  text-decoration: none;
  margin-top: 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #faccc9;
  text-align: center;
  text-transform: uppercase; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .cherry-key__link {
      margin-top: 0;
      width: 6.625rem;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; }
      .cherry-key__link:hover {
        color: #cb352e; }
        .cherry-key__link:hover .cherry-key__link-arrow path {
          stroke: #cb352e; } }

.cherry-key__link-text {
  max-width: 10.625rem;
  font-size: 0.8125rem;
  text-align: left; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .cherry-key__link-text {
      font-size: 0.875rem;
      text-align: center; } }

.cherry-key__link-arrow path {
  stroke: #faccc9; }

.cherry-key__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.375rem;
  height: 2.375rem;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  background-color: #fff; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .cherry-key__icon {
      margin: 0 auto;
      width: 4.25rem;
      height: 4.25rem; } }

.cherry-key__ratings {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .cherry-key__ratings {
      margin-top: 0;
      width: 100%;
      padding: 0 1.25rem;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-pack: distribute;
          justify-content: space-around; } }

.cherry-key__ratings-key {
  margin: 0;
  padding: 0;
  line-height: 1;
  margin-bottom: 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .cherry-key__ratings-key {
      margin-top: 0;
      margin-bottom: 0;
      display: inline-block;
      width: 6.625rem; } }

.cherry-key__icon-svg {
  width: 66%; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .cherry-key__icon-svg {
      width: 60%; } }

.cherry-key__name {
  margin-left: 0.75rem;
  max-width: 7.5rem;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.1;
  text-align: left; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .cherry-key__name {
      margin-top: 0.5625rem;
      margin-left: 0;
      font-size: 1rem;
      text-align: center; } }

.critic-info__container {
  padding-top: 80px;
  background-color: #fff; }

.critic-info__info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .critic-info__info-wrapper .critic-info__avatar {
    margin-bottom: 50px;
    width: 98px;
    height: 98px;
    border-radius: 50%;
    -webkit-filter: grayscale(1);
            filter: grayscale(1); }
  .critic-info__info-wrapper .critic-info__name {
    font-family: "HelveticaNeueLTStd-BdCn";
    font-size: 48px;
    text-align: center; }
  .critic-info__info-wrapper .critic-info__sidebar-title {
    font-family: "HelveticaNeueLTStd-BdCn";
    margin: 0;
    font-size: 12px;
    text-transform: uppercase; }
  .critic-info__info-wrapper .critic-info__introduction-container {
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
  .critic-info__info-wrapper .critic-info__introduction {
    font-family: "HelveticaNeueLTStd-Lt";
    margin: 0;
    font-size: 18px;
    line-height: 30px; }
    .critic-info__info-wrapper .critic-info__introduction a {
      color: #f50000;
      text-decoration: none; }
  .critic-info__info-wrapper .critic-info__social {
    margin: 0;
    height: auto;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    background: #fff; }
  .critic-info__info-wrapper .critic-info__social-list {
    margin: 0;
    margin: 0;
    padding: 0;
    line-height: 1; }
    .critic-info__info-wrapper .critic-info__social-list li {
      list-style: none; }
  .critic-info__info-wrapper .critic-info__social-item {
    margin: 0 6px;
    display: inline-block;
    width: 36px;
    height: 36px;
    border: 1px solid #bbb;
    border-radius: 100%;
    color: #777;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    cursor: pointer; }
    .critic-info__info-wrapper .critic-info__social-item a {
      color: #222; }
    .critic-info__info-wrapper .critic-info__social-item:hover {
      color: #f50000; }

.critic-info-content__container {
  position: relative;
  min-height: 30rem;
  padding: 5rem 0; }

.critic-info-content__button-wrapper {
  margin-bottom: 1.4rem; }

.critic-info-content__heading-button {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin-right: 1.5rem;
  font-size: 1.5rem; }
  .critic-info-content__heading-button[disabled] {
    opacity: 0.5; }
  .critic-info-content__heading-button.selected {
    border-bottom: 0.3125rem solid #f50000; }
  @media (min-width: 64rem) {
    .critic-info-content__heading-button {
      font-size: 3rem;
      font-weight: 600; } }

.film-card-stacked {
  margin: 0;
  padding: 0;
  line-height: 1;
  position: relative;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

@media (min-width: 64rem) and (min-height: 1100px) {
  .film-card-stacked {
    margin-bottom: 60px; } }
  .film-card-stacked__link {
    color: inherit;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .film-card-stacked__image {
    -webkit-transform-origin: right center;
            transform-origin: right center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 175px;
    height: 260px;
    -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
            box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transition: -webkit-transform 400ms;
    transition: -webkit-transform 400ms;
    transition: transform 400ms;
    transition: transform 400ms, -webkit-transform 400ms; }
    @media (min-width: 767px) {
      .film-card-stacked__image {
        -webkit-box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2);
                box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2); }
        .film-card-stacked__image:hover {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); } }
    @media (min-height: 1000px) and (min-width: 767px) {
      .film-card-stacked__image {
        width: 250px;
        height: 370px; } }
  .film-card-stacked__caption {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 260px;
    padding: 25px 20px 3px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px; }
    .film-card-stacked__caption.ratings:before {
      width: 0px;
      height: 0px;
      background-color: transparent; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-card-stacked__caption {
        padding: 45px 30px 3px 40px;
        font-size: 18px;
        line-height: 1.3; } }
    @media (min-width: 64.0625rem) {
      .film-card-stacked__caption {
        padding: 45px 30px 3px 40px;
        font-size: 20px;
        line-height: 1.3; } }
  @media (min-width: 64.0625rem) and (min-height: 1100px) {
    .film-card-stacked__caption {
      height: 310px; } }
    .film-card-stacked__caption .caption-title {
      font-family: "HelveticaNeueLTStd-BdCn";
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .film-card-stacked__caption .caption-year,
    .film-card-stacked__caption .caption-release {
      font-family: "HelveticaNeueLTStd-Lt";
      margin-top: 10px;
      padding-top: 5px;
      color: rgba(255, 255, 255, 0.9);
      font-size: 13px; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .film-card-stacked__caption .caption-year,
        .film-card-stacked__caption .caption-release {
          padding-top: 15px; } }
    .film-card-stacked__caption .caption-streaming {
      margin-top: 10px; }
    .film-card-stacked__caption .score-percent {
      font-family: "HelveticaNeueLTStd-BdCn";
      position: absolute;
      right: 18px;
      bottom: 12px;
      display: block;
      color: #fff;
      font-size: 20px;
      text-decoration: none; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .film-card-stacked__caption .score-percent {
          font-size: 40px; } }
      .film-card-stacked__caption .score-percent img {
        -webkit-transform: translateY(9px);
                transform: translateY(9px);
        margin-left: 10px;
        display: inline-block; }
        .film-card-stacked__caption .score-percent img.cherry-check__top-icon-wait {
          width: 32px;
          -webkit-transform: translateY(4px);
                  transform: translateY(4px); }
        @media (max-width: 47.9375rem) {
          .film-card-stacked__caption .score-percent img {
            -webkit-transform: translateY(5px);
                    transform: translateY(5px);
            width: 25px;
            height: auto; } }

.film-card-container-hack {
  clear: both; }
  .film-card-container-hack .film-card__link {
    display: inline-block; }

.film-card {
  margin: 0;
  padding: 0;
  line-height: 1;
  overflow: auto;
  position: relative;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  background-color: #fff;
  color: #000; }
  .film-card.film-card__gridded {
    margin-bottom: 0;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .film-card.film-card__gridded.ratings--timer:before {
      bottom: 12px;
      right: 12px;
      opacity: 1; }
  .film-card__link {
    color: inherit;
    text-decoration: none;
    display: block;
    width: 203px;
    -webkit-transition: -webkit-transform 300ms, -webkit-box-shadow 300ms;
    transition: -webkit-transform 300ms, -webkit-box-shadow 300ms;
    transition: transform 300ms, box-shadow 300ms;
    transition: transform 300ms, box-shadow 300ms, -webkit-transform 300ms, -webkit-box-shadow 300ms; }
    .film-card__link .ratings--timer .score-percent {
      display: none; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-card__link:hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
                box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
        -webkit-transition: -webkit-transform 400ms ease-out, -webkit-box-shadow 400ms ease-out;
        transition: -webkit-transform 400ms ease-out, -webkit-box-shadow 400ms ease-out;
        transition: transform 400ms ease-out, box-shadow 400ms ease-out;
        transition: transform 400ms ease-out, box-shadow 400ms ease-out, -webkit-transform 400ms ease-out, -webkit-box-shadow 400ms ease-out; } }
  .film-card__image {
    display: block;
    width: 203px;
    height: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .film-card__caption {
    font-family: "HelveticaNeueLTStd-MdCn";
    min-height: 90px;
    padding: 18px 10px 3px 14px;
    color: #000;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 1.2; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-card__caption {
        font-family: "HelveticaNeueLTStd-Cn";
        font-size: 16px; } }
    .film-card__caption .caption-year {
      display: none;
      color: #999; }
    .film-card__caption .score-percent {
      position: absolute;
      right: 10px;
      bottom: 13px;
      color: #f50000;
      font-size: 14px;
      font-family: "HelveticaNeueLTStd-BdCn"; }

.film-collection {
  position: relative;
  padding-top: 100px;
  padding-bottom: 130px;
  background-size: 25% auto;
  background-repeat: repeat; }
  .film-collection.background-frame {
    background-size: cover; }
    .film-collection.background-frame .background-shim:before {
      opacity: 0.3; }
  .film-collection .background-shim:before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3; }
  .film-collection__view-all {
    font-family: "HelveticaNeueLTStd-MdCn";
    position: absolute;
    right: 0;
    bottom: 55px;
    left: 0;
    display: inline-block;
    width: auto;
    color: #fff;
    font-size: 15px;
    text-align: center;
    text-decoration: none; }
  .film-collection h2 {
    font-family: "HelveticaNeueLTStd-BdCn";
    margin: auto;
    margin-bottom: 60px;
    padding-left: 20px;
    font-size: 50px;
    letter-spacing: -2px;
    text-transform: uppercase; }
    .film-collection h2 a {
      color: #cccccc;
      text-decoration: none; }
      .film-collection h2 a:hover {
        color: #fff; }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .film-collection--first {
    padding-top: 78px; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .film-collection--button {
    padding-bottom: 60px; } }

.film-collection__cta {
  margin-top: 85px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-collection__cta {
      margin-top: 73px; } }

.film-collection-single {
  padding-top: 0;
  padding-bottom: 40px;
  background-color: #f8f8f8; }
  .film-collection-single__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 230px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-position: center top;
    background-size: cover; }
    @media (min-height: 800px) and (min-width: 767px) {
      .film-collection-single__header {
        height: 430px; } }
    .film-collection-single__header .video-container {
      height: 230px;
      z-index: 0; }
      @media (min-height: 800px) and (min-width: 767px) {
        .film-collection-single__header .video-container {
          height: 430px; } }
    .film-collection-single__header h1 {
      font-family: "NoeDisplay-Medium";
      margin-bottom: 0;
      color: #fff;
      font-size: 32px;
      text-align: center; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .film-collection-single__header h1 {
          font-size: 44px; } }
    .film-collection-single__header h2 {
      font-family: "HelveticaNeueLTStd-Cn";
      margin-bottom: 0;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 1.5; }
  .film-collection-single__image-title {
    display: absolute;
    z-index: 10; }
    .film-collection-single__image-title h2 {
      font-family: "HelveticaNeueLTStd-Md";
      margin: 0;
      margin-top: 4px;
      font-size: 11px;
      text-align: center;
      padding: 0 20px;
      max-width: 820px; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .film-collection-single__image-title h2 {
          font-size: 14px;
          padding: 0 60px; } }
  .film-collection-single__cards-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

.film-overview {
  position: relative;
  background-color: #fff; }

@media (max-width: 47.9375rem) {
  .film-overview__image {
    float: left;
    width: 150px; } }

.film-overview__wrap {
  width: 100%;
  background-position: center left;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: calc(100vh - 360px);
  padding-bottom: 0; }
  .film-overview__wrap .grid__shim {
    display: none; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-overview__wrap {
      position: relative;
      padding-top: 0;
      background-position: top left;
      background-size: 25% auto;
      background-repeat: repeat; }
      .film-overview__wrap:before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 34.375%; }
      .film-overview__wrap .grid__shim {
        display: block; } }
  .film-overview__wrap.film-overview__wrap-hero-background {
    background-size: cover;
    background-position: top center; }
  .film-overview__wrap.film-overview__wrap-film-cover {
    -webkit-box-shadow: inset 0 0 0 1000vmax rgba(0, 0, 0, 0.15);
            box-shadow: inset 0 0 0 1000vmax rgba(0, 0, 0, 0.15);
    -webkit-transform: translate3d(0, 0, 0); }

.film-overview__header {
  width: calc(100vw - 22px);
  color: #fff; }
  @media (max-width: 47.9375rem) {
    .film-overview__header {
      -webkit-transform: translateY(1px);
              transform: translateY(1px); } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-overview__header {
      display: grid;
      width: unset;
      grid-template-columns: auto 477px 160px 130px auto; } }
  @media (min-width: 60rem) {
    .film-overview__header {
      grid-template-columns: auto 670px 160px 130px auto; } }
  @media (min-width: 71.875rem) {
    .film-overview__header {
      grid-template-columns: auto 757px 200px 150px auto; } }
  .film-overview__header .film-overview__header-paint {
    padding-top: 20px;
    padding-left: 30px;
    background-color: #464646; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-overview__header .film-overview__header-paint {
        padding-top: 30px;
        padding-left: 10px; } }
    @media (min-width: 64rem) {
      .film-overview__header .film-overview__header-paint {
        padding-left: 0; } }
    .film-overview__header .film-overview__header-paint.film-overview__contain {
      position: relative; }
      @media (min-width: 71.875rem) {
        .film-overview__header .film-overview__header-paint.film-overview__contain {
          padding-left: 50px; }
          .film-overview__header .film-overview__header-paint.film-overview__contain:before {
            content: '';
            position: absolute;
            left: 15px;
            top: 50px;
            width: 1px;
            height: 94px;
            background-color: rgba(255, 255, 255, 0.1); } }
    .film-overview__header .film-overview__header-paint.film-overview__contain-score {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .film-overview__header .film-overview__header-paint.film-overview__contain-score {
          min-width: 206px; } }
    @media (min-width: 71.875rem) {
      .film-overview__header .film-overview__header-paint.film-overview__contain-title {
        padding-left: 0; } }
    .film-overview__header .film-overview__header-paint.film-overview__contain-title:before {
      display: none; }
    @media (max-width: 47.9375rem) {
      .film-overview__header .film-overview__header-paint.film-overview__contain-title {
        padding-top: 30px;
        padding-right: 32px; }
        .film-overview__header .film-overview__header-paint.film-overview__contain-title .film-overview__title {
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(231, 231, 231, 0.3); }
        .film-overview__header .film-overview__header-paint.film-overview__contain-title hr {
          width: 100%;
          height: 1px;
          background-color: #e7e7e7; } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-overview__header .film-overview__header-paint.film-overview__contain-title {
        padding-top: 30px; }
        .film-overview__header .film-overview__header-paint.film-overview__contain-title hr {
          display: none; } }
  .film-overview__header .film-overview__mobile-double {
    display: inline-block;
    width: 50%;
    height: 150px; }
    @media (max-width: 47.9375rem) {
      .film-overview__header .film-overview__mobile-double-second {
        -webkit-transform: translateY(-1px);
                transform: translateY(-1px); } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-overview__header .film-overview__mobile-double {
        -webkit-transform: unset;
                transform: unset;
        display: block;
        width: 100%;
        height: unset; } }
  .film-overview__header .film-overview__category {
    display: block;
    opacity: 0.7; }

.red-side-block {
  z-index: 1;
  position: absolute;
  bottom: -180px;
  left: 0;
  width: 0px;
  height: 180px;
  background-color: #464646;
  display: none; }
  @media (min-width: 71.875rem) {
    .red-side-block {
      display: block;
      width: 15px;
      height: 180px; } }
  @media (min-width: 90rem) {
    .red-side-block {
      width: 20px;
      height: 180px; } }

.film-overview__score a {
  color: inherit;
  text-decoration: none; }

.film-overview__score h2 {
  font-family: "HelveticaNeueLTStd-BdCn";
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  padding-top: 3px;
  font-size: 34px;
  margin-bottom: 0; }

.film-overview__score img {
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  margin-left: 10px;
  height: 30px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-overview__score img {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
      height: 42px; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .film-overview__score {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px); } }

@media (min-width: 64rem) {
  .film-overview__score h2 {
    font-family: "HelveticaNeueLTStd-BdCn";
    -webkit-transform: translateY(0);
            transform: translateY(0);
    padding-top: 3px;
    font-size: 44px; }
  .film-overview__score img {
    -webkit-transform: translateY(4px);
            transform: translateY(4px); } }

.film-overview__category {
  font-family: "HelveticaNeueLTStd-Cn";
  margin-top: 5px;
  margin-bottom: 20px;
  display: none; }
  .mobile-only .film-overview__category {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-overview__category {
      display: block;
      margin-top: 20px;
      margin-bottom: 40px; } }

.ticket-button {
  margin-top: 0; }

.film-overview__image {
  margin-top: 40px;
  width: 150px; }
  .film-overview__image img {
    margin-top: 0; }

.film-overview__poster {
  width: 100%; }

.film-overview__watch-link {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: 0;
  border: 0;
  color: #fff; }
  .film-overview__watch-link[disabled] {
    opacity: 0.5; }
  @media (min-width: 64rem) {
    .film-overview__watch-link {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px); } }

.film-overview__watch-svg {
  color: #fff;
  text-align: left;
  width: 32px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-overview__watch-svg {
      width: 40px;
      -webkit-transform: translateY(1px);
              transform: translateY(1px); } }

.film-overview__title {
  font-family: "NoeDisplay-Medium";
  margin-bottom: 0;
  font-size: 32px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-overview__title {
      margin-bottom: 54px;
      font-size: 3rem; } }
  .film-overview__title--long {
    font-size: 1.5rem; }

.mobile-only {
  float: right;
  width: calc(100vw - 200px);
  padding-left: 25px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .mobile-only {
      display: none; } }
  .mobile-only h2 {
    font-family: "HelveticaNeueLTStd-BdCn";
    margin-top: 4px;
    padding-bottom: 6px;
    margin-bottom: 16px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7e7; }
  .mobile-only .film-overview__category-show {
    margin-top: 45px;
    font-size: 12px;
    text-transform: uppercase; }

.mobile-only-top {
  clear: both;
  margin-top: 30px;
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .mobile-only-top .tickets-contain {
    margin-right: 30px; }
  .mobile-only-top .stream-contain .film-overview__category {
    margin-bottom: 16px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .mobile-only-top {
      display: none; } }

.mobile-only-wide {
  width: unset;
  float: unset; }

.film-overview__category-show {
  display: block;
  font-size: 12px;
  font-family: "HelveticaNeueLTStd-Cn"; }

.film-overview__about .mobile-only {
  padding-left: 0; }

.film-overview__about-header {
  display: none;
  padding-bottom: 30px;
  border-bottom: 1px solid #e7e7e7; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-overview__about-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }
  @media (min-width: 71.875rem) {
    .film-overview__about-header {
      padding-bottom: 0; } }
  .film-overview__about-header .film-overview__category-streams {
    padding-bottom: 13px; }
  .film-overview__about-header div:nth-of-type(1) {
    margin-right: 30px; }
    .film-overview__about-header div:nth-of-type(1) h2 {
      font-family: "HelveticaNeueLTStd-BdCn";
      margin-top: 39px;
      margin-bottom: 33px;
      font-size: 34px; }
  .film-overview__about-header div:nth-of-type(2) {
    margin-right: 30px; }
    .film-overview__about-header div:nth-of-type(2) .film-overview__category {
      display: block; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .film-overview__about-header div:nth-of-type(2) .film-overview__category {
          margin-bottom: 24px; } }
  .film-overview__about-header div:nth-of-type(3) {
    padding-bottom: 10px; }
  .film-overview__about-header .film-overview__category {
    display: block; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-overview__about-header .film-overview__category {
        margin-bottom: 35px; } }

.film-overview__center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.film-overview__wrapper {
  padding: 0 25px;
  padding-top: 20px; }
  .film-overview__wrapper .film-overview__info {
    overflow: auto; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-overview__wrapper {
      display: grid;
      padding: 0;
      padding-top: 80px;
      grid-gap: 60px;
      grid-template-columns: 215px 440px; } }
  @media (min-width: 60rem) {
    .film-overview__wrapper {
      display: grid;
      padding: 0;
      padding-top: 80px;
      grid-gap: 60px;
      grid-template-columns: 310px 560px; } }
  @media (min-width: 71.875rem) {
    .film-overview__wrapper {
      display: grid;
      padding: 0;
      padding-top: 80px;
      grid-gap: 112px;
      grid-template-columns: 330px 660px; } }

.desktop-only {
  display: none; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .desktop-only {
      display: block; } }

.film-overview__excerpt {
  margin-top: 40px;
  font-size: 18px; }
  @media (max-width: 47.9375rem) {
    .film-overview__excerpt {
      clear: both;
      margin-top: 40px;
      padding-top: 10px;
      border-top: 1px solid #e7e7e7; } }
  .film-overview__excerpt p {
    font-family: "HelveticaNeueLTStd-Lt";
    margin-top: 30px;
    font-size: 18px;
    line-height: 1.62; }

.film-overview__tags-list {
  margin: 0;
  padding: 0;
  line-height: 1; }
  .film-overview__tags-list li {
    list-style: none; }

.film-overview__tags-item {
  margin-top: 0.3125rem;
  margin-right: 0.3125rem;
  display: inline-block; }

.film-overview__social-share-container {
  display: none; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-overview__social-share-container {
      display: block; } }
  .film-overview__social-share-container.show-mobile {
    margin-top: 20px;
    display: block;
    border-top: 1px solid #e7e7e7;
    padding-top: 30px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-overview__social-share-container.show-mobile {
        display: none; } }
    .film-overview__social-share-container.show-mobile .film-overview__category {
      display: block; }
  .film-overview__social-share-container .film-overview__category {
    margin-bottom: 8px; }

.film-reviews-title {
  font-family: "HelveticaNeueLTStd-BdCn";
  padding-bottom: 6px;
  padding-top: 70px;
  padding-left: 24px;
  font-size: 24px;
  position: relative;
  margin: auto;
  margin-bottom: 1px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-reviews-title {
      width: 757px;
      padding-left: 0; } }
  @media (min-width: 64rem) {
    .film-reviews-title {
      width: 940px; } }
  @media (min-width: 80rem) {
    .film-reviews-title {
      width: 1105px; } }

.film-details-featured-reviews {
  position: relative;
  margin: auto;
  margin-bottom: 96px;
  padding-top: 0;
  padding-bottom: 6px;
  font-size: 24px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-details-featured-reviews {
      width: 757px; } }
  @media (min-width: 64rem) {
    .film-details-featured-reviews {
      width: 940px; } }
  @media (min-width: 80rem) {
    .film-details-featured-reviews {
      width: 1105px; } }

.film-overview__reviews-header {
  font-family: "HelveticaNeueLTStd-BdCn";
  -webkit-transform: translateX(-24px);
          transform: translateX(-24px);
  margin-top: 40px;
  width: calc(100% + 48px);
  padding-top: 50px;
  padding-left: 24px;
  border-top: 1px solid #e7e7e7;
  font-size: 26px; }

.film-details-reviews__container {
  padding: 0 0 100px 0;
  background-color: #fff; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-details-reviews__container {
      margin-top: 40px; } }
  .film-details-reviews__container:before {
    content: "";
    position: absolute;
    top: 470px;
    left: 0;
    width: 100%;
    height: calc(100% - 470px);
    background-color: #f6f6f6; }
  .film-details-reviews__container--last-section .image-callout--film-details-reviews-right, .film-details-reviews__container--last-section .image-callout--author-articles-right {
    bottom: 0; }
  @media (min-width: 64.0625rem) {
    .film-details-reviews__container--last-section .image-callout--film-details-reviews-left, .film-details-reviews__container--last-section .image-callout--author-articles-left {
      bottom: 0; }
    .film-details-reviews__container--last-section .image-callout--film-details-reviews-right, .film-details-reviews__container--last-section .image-callout--author-articles-right {
      bottom: 0; } }

.film-details-reviews__heading-container h2 {
  font-family: "HelveticaNeueLTStd-BdCn";
  font-size: 12px;
  text-transform: uppercase; }

.film-details-reviews__heading-container .film-details-reviews__heading-description {
  display: none; }

.film-details-reviews__no-reviews {
  margin-top: 2rem;
  font-family: "arial"; }

.film-details-trailer__modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.7); }

.film-details-trailer__video-container {
  position: relative;
  background-color: #000; }

.film-details-trailer__close-btn {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: absolute;
  top: -32px;
  right: 16px; }
  .film-details-trailer__close-btn[disabled] {
    opacity: 0.5; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-details-trailer__close-btn {
      top: -24px;
      right: -24px; } }

.film-details-trailer__close-btn-icon {
  width: 1.375rem;
  height: 1.375rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-details-trailer__close-btn-icon:hover path {
      stroke: #5b1815; } }

.you-may-like__cards-contain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.you-may-like {
  padding: 30px 0 60px 0; }
  .you-may-like .two-three-five-col-films {
    margin-top: 0; }
  .you-may-like h1 {
    font-family: "HelveticaNeueLTStd-BdCn";
    margin: auto;
    width: 100%;
    font-size: 34px;
    line-height: 50px;
    color: #fff;
    text-align: center;
    -webkit-transform: translateY(13px);
            transform: translateY(13px); }
    @media (min-width: 45.625rem) {
      .you-may-like h1 {
        text-align: left;
        width: 696px; } }
    @media (min-width: 60rem) {
      .you-may-like h1 {
        width: 944px; } }
    @media (min-width: 80rem) {
      .you-may-like h1 {
        width: 1185px; } }
  .you-may-like .two-three-five-col-films {
    margin-top: 0px; }
    .you-may-like .two-three-five-col-films .film-card__link:nth-of-type(5) {
      display: none; }
    @media (min-width: 45.625rem) {
      .you-may-like .two-three-five-col-films .film-card__link:nth-of-type(4) {
        display: none; } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .you-may-like .two-three-five-col-films {
        grid-template-columns: repeat(3, 203px); } }
    @media (min-width: 60rem) {
      .you-may-like .two-three-five-col-films {
        width: 944px;
        grid-template-columns: repeat(4, 203px); }
        .you-may-like .two-three-five-col-films .film-card__link:nth-of-type(4) {
          display: block; }
        .you-may-like .two-three-five-col-films .film-card__link:nth-of-type(5) {
          display: none; } }
    @media (min-width: 80rem) {
      .you-may-like .two-three-five-col-films {
        width: 1185px;
        grid-template-columns: repeat(5, 203px); }
        .you-may-like .two-three-five-col-films .film-card__link:nth-of-type(5) {
          display: block; } }

.print-only {
  display: none; }

.print-header {
  display: none; }

@media print {
  @page {
    margin: 0 !important;
    size: 8.5in 11in; }
  .app__main-container {
    min-height: 10px !important; }
  .desktop-only {
    display: none; }
  body {
    margin: 1cm 1cm 1cm 1cm; }
  .you-may-like {
    display: none; }
  .print-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
  .film-overview__wrap {
    display: none; }
  .print-header {
    margin-top: 20px;
    margin-bottom: 60px;
    display: block;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px; }
    .print-header::before {
      content: "";
      width: 200px;
      height: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      padding-left: 20px; }
  p.film-overview__excerpt-paragraph {
    font-size: 14px; }
  .film-overview__center {
    display: block; }
  .film-overview__wrapper {
    display: block;
    width: 100%; }
  .film-overview__image {
    float: left; }
  .print-column {
    width: 3.6in; }
  .print-only {
    margin-left: 280px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .film-overview__title {
    margin-bottom: 5px; }
  .film-overview__title-contain {
    margin-bottom: 30px;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #e7e7e7; }
  .app__main-container {
    padding-top: 0 !important; }
  .film-overview__wrapper {
    padding-top: 0;
    padding: 0; }
  h2.row-two {
    font-family: "HelveticaNeueLTStd-MdCn";
    margin-bottom: 0;
    font-size: 16px !important; }
  .film-overview__score {
    position: relative;
    -webkit-transform: translateY(0) !important;
            transform: translateY(0) !important; }
  .cherry-check__container {
    margin-top: 0;
    padding-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
  .cherry-check-film-info__list {
    margin-top: 0;
    width: 47%;
    padding-top: 0;
    border-top: 0; }
  .cherry-check-rating-reviews__container {
    width: 47%; }
  .cherry-check-rating-reviews__name {
    padding-left: 5px;
    font-size: 12px; }
  .cherry-check-film-info__name {
    font-size: 11px;
    line-height: 13px; }
  .cherry-check-film-info__info {
    font-size: 11px;
    line-height: 13px; }
  .cherry-check-film-info__item {
    margin: 0; }
  .cherry-check-rating-reviews__paragraph {
    display: none;
    padding-left: 5px;
    font-size: 11px; }
  .cherry-check-rating-reviews__button img {
    width: 20px; }
  .cherry-check__top-icon {
    -webkit-transform: translateY(0) !important;
            transform: translateY(0) !important;
    height: 32px !important;
    position: absolute;
    top: 0;
    left: 15px; }
  .film-overview__wrap-film-cover {
    display: none; }
  .film-overview__wrap::before {
    display: none;
    padding-top: 0; }
  .film-overview__category {
    margin: 0;
    margin-bottom: 10px;
    display: block; }
  .film-overview__excerpt .film-overview__category {
    margin-top: 30px; }
  .film-overview__tags .film-overview__category {
    margin-top: 30px; }
  .film-overview__about-header {
    display: none; }
  .film-overview__social-share-container {
    display: none; }
  .film-overview__social-share-container.show-mobile {
    display: none; }
  .film-featured-article {
    display: none; }
  .grid__shim {
    display: none; }
  .red-side-block {
    display: none; }
  .film-overview__image .film-overview__category {
    display: none; }
  .film-overview__image {
    margin-top: 0; }
  .film-overview__image img {
    width: 240px; }
  .main-header-section {
    display: none; }
  .mobile-only {
    display: none; }
  .film-overview__header {
    display: block; }
  .film-reviews-title {
    display: none; }
  .film-details-featured-reviews {
    display: none; }
  .film-details-reviews__container {
    display: none; }
  footer {
    display: none; } }

.film-featured-article {
  margin-top: 60px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-featured-article {
      margin-top: 120px; } }
  .film-featured-article a {
    color: #000;
    text-decoration: none; }
    .film-featured-article a:hover {
      color: #f50000; }
  .film-featured-article h3 {
    font-family: "HelveticaNeueLTStd-Cn";
    margin-bottom: 26px;
    padding-bottom: 22px;
    border-bottom: 1px solid #e7e7e7;
    font-size: 12px;
    text-transform: uppercase; }
  .film-featured-article h2 {
    font-family: "PlayfairDisplay-Regular";
    font-size: 20px;
    line-height: 30px; }
  .film-featured-article img {
    margin-right: 40px;
    margin-bottom: 20px;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-featured-article img {
        margin-bottom: 0;
        width: 200px;
        height: 135px; } }
  .film-featured-article .film-featured-article-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-featured-article .film-featured-article-content {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
  .film-featured-article .film-featured-article-copy {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .film-featured-article .film-featured-article-meta {
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 13px; }

.film-filter {
  margin-top: 30px; }

.film-filter__content {
  display: block; }

.film-filter__form {
  position: relative; }

.filter-button-contain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.film-filter__filters-name {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: "HelveticaNeueLTStd-MdCn";
  margin-bottom: 18px;
  font-size: 13px;
  text-transform: uppercase; }
  .film-filter__filters-name[disabled] {
    opacity: 0.5; }

.filter-button-clear {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  width: 40px;
  height: 23px; }
  .filter-button-clear[disabled] {
    opacity: 0.5; }
  .filter-button-clear.genre-clear {
    margin-right: 8px; }
  .filter-button-clear::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 4px;
    display: inline-block;
    width: 23px;
    height: 23px;
    background-image: url(/assets/circle-x-black.svg);
    background-position: center;
    background-size: 23px;
    background-repeat: no-repeat; }

.film-filter-rating-filtered .film-filter__label {
  color: grey; }
  .film-filter-rating-filtered .film-filter__label img {
    opacity: 0.5; }

.film-filter-rating-filtered .film-filter-rating-active {
  color: black; }
  .film-filter-rating-filtered .film-filter-rating-active img {
    opacity: 1; }

button.film-filter__filters-name {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }
  button.film-filter__filters-name::after {
    content: "";
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    width: 32px;
    height: 10px;
    background-image: url(/assets/toggle-arrow-vert.svg);
    background-position: center;
    background-size: 10px;
    background-repeat: no-repeat;
    -webkit-transition: rotate 300ms;
    transition: rotate 300ms; }
  button.film-filter__filters-name.film-filter-open::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: rotate 300ms;
    transition: rotate 300ms; }

.film-filter__ratings-item {
  font-family: "HelveticaNeueLTStd-Md";
  margin-bottom: 10px;
  font-size: 12px; }
  .film-filter__ratings-item .film-filter__input::checked + .film-filter__label {
    color: #f50000; }
  .film-filter__ratings-item .film-filter__label {
    display: block;
    cursor: pointer; }
  .film-filter__ratings-item .film-filter__icon {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    margin-right: 10px;
    width: 28px;
    height: 28px; }
  .film-filter__ratings-item:last-of-type .film-filter__icon {
    margin-top: 2px;
    margin-left: 2px;
    width: 25px;
    height: 25px; }

.film-filter__filters-item {
  margin: 0;
  padding: 0;
  line-height: 1;
  border: 0;
  margin-bottom: 28px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e7e7e7; }

.film-filter__filters-list {
  margin: 0;
  padding: 0;
  line-height: 1; }
  .film-filter__filters-list li {
    list-style: none; }

.film-filter__genres-item {
  margin-bottom: 10px;
  height: 25px;
  line-height: 28px; }
  .film-filter__genres-item .film-filter__genres-label {
    font-family: "HelveticaNeueLTStd-Md";
    display: block;
    padding-left: 40px;
    color: #494949;
    font-size: 13px; }

.film-filter__tags-item {
  margin-bottom: 10px;
  height: 25px;
  line-height: 28px; }
  .film-filter__tags-item .film-filter__tags-label {
    font-family: "HelveticaNeueLTStd-Md";
    display: block;
    padding-left: 40px;
    color: #494949;
    font-size: 13px; }

.film-filter__buttons {
  margin: 0;
  padding: 0;
  line-height: 1;
  border: 0;
  font-family: "HelveticaNeueLTStd-MdCn"; }

.film-filter__buttons-clear {
  margin-left: 0;
  padding-left: 0;
  -webkit-transform: translateY(2px);
          transform: translateY(2px); }
  .film-filter__buttons-clear img {
    margin-left: 2px; }

.film-filter__buttons-toggle {
  font-family: "HelveticaNeueLTStd-MdCn";
  margin-left: 0;
  width: 110px;
  padding-left: 0;
  font-size: 14px;
  text-align: left;
  text-transform: uppercase; }
  .film-filter__buttons-toggle img {
    margin-right: 6px;
    margin-left: 4px;
    padding-left: 0; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .film-filter__buttons-toggle img {
        padding-right: 6px; } }

.film-filter__buttons-toggle-closed img {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
  margin-right: 8px;
  margin-left: 2px;
  padding-left: 0; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .film-filter__buttons-toggle-closed img {
      padding-right: 8px; } }

.film-filter__filters-item--tags {
  padding-bottom: 1rem; }

.filter-sorter {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  @media (min-width: 23.5rem) {
    .filter-sorter {
      width: 367px;
      padding: 0; } }
  @media (min-width: 31.25rem) {
    .filter-sorter {
      width: 379px; } }
  @media (min-width: 45.625rem) {
    .filter-sorter {
      width: 652px; } }
  @media (min-width: 60rem) {
    .filter-sorter {
      width: 943px; } }
  @media (min-width: 80rem) {
    .filter-sorter {
      width: 1191px; } }
  @media (min-width: 96.875rem) {
    .filter-sorter {
      width: 1435px; } }
  .filter-sorter .filter-sorter__item {
    position: relative;
    border: 0; }
  .filter-sorter .filter-sorter__item-name {
    margin: 0;
    padding: 0;
    line-height: 1;
    outline: 0;
    border: 0;
    background: none;
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: "HelveticaNeueLTStd-MdCn";
    font-size: 14px;
    text-align: right;
    text-transform: uppercase;
    overflow: visible;
    height: 30px;
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
    position: relative; }
    .filter-sorter .filter-sorter__item-name[disabled] {
      opacity: 0.5; }
    .filter-sorter .filter-sorter__item-name::after {
      content: "";
      -webkit-transform: translateY(-2px);
              transform: translateY(-2px);
      display: inline-block;
      width: 32px;
      height: 10px;
      background-image: url(/assets/toggle-arrow-vert.svg);
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
      -webkit-transition: rotate 300ms;
      transition: rotate 300ms; }
    .filter-sorter .filter-sorter__item-name.filter-sorter-open::after {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      -webkit-transition: rotate 300ms;
      transition: rotate 300ms; }
  .filter-sorter .filter-sorter__options {
    display: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    z-index: 20;
    position: absolute;
    top: 45px;
    right: -20px;
    width: 280px;
    height: 230px;
    padding: 20px;
    border: solid 1px #e2e2e2;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff; }
    .filter-sorter .filter-sorter__options-show {
      display: block; }
    .filter-sorter .filter-sorter__options li {
      list-style: none; }
    .filter-sorter .filter-sorter__options::before, .filter-sorter .filter-sorter__options::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #fff;
      position: absolute;
      top: -14px;
      right: 40px;
      z-index: 200; }
    .filter-sorter .filter-sorter__options::after {
      z-index: 100;
      top: -15px;
      border-bottom: 15px solid rgba(205, 205, 205, 0.8); }
  .filter-sorter .filter-sorter__options-item {
    display: block;
    height: 40px;
    line-height: 25px; }
    .filter-sorter .filter-sorter__options-item .filter-sorter__label {
      padding-left: 40px; }

.films-all {
  padding-bottom: 70px;
  background-color: #f8f8f8; }

.films-all__filter-header {
  margin: auto;
  margin-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 333px;
  padding-bottom: 25px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #e7e7e7; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .films-all__filter-header {
      margin-top: 80px; } }
  @media (min-width: 23.5rem) {
    .films-all__filter-header {
      width: 333px; } }
  @media (min-width: 31.25rem) {
    .films-all__filter-header {
      width: 379px; } }
  @media (min-width: 45.625rem) {
    .films-all__filter-header {
      width: 652px; } }
  @media (min-width: 60rem) {
    .films-all__filter-header {
      width: 943px; } }
  @media (min-width: 80rem) {
    .films-all__filter-header {
      width: 1191px; } }
  @media (min-width: 96.875rem) {
    .films-all__filter-header {
      width: 1435px; } }
  .films-all__filter-header-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }

.films-all__content-wrapper {
  display: grid;
  width: 333px;
  grid-gap: 6px;
  grid-template-columns: 203px 154px; }
  @media (min-width: 23.5rem) {
    .films-all__content-wrapper {
      grid-gap: 10px;
      width: 367px;
      grid-template-columns: 203px 154px; } }
  @media (min-width: 31.25rem) {
    .films-all__content-wrapper {
      width: 379px;
      grid-gap: 22px;
      grid-template-columns: 203px 154px; } }
  @media (min-width: 45.625rem) {
    .films-all__content-wrapper {
      width: 652px;
      grid-gap: 44px;
      grid-template-columns: 203px 450px; } }
  @media (min-width: 60rem) {
    .films-all__content-wrapper {
      width: 943px;
      grid-template-columns: 203px 696px; } }
  @media (min-width: 80rem) {
    .films-all__content-wrapper {
      width: 1191px;
      grid-template-columns: 203px 944px; } }
  @media (min-width: 96.875rem) {
    .films-all__content-wrapper {
      width: 1435px;
      grid-template-columns: 203px 1191px; } }

.films-all__header {
  font-family: "NoeDisplay-Medium";
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid #f7f7f7;
  background-color: #fff;
  color: #f50000;
  text-align: center; }
  @media (max-width: 47.9375rem) {
    .films-all__header .h2 {
      margin-bottom: 0;
      font-size: 32px; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .films-all__header {
      height: 400px; } }

.films-all__cards-contain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.films-all__filter-btn-selected-count {
  color: #af8f8d;
  font-size: 1rem;
  text-transform: capitalize; }

.films-all__tags-container {
  display: none; }
  @media (min-width: 64.0625rem) {
    .films-all__tags-container {
      display: block; } }

.films-all__tags-list {
  margin: 0;
  padding: 0;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .films-all__tags-list li {
    list-style: none; }

.films-all__tags-item {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem; }

.films-all__tags-button {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.125rem 0.375rem 0.125rem 0.0625rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .films-all__tags-button[disabled] {
    opacity: 0.5; }
  .films-all__tags-button:hover {
    background-color: #af8f8d; }
    .films-all__tags-button:hover .films-all__tags-close-btn-icon path {
      stroke: #000; }
    .films-all__tags-button:hover .films-all__tags-name {
      color: #000; }

.films-all__tags-close-btn-icon path {
  stroke: #af8f8d; }

.films-all__tags-name {
  color: #000;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize; }

.films-all .cta {
  margin-top: 40px; }

.film-nocards-notice {
  margin-top: 90px;
  text-align: center;
  font-size: 20px; }
  .film-nocards-notice button {
    margin: 0;
    padding: 0;
    line-height: 1;
    outline: 0;
    border: 0;
    background: none;
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    margin-top: 40px;
    font-size: 16px; }
    .film-nocards-notice button[disabled] {
      opacity: 0.5; }
    .film-nocards-notice button img {
      -webkit-transform: translateY(6px);
              transform: translateY(6px);
      margin-right: 6px; }

.footer {
  width: 100%;
  padding-bottom: 30px;
  border-top: 1px solid #e7e7e7;
  background-color: #000; }

.footer__top-wrapper {
  background-color: #000; }

.footer__container {
  padding: 0; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .footer__container {
      padding: 0 22px 22px 22px; } }

.footer__newsletter-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 40px 20px 40px 20px;
  background-color: #fff;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .footer__newsletter-container {
      padding: 50px 42px; } }
  .footer__newsletter-container .footer_newsletter-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1440px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media (min-width: 64rem) {
      .footer__newsletter-container .footer_newsletter-content {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }

.footer__newsletter-details {
  text-align: left; }
  .footer__newsletter-details h1 {
    font-family: "HelveticaNeueLTStd-BdCn";
    margin-top: 6px;
    margin-bottom: 4px;
    font-size: 16px;
    text-transform: uppercase; }
  .footer__newsletter-details p {
    margin-bottom: 0;
    color: #505050;
    font-size: 15px;
    font-family: "HelveticaNeueLTStd-Md"; }

.footer__newsletter-field-wrapper {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (min-width: 64rem) {
    .footer__newsletter-field-wrapper {
      margin-top: 0;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }

.footer__newsletter-field {
  margin: 0;
  padding: 0;
  line-height: 1;
  resize: none;
  border: 0;
  background: transparent;
  color: #000;
  font-family: inherit;
  margin-right: 12px;
  background-clip: padding-box;
  width: calc(100vw - 170px);
  height: 48px;
  padding: 0 10px;
  border: 1px solid #f8f8f8;
  border-radius: 0;
  background-color: #f8f8f8; }
  .footer__newsletter-field:focus {
    outline: none; }
  .footer__newsletter-field::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .footer__newsletter-field::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .footer__newsletter-field:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .footer__newsletter-field:-moz-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .footer__newsletter-field {
      width: 350px;
      padding: 0 22px; } }
  @media (min-width: 64rem) {
    .footer__newsletter-field {
      width: 300px; } }
  @media (min-width: 64.0625rem) {
    .footer__newsletter-field {
      width: 450px; } }
  @media (min-width: 80rem) {
    .footer__newsletter-field {
      width: 650px; } }
  @media (min-width: 90rem) {
    .footer__newsletter-field {
      width: 800px; } }
  .footer__newsletter-field:hover {
    border: 1px solid #e7e7e7; }
  .footer__newsletter-field:focus {
    border: 1px solid #e7e7e7; }
  .footer__newsletter-field::-webkit-input-placeholder {
    color: #a7a7a7; }
  .footer__newsletter-field::-moz-placeholder {
    color: #a7a7a7; }
  .footer__newsletter-field:-ms-input-placeholder {
    color: #a7a7a7; }
  .footer__newsletter-field::-ms-input-placeholder {
    color: #a7a7a7; }
  .footer__newsletter-field::placeholder {
    color: #a7a7a7; }

.footer__newsletter-field-button {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: "HelveticaNeueLTStd-BdCn";
  width: 110px;
  border-radius: 0;
  height: 48px;
  padding-top: 5px;
  background-color: #f50000;
  color: #fff;
  cursor: pointer; }
  .footer__newsletter-field-button[disabled] {
    opacity: 0.5; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .footer__newsletter-field-button {
      width: 180px; } }
  .footer__newsletter-field-button:hover {
    background-color: #000; }

.footer__links-container {
  margin-top: 80px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 22px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .footer__links-container img {
    display: block; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .footer__links-container {
      margin-top: 110px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -ms-flex-line-pack: end;
          align-content: flex-end; } }

.footer__links-list {
  margin: 0;
  padding: 0;
  line-height: 1;
  list-style: none; }
  .footer__links-list li {
    list-style: none; }
  @media (max-width: 47.9375rem) {
    .footer__links-list {
      text-align: center;
      margin-bottom: 60px; } }

.footer__links-item {
  font-family: "HelveticaNeueLTStd-BdCn";
  display: inline-block;
  padding: 0 9px;
  font-size: 13px;
  line-height: 3; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .footer__links-item {
      font-size: 15px;
      padding-right: 0;
      padding-left: 30px; } }

.footer__links {
  color: #999;
  text-decoration: none; }
  .footer__links:visited {
    color: #999; }
  @media (min-width: 64.0625rem) {
    .footer__links:hover {
      color: #fff; } }

.footer__bottom-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid #222; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .footer__bottom-wrapper {
      margin-right: 22px;
      margin-left: 22px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -ms-flex-line-pack: end;
          align-content: flex-end; } }
  .footer__bottom-wrapper .footer__license {
    font-family: "HelveticaNeueLTStd-Lt";
    margin: 0;
    color: #999;
    font-size: 12px; }
    .footer__bottom-wrapper .footer__license a {
      color: #999;
      text-decoration: none; }
  .footer__bottom-wrapper .footer__social-container {
    text-align: center; }
    .footer__bottom-wrapper .footer__social-container .main-nav__social-list {
      margin: 0;
      padding: 0; }
    .footer__bottom-wrapper .footer__social-container .main-nav__social-item {
      margin: 10px 10px 20px 10px;
      display: inline-block; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .footer__bottom-wrapper .footer__social-container .main-nav__social-item {
          margin: 0 0 0 15px;
          text-align: right; } }
      .footer__bottom-wrapper .footer__social-container .main-nav__social-item a {
        color: #fff; }

.editorial-trio {
  position: relative;
  padding-top: 40px;
  padding-bottom: 2px; }
  .editorial-trio.editorial-trio-page-bottom .content-wrapper {
    border-bottom: 0; }
  .page-stories .editorial-trio {
    padding-top: 20px; }
  .page-home .editorial-trio .content-wrapper {
    border-bottom: 0; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .editorial-trio {
      padding-top: 90px;
      padding-bottom: 20px; }
      .editorial-trio .content-wrapper {
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 100px; } }
  .editorial-trio h1 {
    font-family: "DINPro-CondBlack";
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    margin: 0;
    width: 100%;
    color: #f8f8f8;
    font-size: 80px;
    line-height: 66px;
    text-align: left;
    text-transform: uppercase; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .editorial-trio h1 {
        text-align: center;
        -webkit-transform: translateY(40px);
                transform: translateY(40px);
        font-size: 120px; } }
    @media (min-width: 64rem) {
      .editorial-trio h1 {
        -webkit-transform: translateY(30px);
                transform: translateY(30px);
        font-size: 144px; } }

.no-image-editorial {
  padding: 0 0 30px 0; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .no-image-editorial {
      padding: 60px 0;
      padding-left: 40px; } }
  @media (min-width: 64rem) {
    .no-image-editorial {
      padding: 60px 0;
      padding-left: 50px; } }
  @media (min-width: 1318px) {
    .no-image-editorial {
      padding-left: 0; } }
  .page-all-articles .no-image-editorial h5 {
    display: block; }
  .no-image-editorial .ad-col-column {
    position: relative; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .no-image-editorial .ad-col-column img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); } }

.carousel--homeHeader {
  overflow: hidden;
  position: relative;
  height: 90vh;
  text-align: center;
  -webkit-transition: height 1000ms;
  transition: height 1000ms; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .carousel--homeHeader {
      height: 100vh; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .carousel--homeHeader.slide-up {
      -webkit-transition: -webkit-transform 1500ms;
      transition: -webkit-transform 1500ms;
      transition: transform 1500ms;
      transition: transform 1500ms, -webkit-transform 1500ms;
      -webkit-transform: translateY(-100vh);
              transform: translateY(-100vh); } }
  .page-scrolled .carousel--homeHeader .home-header__caption {
    opacity: 0; }
  .carousel--homeHeader .slick-dots {
    margin: 0;
    padding: 0;
    line-height: 1;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 40px; }
    .carousel--homeHeader .slick-dots li {
      list-style: none; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .carousel--homeHeader .slick-dots {
        bottom: 186px; } }
    .carousel--homeHeader .slick-dots li {
      display: inline-block;
      padding: 10px 6px;
      opacity: 0.2;
      -webkit-transition: opacity 300ms;
      transition: opacity 300ms; }
      .carousel--homeHeader .slick-dots li button {
        margin: 0;
        padding: 0;
        line-height: 1;
        outline: 0;
        border: 0;
        background: none;
        font-family: inherit;
        cursor: pointer;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        position: relative;
        width: 50px;
        height: 30px;
        background-color: transparent;
        color: transparent; }
        .carousel--homeHeader .slick-dots li button[disabled] {
          opacity: 0.5; }
        .carousel--homeHeader .slick-dots li button::before {
          content: "";
          position: absolute;
          top: 24px;
          left: 0;
          width: 50px;
          height: 2px;
          background-color: #fff; }
      .carousel--homeHeader .slick-dots li.slick-active {
        opacity: 0.8;
        -webkit-transition: opacity 300ms;
        transition: opacity 300ms; }

.home-header {
  display: block; }

.carousel--homeHeader.slick-initialized .slick-slide.slick-active {
  z-index: 10; }

.home-header__link {
  display: none;
  height: 100vh; }

.slick-current .home-header__link {
  display: block; }

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .video-container video {
    /* Make video to at least 100% wide and tall */
    width: auto;
    height: auto;
    width: 100vw; }

.home-header {
  position: relative;
  height: 90vh;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .home-header {
      height: 100vh; } }
  .home-header__caption {
    position: absolute;
    bottom: 100px;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    opacity: 1;
    -webkit-transition: opacity 400ms ease-out;
    transition: opacity 400ms ease-out; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .home-header__caption {
        position: unset;
        bottom: unset;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); } }
    .home-header__caption h1 {
      font-family: "ActionCondensedBold-Grade2";
      margin: 0;
      margin-bottom: 3px;
      padding: 0 20px;
      font-size: 105px;
      letter-spacing: 8px;
      line-height: 85px; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .home-header__caption h1 {
          padding: 0;
          font-size: 100px;
          letter-spacing: 16px;
          line-height: 56px; } }
      @media (min-width: 64rem) {
        .home-header__caption h1 {
          font-size: 140px;
          letter-spacing: 27px;
          line-height: 140px; } }
      @media (min-width: 80rem) {
        .home-header__caption h1 {
          font-size: 180px;
          letter-spacing: 35px;
          line-height: 170px; } }
    .home-header__caption h2 {
      font-family: "HelveticaNeueLTStd-Md";
      margin-top: 12px;
      font-size: 14px; }
  .home-header.home-header--editorial h1 {
    font-family: "NoeDisplay-Medium";
    font-size: 60px;
    letter-spacing: 0;
    line-height: 70px;
    text-transform: initial; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .home-header.home-header--editorial h1 {
        font-size: 80px;
        letter-spacing: 2px;
        line-height: 80px; } }
    @media (min-width: 64rem) {
      .home-header.home-header--editorial h1 {
        font-size: 97px;
        letter-spacing: 3px;
        line-height: 97px; } }
  .home-header.home-header--noe-regular h1 {
    font-family: "NoeDisplay-Medium";
    font-size: 60px;
    letter-spacing: 0;
    line-height: 70px;
    text-transform: initial; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .home-header.home-header--noe-regular h1 {
        font-size: 80px;
        letter-spacing: 2px;
        line-height: 80px; } }
    @media (min-width: 64rem) {
      .home-header.home-header--noe-regular h1 {
        font-size: 97px;
        letter-spacing: 3px;
        line-height: 97px; } }
  .home-header.home-header--action-mixed h1 {
    text-transform: initial; }
  .home-header.home-header--noe-italic h1 {
    font-family: "NoeDisplay-MediumItalic";
    font-size: 60px;
    letter-spacing: 0;
    line-height: 70px;
    text-transform: initial; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .home-header.home-header--noe-italic h1 {
        font-size: 80px;
        letter-spacing: 2px;
        line-height: 80px; } }
    @media (min-width: 64rem) {
      .home-header.home-header--noe-italic h1 {
        font-size: 97px;
        letter-spacing: 3px;
        line-height: 97px; } }
  .home-header.home-header--muli-bold h1 {
    font-family: "Muli-Bold";
    font-size: 60px;
    letter-spacing: 0;
    line-height: 70px;
    text-transform: initial; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .home-header.home-header--muli-bold h1 {
        font-size: 80px;
        letter-spacing: 2px;
        line-height: 80px; } }
    @media (min-width: 64rem) {
      .home-header.home-header--muli-bold h1 {
        font-size: 97px;
        letter-spacing: 3px;
        line-height: 97px; } }
  .home-header.home-header--muli-italic h1 {
    font-family: "Muli-SemiBoldItalic";
    font-size: 60px;
    letter-spacing: 0;
    line-height: 70px;
    text-transform: initial; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .home-header.home-header--muli-italic h1 {
        font-size: 80px;
        letter-spacing: 2px;
        line-height: 80px; } }
    @media (min-width: 64rem) {
      .home-header.home-header--muli-italic h1 {
        font-size: 97px;
        letter-spacing: 3px;
        line-height: 97px; } }

.image-callout {
  position: absolute; }

.image-callout--new-release-right {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--new-release-right {
      right: calc(50% - 86rem/2);
      bottom: 0;
      display: block;
      max-width: 12.25rem; } }

.image-callout__image {
  width: 100%;
  vertical-align: bottom; }

.image-callout--featured-article-right {
  right: 0;
  bottom: -2.3rem;
  max-width: 7.4375rem; }
  @media (min-width: 51.25rem) {
    .image-callout--featured-article-right {
      right: calc(50% - 86rem/2);
      bottom: -4rem;
      max-width: 19.8125rem; } }

.image-callout--featured-article-left {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--featured-article-left {
      bottom: -1.2rem;
      left: calc(50% - 88rem/2);
      display: block;
      max-width: 16.75rem; } }

.image-callout--recent-articles-right {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--recent-articles-right {
      right: calc(50% - 89rem/2);
      bottom: -0.5rem;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
      display: block;
      max-width: 16.75rem; } }

.image-callout--all-articles-left {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--all-articles-left {
      bottom: 0rem;
      left: calc(50% - 85rem/2);
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
      display: block;
      max-width: 19.8125rem; } }

.image-callout--film-collections-right {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--film-collections-right {
      right: calc(50% - 85rem/2);
      bottom: 0rem;
      display: block;
      max-width: 12.25rem; } }

.image-callout--film-collections-left {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--film-collections-left {
      bottom: -0.2rem;
      left: calc(50% - 95rem/2);
      display: block;
      max-width: 16.875rem; } }

.image-callout--film-details-overviews-right {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--film-details-overviews-right {
      right: calc(50% - 90rem/2);
      bottom: -4rem;
      display: block;
      max-width: 13rem; } }

.image-callout--film-details-reviews-right, .image-callout--author-articles-right {
  right: 0;
  bottom: -2.8rem;
  max-width: 10.7rem; }
  @media (min-width: 51.25rem) {
    .image-callout--film-details-reviews-right, .image-callout--author-articles-right {
      right: calc(50% - 86rem/2);
      bottom: 8.5rem;
      max-width: 19.8125rem; } }
  .image-callout--film-details-reviews-right--desktop {
    display: none; }
    @media (min-width: 51.25rem) {
      .image-callout--film-details-reviews-right--desktop {
        display: block; } }
  .image-callout--film-details-reviews-right--mobile {
    display: block; }
    @media (min-width: 51.25rem) {
      .image-callout--film-details-reviews-right--mobile {
        display: none; } }

.image-callout--film-details-reviews-left, .image-callout--author-articles-left {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--film-details-reviews-left, .image-callout--author-articles-left {
      z-index: -1;
      bottom: 8.5rem;
      left: calc(50% - 95rem/2);
      display: block;
      max-width: 16.75rem; } }

.image-callout--article-left {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--article-left {
      bottom: -1.2rem;
      left: calc(50% - 88rem/2);
      display: block;
      max-width: 16.75rem; } }

.image-callout--article-right {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--article-right {
      right: calc(50% - 86rem/2);
      bottom: 0rem;
      display: block;
      max-width: 19.8125rem; } }

.image-callout--films-all-right, .image-callout--search-right {
  display: none; }
  @media (min-width: 51.25rem) {
    .image-callout--films-all-right, .image-callout--search-right {
      right: calc(50% - 90rem/2);
      bottom: 19.375rem;
      display: block;
      max-width: 13rem; } }

.image-callout--not-found--left {
  position: relative;
  left: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  width: 13rem;
  height: 10.9375rem; }
  @media (min-width: 64.0625rem) {
    .image-callout--not-found--left {
      position: absolute;
      bottom: 19.375rem;
      width: 27.3125rem;
      height: 23rem; } }

.loader {
  margin: 100px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .page-home .loader {
    margin: 0;
    height: 100vh; }

.tech-talk {
  font-family: "HelveticaNeueLTStd-Cn";
  text-align: justify;
  padding-bottom: 100px; }
  .tech-talk h1,
  .tech-talk h2,
  .tech-talk h3,
  .tech-talk h4 {
    margin: 0; }
  .tech-talk h1 {
    margin-top: 120px;
    font-size: 16px; }
  .tech-talk h2 {
    font-size: 14px;
    margin-bottom: 20px; }
  .tech-talk h3 {
    font-size: 14px; }
  .tech-talk h4 {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 6px; }
  .tech-talk p {
    font-family: "HelveticaNeueLTStd-Lt";
    line-height: 18px;
    font-size: 12px;
    line-height: 12px; }
  .tech-talk ul {
    font-family: "HelveticaNeueLTStd-Lt";
    -webkit-padding-start: 20px;
            padding-inline-start: 20px; }
  .tech-talk ul li {
    font-size: 12px;
    line-height: 14px; }
  .tech-talk a {
    color: #f50000; }

.main-header {
  z-index: 300;
  position: fixed;
  top: 0;
  right: auto;
  left: auto;
  width: 100vw;
  height: 71px;
  border-bottom: 1px solid #e7e7e7;
  background: #fff; }
  body.page-home .main-header {
    opacity: 0; }
  body.page-home.page-loaded .main-header {
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-transition-delay: 900ms;
            transition-delay: 900ms;
    opacity: 1; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-header {
      height: 100px; } }
  .main-header__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .main-header__wrapper {
        margin: 0 auto;
        display: block;
        width: 100%;
        padding: 0; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-header__search-mobile {
      display: none; } }
  .main-header__search {
    display: none; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .main-header__search {
        z-index: 22;
        position: absolute;
        top: 37px;
        right: 32px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 4.375rem;
        -webkit-transition: all 225ms;
        transition: all 225ms; } }
  .main-header__search-svg {
    width: 22px; }
    .main-header__search-svg path,
    .main-header__search-svg line {
      stroke: #000; }
    .main-header__search-svg:hover path,
    .main-header__search-svg:hover line,
    .main-header__search-svg:hover circle {
      stroke: #f50000; }
  .main-header__toggle {
    margin: 0;
    padding: 0;
    line-height: 1;
    outline: 0;
    border: 0;
    background: none;
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .main-header__toggle[disabled] {
      opacity: 0.5; }
  .main-header__logo {
    margin: 0;
    padding: 0;
    line-height: 1;
    outline: 0;
    border: 0;
    background: none;
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    margin-top: 12px;
    margin-left: 12px;
    display: block;
    width: 79px;
    height: 44px;
    background-image: url(/assets/cherry-picks-header-logo.svg);
    background-size: cover; }
    .main-header__logo[disabled] {
      opacity: 0.5; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .main-header__logo {
        z-index: 20;
        position: absolute;
        margin-top: 21px;
        margin-left: 22px; } }
  .main-header__logo-back {
    margin-left: 19px;
    width: 40px;
    border: 0;
    background-color: #fff;
    background-image: none; }
  .main-header__logo-img {
    width: 60px;
    text-align: left; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .main-header__logo-img {
        width: 92px; } }
  .main-header__toggle {
    position: relative;
    margin-top: 11px;
    margin-right: 20px;
    width: 14px;
    height: 14px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .main-header__toggle {
        display: none; } }
  .main-header__toggle-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transition: all 225ms;
    transition: all 225ms; }
    .main-header__toggle-svg--close {
      -webkit-transform: translate(-50%, -50%) scale(0);
              transform: translate(-50%, -50%) scale(0); }
      .nav-opened .main-header__toggle-svg--close {
        -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1); }
    .main-header__toggle-svg--open {
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1); }
      .nav-opened .main-header__toggle-svg--open {
        -webkit-transform: translate(-50%, -50%) scale(0);
                transform: translate(-50%, -50%) scale(0); }

.main-nav {
  position: fixed;
  top: 71px;
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: calc(100% - 71px);
  padding-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #fff;
  text-align: center;
  opacity: 0; }
  .main-nav button {
    margin: 0;
    padding: 0;
    line-height: 1;
    outline: 0;
    border: 0;
    background: none;
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: "HelveticaNeueLTStd-Md";
    color: inherit;
    text-decoration: none;
    margin-bottom: 5px;
    display: block;
    width: 100%;
    padding: 20px 30px;
    border: 0;
    font-size: 13px;
    text-align: left; }
    .main-nav button[disabled] {
      opacity: 0.5; }
    @media (min-width: 23.375rem) {
      .main-nav button {
        font-size: 18px; } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .main-nav button {
        font-family: "HelveticaNeueLTStd-MdCn";
        margin-bottom: unset;
        width: unset;
        padding: 20px 0;
        font-size: 16px;
        text-align: center; }
        .main-nav button:hover {
          color: #f50000; }
          .main-nav button:hover svg path {
            fill: #f50000; } }
  @media (max-width: 64.0625rem) {
    .main-nav {
      -webkit-transition: opacity 225ms;
      transition: opacity 225ms; } }
  @media (max-width: 64.0625rem) and (orientation: landscape) {
    .nav-opened .main-nav {
      overflow: auto; } }
  .nav-opened .main-nav {
    visibility: visible;
    opacity: 1; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .page-scrolled .main-nav {
      margin-top: 0; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-nav {
      position: absolute;
      top: 0;
      right: auto;
      left: auto;
      margin: 0 auto;
      visibility: visible;
      display: block;
      width: 100%;
      height: auto;
      padding-top: 0;
      opacity: 1; } }

.main-header-section {
  position: absolute;
  height: 100vh; }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .main-nav__nav--top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 225ms;
    transition: all 225ms; } }

.main-nav__nav--bottom {
  margin-top: 4.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: top 225ms;
  transition: top 225ms; }
  @media (max-width: 64.0625rem) and (orientation: landscape) {
    .main-nav__nav--bottom {
      display: block; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-nav__nav--bottom {
      position: absolute;
      top: -1.5rem;
      right: 0;
      margin-top: 0;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-flex: inherit;
          -ms-flex-positive: inherit;
              flex-grow: inherit; } }

.main-nav__list {
  margin: 0;
  padding: 0;
  line-height: 1;
  margin-top: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  color: #000; }
  .main-nav__list li {
    list-style: none; }
  .main-nav__list .main-nav__list-item {
    width: 50%; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-nav__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: unset;
      -webkit-box-direction: unset;
          -ms-flex-direction: unset;
              flex-direction: unset;
      -ms-flex-wrap: unset;
          flex-wrap: unset; }
      .main-nav__list .main-nav__list-item {
        width: unset; } }
  @media (min-width: 23.375rem) {
    .main-nav__list {
      margin-top: 10px; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-nav__list {
      margin-top: 0;
      margin-right: 1.25rem;
      margin-left: 1.25rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .main-nav__list-item + .main-nav__list-item {
    margin-top: 0;
    margin-left: 42px; } }

@media (min-width: 64rem) {
  .main-nav__list-item + .main-nav__list-item {
    margin-top: 0;
    margin-left: 118px; } }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .main-nav__list-item.main-nav__list-item-mobile {
    display: none; } }

.main-nav__list-item.main-nav__list-item_rating {
  position: relative; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-nav__list-item.main-nav__list-item_rating {
      position: absolute;
      right: 70px;
      padding-right: 14px;
      padding-left: 36px; }
      .main-nav__list-item.main-nav__list-item_rating .main-nav__list-link::after {
        content: "";
        position: absolute;
        top: 14px;
        right: 0;
        width: 1px;
        height: 24px;
        background-color: #e7e7e7; }
      .main-nav__list-item.main-nav__list-item_rating .main-nav__list-link::before {
        content: "";
        position: absolute;
        top: 11px;
        left: 0;
        width: 28px;
        height: 28px;
        background-image: url(/assets/cherry-ratings-logo.svg);
        background-size: 28px 28px; } }

.main-nav__list-link {
  font-family: "HelveticaNeueLTStd-Md";
  color: inherit;
  text-decoration: none;
  margin-bottom: 5px;
  display: block;
  padding: 20px 30px;
  font-size: 13px;
  text-align: left; }
  @media (min-width: 23.375rem) {
    .main-nav__list-link {
      font-size: 18px; } }
  .main-nav__list-link-active {
    color: #f50000; }
    .main-nav__list-link-active svg path {
      fill: #f50000; }
  .main-nav__list-link .nav-toggle-film {
    -webkit-transform: rotate(270deg) translateX(3px);
            transform: rotate(270deg) translateX(3px);
    margin-left: 10px;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }
  .main-nav__list-link-hover .nav-toggle-film {
    -webkit-transform: rotate(360deg) translateX(0) translateY(-2px);
            transform: rotate(360deg) translateX(0) translateY(-2px);
    margin-left: 10px;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }
  .main-nav__list-link .nav-toggle-film__toggled {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    margin-left: 10px; }
  .main-nav__list-link span {
    padding-right: 5px;
    color: #bbb; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-nav__list-link {
      font-family: "HelveticaNeueLTStd-MdCn";
      padding: 20px 0;
      font-size: 16px; }
      .main-nav__list-link:hover {
        color: #f50000; }
        .main-nav__list-link:hover svg path {
          fill: #f50000; } }

.main-nav__critic {
  margin: 0;
  padding: 0;
  line-height: 1;
  color: #fff; }
  .main-nav__critic li {
    list-style: none; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-nav__critic {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

.main-nav__critic-item + .main-nav__critic-item {
  margin-top: 2.45rem; }

@media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
  .main-nav__critic-item + .main-nav__critic-item {
    margin-top: 0;
    margin-left: 1.75rem; } }

.main-nav__critic-link {
  color: inherit;
  text-decoration: none; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .main-nav__critic-link {
      font-size: 0.75rem; }
      .main-nav__critic-link:hover {
        color: #f50000; } }

.main-header-section {
  position: absolute;
  top: 0;
  height: 100vh; }

.main-header-home {
  overflow: hidden;
  z-index: 100;
  position: absolute;
  bottom: -20px;
  margin-left: 30px;
  width: calc(100vw - 60px);
  height: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
  opacity: 0;
  pointer-events: none; }
  .main-header-home .icon-contain {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-image: url(/assets/cherry-picks-graphic-logo-dark.svg);
    background-position: center;
    background-size: 300px auto;
    background-repeat: no-repeat;
    opacity: 0;
    pointer-events: none; }
  .page-splash .main-header-home {
    opacity: 1;
    pointer-events: initial; }
    .page-splash .main-header-home .main-nav-home__nav {
      display: none; }
    @media (min-width: 64rem) {
      .page-splash .main-header-home {
        height: 170px; }
        .page-splash .main-header-home .main-nav-home__nav {
          display: block; } }
  .page-home .main-header-home {
    top: 0;
    bottom: auto;
    margin-left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1; }
    .page-home .main-header-home .main-nav-home__nav {
      display: none; }
    .page-home .main-header-home .icon-contain {
      opacity: 1; }
  .page-loaded .main-header-home {
    opacity: 0;
    -webkit-transition: opacity 250ms;
    transition: opacity 250ms;
    -webkit-transition-delay: 50ms;
            transition-delay: 50ms; }
  .main-header-home .main-header-home__wrapper {
    position: relative; }
  .main-header-home .main-nav-home {
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 170px;
    padding-top: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #fff;
    opacity: 1; }
    .page-home .main-header-home .main-nav-home {
      height: 100vh; }
  .main-header-home .main-nav-home__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 871px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .main-header-home .main-nav-home__list a {
      font-family: "HelveticaNeueLTStd-MdCn";
      color: #000;
      text-decoration: none; }
      .main-header-home .main-nav-home__list a span {
        color: #bbb; }
      .main-header-home .main-nav-home__list a:hover {
        color: #f50000; }
  .main-header-home.slide-up {
    -webkit-animation: navSwoop 1s;
            animation: navSwoop 1s;
    pointer-events: none; }
    .main-header-home.slide-up .main-nav-home__list {
      opacity: 0;
      -webkit-transition: opacity 300ms;
      transition: opacity 300ms; }
    .main-header-home.slide-up .icon-contain {
      opacity: 1;
      -webkit-transition: opacity 900ms;
      transition: opacity 900ms; }

@-webkit-keyframes navSwoop {
  0% { }
  20% {
    margin-left: 0;
    width: 100vw; }
  50% {
    width: 100vw;
    opacity: 1; }
  90% {
    bottom: 0;
    margin-left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1; }
  100% {
    bottom: 0;
    margin-left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1; } }

@keyframes navSwoop {
  0% { }
  20% {
    margin-left: 0;
    width: 100vw; }
  50% {
    width: 100vw;
    opacity: 1; }
  90% {
    bottom: 0;
    margin-left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1; }
  100% {
    bottom: 0;
    margin-left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1; } }

.main-nav-home__list {
  margin: 0;
  padding: 0;
  line-height: 1;
  margin-top: 2.45rem;
  display: block;
  color: #000; }
  .main-nav-home__list li {
    list-style: none; }
  @media (min-width: 64.0625rem) {
    .main-nav-home__list {
      margin-top: 0;
      margin-right: 1.25rem;
      margin-left: 1.25rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

.progressbar-container {
  margin-top: 0.25rem;
  height: 0.25rem;
  background-color: #faccc9; }

.progressbar-progress {
  height: 0.25rem;
  background-color: #cb352e; }

.ratings:before {
  content: "";
  z-index: 1;
  position: absolute;
  right: 40px;
  bottom: 12px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .ratings:before {
      width: 24px;
      height: 24px;
      background-size: 24px; } }

.ratings.score-percent-long:before {
  -webkit-transform: translateX(-6px);
          transform: translateX(-6px); }

.ratings--timer:before {
  right: 44px;
  bottom: 15px;
  width: 18px;
  height: 18px;
  background-image: url(/assets/wait-icon.svg);
  background-size: 18px; }

.ratings--love:before {
  background-image: url(/assets/lots-icon.svg); }

.ratings--sweet:before {
  background-image: url(/assets/two-icon.svg); }

.ratings--slim:before {
  background-image: url(/assets/one-icon.svg); }

.ratings--bad:before {
  background-image: url(/assets/nuts-icon.svg); }

.search__container {
  min-height: calc(100vh - 607px); }

.results {
  padding-top: 40px;
  background-color: #f8f8f8; }
  .results h2 {
    font-family: "HelveticaNeueLTStd-BdCn";
    border-bottom: 1px solid #e7e7e7;
    font-size: 13px;
    text-transform: uppercase; }
  .results .results-item {
    font-family: "HelveticaNeueLTStd-Md";
    display: block;
    color: #000;
    text-decoration: none; }
  .results .results-item-text-year {
    font-family: "HelveticaNeueLTStd-Cn";
    padding-top: 5px;
    color: #555;
    font-size: 14px; }
  .results .results-item-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .results .results-critic-item, .results .results-author-item {
    font-family: "HelveticaNeueLTStd-Lt";
    font-size: 13px; }
    .results .results-critic-item .results-profile-pic, .results .results-author-item .results-profile-pic {
      width: 64px;
      height: 64px;
      -o-object-fit: cover;
         object-fit: cover;
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
      border-radius: 100%; }
    .results .results-critic-item .results-item-text-title, .results .results-author-item .results-item-text-title {
      font-family: "HelveticaNeueLTStd-Md";
      font-size: 16px; }
    .results .results-critic-item .results-item-text-description, .results .results-author-item .results-item-text-description {
      padding-top: 1px;
      height: 27px;
      overflow: hidden; }
      .results .results-critic-item .results-item-text-description p, .results .results-author-item .results-item-text-description p {
        font-size: 13px; }
    .results .results-critic-item p, .results .results-author-item p {
      margin: 0; }
  .results .results-collections-item {
    font-family: "NoeDisplay-Medium";
    font-size: 27px;
    letter-spacing: 0.04rem;
    padding: 13%;
    margin: 20px;
    width: calc(50% - 100px);
    background-color: darkred;
    color: #fff;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .results .results-article-collections-item {
    font-family: "NoeDisplay-Medium";
    font-size: 27px;
    letter-spacing: 0.04rem;
    padding: 13% 0;
    background-color: darkred;
    color: #fff;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .results .results-article-collections-item p {
      padding: 0 40px; }

.results-articles h2 {
  margin-bottom: 0; }

.results-grid-articles {
  padding-top: 5px; }

.results-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .results-grid .results-item {
    width: 100%;
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .results-grid .results-item {
        width: 50%; } }
    .results-grid .results-item img {
      margin-right: 30px; }
  .results-grid .film-poster {
    width: 100px;
    height: 150px;
    -o-object-fit: cover;
       object-fit: cover; }
  .results-grid .results-article-item {
    font-family: "PlayfairDisplay-Regular";
    padding: 40px 20px 30px 0;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    font-size: 18px;
    line-height: 28px; }
  .results-grid .results-collections-item,
  .results-grid .results-article-collections-item {
    margin: 20px;
    width: calc(50% - 40px); }

.review-card__center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.review-card__container {
  margin-bottom: 40px;
  display: grid; }
  .review-card__container .review-card {
    margin-bottom: 20px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .review-card__container {
      width: 756px;
      grid-gap: 6px;
      grid-template-columns: repeat(2, 375px); }
      .review-card__container .review-card {
        margin-bottom: 0; } }
  @media (min-width: 64rem) {
    .review-card__container {
      width: 940px;
      grid-gap: 20px;
      grid-template-columns: repeat(3, 300px); }
      .review-card__container .review-card {
        width: 300px; } }
  @media (min-width: 80rem) {
    .review-card__container {
      width: 1165px;
      grid-template-columns: repeat(3, 375px); }
      .review-card__container .review-card {
        width: unset; } }

.review-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 546px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #e7e7e7;
  background: #fff;
  text-align: center; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .review-card {
      width: 375px; } }

.review-card__reviewer-publisher-details {
  font-family: "NoeDisplay-Medium";
  font-size: 16px;
  margin-top: 50px;
  margin-bottom: 80px; }
  .review-card__reviewer-publisher-details:hover {
    color: #f50000; }
  .review-card__reviewer-publisher-details .review-card__reviewer-link {
    display: block;
    margin-bottom: 7px; }
  .review-card__reviewer-publisher-details .review-card__reviewer-link-image {
    height: 20px;
    width: 60%;
    -o-object-fit: contain;
       object-fit: contain; }

.review-card__review-text-contain {
  padding: 0 40px; }

.review-card__review-text {
  height: 302px;
  font-family: "PlayfairDisplay-Regular";
  font-size: 18px;
  color: #222; }
  .review-card__review-text:before {
    content: "“"; }
  .review-card__review-text:after {
    content: "”"; }
  .review-card--default .review-card__review-text {
    font-size: 19px;
    line-height: 1.55; }
  .review-card--large .review-card__review-text {
    font-size: 32px; }

.review-card__reviewer {
  font-family: "HelveticaNeueLTStd-MdCn";
  color: #000;
  font-size: 16px;
  position: absolute;
  bottom: 97px;
  z-index: 10;
  left: 0;
  right: 0;
  width: 100%; }

.review-card__reviewer-date {
  display: inline-block;
  color: #555;
  font-size: 0.8125rem;
  letter-spacing: 0.01875rem; }

.review-card__reviewer-info {
  text-decoration: none; }

.review-card__reviewer-name {
  color: #555; }
  .review-card__reviewer-name:hover {
    color: #f50000; }

.review-card__reviewer-link {
  color: inherit;
  text-decoration: none;
  color: #000;
  font-size: 16px; }
  .review-card__reviewer-link:hover {
    color: #f50000; }

.review-card__reviewer-read-more {
  color: inherit;
  text-decoration: none;
  font-family: "HelveticaNeueLTStd-MdCn";
  display: inline-block;
  color: #222;
  font-size: 14px;
  text-transform: capitalize; }
  .review-card__reviewer-read-more:hover {
    color: #f50000; }

.review-card__footer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 30px 24px 22px 24px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  background-color: white; }
  .review-card__footer::before {
    content: "";
    overflow: hidden;
    position: absolute;
    bottom: 68px;
    left: 0;
    width: 100%;
    height: 200px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(rgba(255, 255, 255, 0.95)), to(white));
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.95), white); }

.review-card--featured {
  width: 100%;
  height: unset;
  padding: 0;
  margin-bottom: 80px;
  border: 0;
  border-top: 2px solid #000;
  text-align: left; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .review-card--featured {
      width: 354px;
      margin-bottom: 0; } }
  @media (min-width: 64rem) {
    .review-card--featured {
      width: 446px; } }
  @media (min-width: 80rem) {
    .review-card--featured {
      width: 528px; } }
  .review-card--featured .review-card__review-text-contain {
    padding: 0; }
  .review-card--featured .review-card__review-text {
    margin-top: 30px;
    height: unset;
    font-size: 24px;
    line-height: 32px; }
  .review-card--featured .review-card__reviewer {
    font-family: "HelveticaNeueLTStd-BdCn";
    position: relative;
    bottom: unset;
    width: unset; }
    .review-card--featured .review-card__reviewer .review-card__reviewer-name {
      padding-bottom: 8px;
      color: #000; }
  .review-card--featured .review-card__reviewer-link {
    font-family: "HelveticaNeueLTStd-Lt";
    padding-left: 6px;
    font-size: 13px; }
  .review-card--featured .review-card__reviewer-date {
    display: block; }
    .review-card--featured .review-card__reviewer-date span {
      padding-right: 6px; }
  .review-card--featured .review-card__reviewer-read-more {
    margin-top: 80px;
    margin-bottom: 20px;
    display: block; }
  .review-card--featured .review-card__footer {
    width: unset;
    padding: 0;
    padding-top: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .review-card--featured .review-card__footer::before {
      display: none; }
  .review-card--featured .review-card__footer-photo {
    margin-right: 20px; }
    .review-card--featured .review-card__footer-photo img {
      border: 1px solid white;
      width: 48px;
      height: 48px;
      border-radius: 100%; }
  .review-card--featured .review-card__footer-text {
    padding-top: 7px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }

.featured-reviews {
  padding: 0 30px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .featured-reviews {
      display: grid;
      width: 757px;
      padding: 0;
      grid-gap: 48px;
      grid-template-columns: 354px 354px; } }
  @media (min-width: 64rem) {
    .featured-reviews {
      width: 940px;
      grid-template-columns: 446px 446px; } }
  @media (min-width: 80rem) {
    .featured-reviews {
      width: 1105px;
      grid-template-columns: 528px 528px; } }

.search__container {
  padding-bottom: 3.125rem;
  background-color: #f8f8f8; }
  @media (min-width: 64.0625rem) {
    .search__container {
      padding-bottom: 5.125rem; } }

.search__top-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.search-bar__container {
  background-color: #fff;
  border-top: 1px solid #e7e7e7; }
  .search-bar__container .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

.search-bar__text-input {
  margin: 0;
  padding: 0;
  line-height: 1;
  resize: none;
  border: 0;
  background: transparent;
  color: #000;
  font-family: inherit;
  width: 80%;
  padding: 1.25rem 0;
  border: 0;
  background-color: #fff;
  color: #000;
  font-size: 1.375rem;
  caret-color: #000; }
  .search-bar__text-input:focus {
    outline: none; }
  .search-bar__text-input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .search-bar__text-input::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .search-bar__text-input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .search-bar__text-input:-moz-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  @media (min-width: 64.0625rem) {
    .search-bar__text-input {
      width: 90%;
      padding: 1.875rem 0;
      font-size: 1.875rem; } }
  .search-bar__text-input::-webkit-input-placeholder {
    color: grey; }
  .search-bar__text-input::-moz-placeholder {
    color: grey; }
  .search-bar__text-input:-ms-input-placeholder {
    color: grey; }
  .search-bar__text-input::-ms-input-placeholder {
    color: grey; }
  .search-bar__text-input::placeholder {
    color: grey; }

.search-bar__action-button {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: #fff;
  font-size: 0.75rem;
  text-transform: uppercase; }
  .search-bar__action-button[disabled] {
    opacity: 0.5; }
  @media (min-width: 64.0625rem) {
    .search-bar__action-button {
      color: #fff; } }

.search-media-tabs__container {
  margin: 2.25rem 0; }

.search-media-tabs__tab {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: #999;
  font-size: 1.375rem;
  text-transform: capitalize; }
  .search-media-tabs__tab[disabled] {
    opacity: 0.5; }
  @media (min-width: 64.0625rem) {
    .search-media-tabs__tab {
      font-size: 1.875rem; } }
  .search-media-tabs__tab + .search-media-tabs__tab {
    margin-left: 1.125rem; }
    @media (min-width: 64.0625rem) {
      .search-media-tabs__tab + .search-media-tabs__tab {
        margin-left: 1.6875rem; } }
  .search-media-tabs__tab--selected {
    padding-bottom: 0.3125rem;
    color: #000; }

@media (min-width: 64.0625rem) {
  .search-films__wrapper {
    min-height: 70vh; } }

.search-films__cards-contain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.search__results-count {
  color: #fff;
  font-size: 1.125rem; }

.sub-nav {
  z-index: 100;
  position: fixed;
  top: 71px;
  overflow: hidden;
  width: 100%;
  height: 0;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .sub-nav {
      -webkit-transition: height 200ms ease-in;
      transition: height 200ms ease-in;
      top: 100px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; } }
  .sub-nav__open {
    height: calc(100% - 71px);
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1); }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .sub-nav__open {
        border-top: 1px solid #f0f0f0;
        height: 432px; } }
    @media (min-height: 1000px) {
      .sub-nav__open {
        height: 540px; } }
  .sub-nav h2 {
    font-family: "HelveticaNeueLTStd-Cn";
    margin-top: 22px;
    margin-bottom: 28px;
    display: none;
    color: #999;
    font-size: 13px;
    text-transform: uppercase; }
    @media (min-height: 1000px) {
      .sub-nav h2 {
        margin-top: 40px;
        margin-bottom: 48px; } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .sub-nav h2 {
        display: block; } }
  .sub-nav ul {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-family: "HelveticaNeueLTStd-Md";
    margin-top: 30px; }
    .sub-nav ul li {
      list-style: none; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .sub-nav ul {
        margin-top: 0; } }
    .sub-nav ul li {
      margin-bottom: 5px;
      padding: 20px 30px;
      font-size: 18px;
      opacity: 0;
      -webkit-transition: all 500ms;
      transition: all 500ms; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .sub-nav ul li {
          margin-bottom: 5px;
          padding: 0 0 30px 0;
          font-size: 16px;
          -webkit-transition: opacity 300ms;
          transition: opacity 300ms; } }
      .sub-nav ul li a {
        color: #1a1a1a;
        text-decoration: none; }
        .sub-nav ul li a:hover {
          color: #f50000; }
  .sub-nav .side-area {
    width: 100%; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .sub-nav .side-area {
        width: 40%; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .sub-nav .side-area__left {
      padding-left: 30px; } }
  @media (min-width: 64rem) {
    .sub-nav .side-area__left {
      padding-left: 80px; } }
  @media (min-width: 71.875rem) {
    .sub-nav .side-area__left {
      padding-left: 100px; } }
  @media (min-width: 80rem) {
    .sub-nav .side-area__left {
      padding-left: 140px; } }
  .sub-nav.sub-nav__sidebar-link .side-area__right {
    background-size: contain;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center; }
  .sub-nav .side-area__right {
    display: none;
    width: 100%;
    background-color: #ddd;
    background-size: cover; }
    .sub-nav .side-area__right-link {
      width: 100%;
      height: 100%;
      display: block; }
      .sub-nav .side-area__right-link:hover {
        background-color: #fff;
        opacity: 0.1; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .sub-nav .side-area__right-link {
          margin-left: -30px;
          width: calc(100% + 30px); } }
      @media (min-width: 64rem) {
        .sub-nav .side-area__right-link {
          margin-left: -30px;
          width: calc(100% + 30px); } }
      @media (min-width: 71.875rem) {
        .sub-nav .side-area__right-link {
          margin-left: -80px;
          width: calc(100% + 80px); } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .sub-nav .side-area__right {
        display: block;
        width: 60%;
        padding-left: 30px;
        height: 431px; } }
    @media (min-height: 1000px) {
      .sub-nav .side-area__right {
        height: 539px; } }
    @media (min-width: 64rem) {
      .sub-nav .side-area__right {
        padding-left: 30px; } }
    @media (min-width: 71.875rem) {
      .sub-nav .side-area__right {
        padding-left: 80px; } }
    .sub-nav .side-area__right h2 {
      font-family: "PlayfairDisplay-Bold";
      margin-top: 40px;
      color: #fff;
      font-size: 24px;
      text-transform: initial; }
    .sub-nav .side-area__right .film-card__link {
      display: inline-block;
      background-color: #000; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .sub-nav .side-area__right .film-card__link {
          width: 260px;
          height: 158px; }
          .sub-nav .side-area__right .film-card__link:first-of-type {
            margin-right: 20px; }
          .sub-nav .side-area__right .film-card__link:nth-of-type(2) {
            display: none; } }
      @media (min-width: 60rem) {
        .sub-nav .side-area__right .film-card__link {
          width: 323px;
          height: 198px; }
          .sub-nav .side-area__right .film-card__link:first-of-type {
            margin-right: 20px; } }
      @media (min-width: 64rem) {
        .sub-nav .side-area__right .film-card__link {
          width: 280px; }
          .sub-nav .side-area__right .film-card__link:nth-of-type(2) {
            display: inline-block; } }
      @media (min-width: 90rem) {
        .sub-nav .side-area__right .film-card__link {
          width: 300px;
          height: 238px; } }
      @media (min-width: 96.875rem) {
        .sub-nav .side-area__right .film-card__link {
          width: 378px;
          height: 300px; }
          .sub-nav .side-area__right .film-card__link:first-of-type {
            margin-right: 40px; } }
    .sub-nav .side-area__right .film-card {
      margin: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      background-color: transparent; }
    .sub-nav .side-area__right .film-card__image {
      width: 189px; }
      @media (max-width: 90rem) {
        .sub-nav .side-area__right .film-card__image {
          height: 238px; } }
    .sub-nav .side-area__right .film-card.ratings::before {
      right: 38px;
      bottom: 10px;
      background-color: transparent; }
    .sub-nav .side-area__right .film-card__caption {
      font-family: "HelveticaNeueLTStd-MdCn";
      width: 189px;
      padding: 28px 0 0 30px;
      background-color: #fff;
      font-size: 17px; }
      .sub-nav .side-area__right .film-card__caption .caption-year {
        display: block;
        color: #bbb; }
    .sub-nav .side-area__right .score-percent {
      right: 20px;
      bottom: 18px;
      font-size: 26px; }
      .sub-nav .side-area__right .score-percent .cherry-check__top-icon {
        -webkit-transform: translateY(7px);
                transform: translateY(7px);
        margin-left: 6px;
        width: 36px; }

.sub-nav-cover {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 1000vh;
  border: 0;
  background-color: rgba(0, 0, 0, 0.8); }

.sub-nav__open ul li {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1; }
  @media (max-width: 47.9375rem) {
    .sub-nav__open ul li {
      -webkit-transition: all 500ms;
      transition: all 500ms; } }

.nav-mobile-films h2 {
  font-family: "NoeDisplay-Medium";
  color: #fff;
  text-align: left; }

.nav-mobile-films__link .nav-mobile-films__contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f8f8f8; }

.nav-mobile-films__contain {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: calc(100vh - 290px);
  background-size: cover;
  background-color: #ddd;
  overflow: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 23.375rem) {
    .nav-mobile-films__contain {
      padding-bottom: calc(100vh - 313px); } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .nav-mobile-films__contain {
      display: none; } }
  .nav-mobile-films__contain h2 {
    font-family: "PlayfairDisplay-Bold";
    padding-left: 30px;
    color: #fff;
    font-size: 24px;
    text-transform: initial; }
  .nav-mobile-films__contain-link {
    width: 100%;
    height: 100%;
    text-decoration: none; }
    .nav-mobile-films__contain-link:hover {
      background-color: #fff;
      opacity: 0.1; }
  .nav-mobile-films__contain .film-card__link {
    display: inline-block;
    width: 310px;
    height: 160px;
    background-color: #000;
    margin-left: 30px; }
    .nav-mobile-films__contain .film-card__link:nth-of-type(2) {
      display: none; }
    @media (min-width: 23.5rem) {
      .nav-mobile-films__contain .film-card__link {
        width: 160px;
        height: 200px; }
        .nav-mobile-films__contain .film-card__link:nth-of-type(2) {
          display: inline-block; }
        .nav-mobile-films__contain .film-card__link:first-of-type {
          margin-right: 20px; } }
  .nav-mobile-films__contain .film-card {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: transparent; }
  .nav-mobile-films__contain .film-card__image {
    width: 108px;
    height: 160px; }
    @media (min-width: 23.5rem) {
      .nav-mobile-films__contain .film-card__image {
        width: 160px;
        height: 230px; } }
  .nav-mobile-films__contain .film-card.ratings::before {
    right: 38px;
    bottom: 10px;
    background-color: transparent; }
  .nav-mobile-films__contain .film-card__caption {
    font-family: "HelveticaNeueLTStd-MdCn";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 202px;
    height: 160px;
    background-color: #fff;
    font-size: 14px;
    text-align: left; }
    @media (min-width: 23.5rem) {
      .nav-mobile-films__contain .film-card__caption {
        right: auto;
        left: 0;
        width: 160px;
        height: 50px; } }
    .nav-mobile-films__contain .film-card__caption .caption-year {
      display: block;
      color: #bbb; }
  .nav-mobile-films__contain .score-percent {
    right: 10px;
    bottom: 10px;
    font-size: 15px; }
    .nav-mobile-films__contain .score-percent .cherry-check__top-icon {
      -webkit-transform: translateY(7px);
              transform: translateY(7px);
      margin-left: 6px;
      width: 24px; }

.splash__nav-extra {
  z-index: 10;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 12px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .splash__nav-extra {
      padding: 37px 34px;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  .splash__nav-extra .home-header__be-critic svg {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    margin-left: 4px; }
  .splash__nav-extra .home-header__be-critic:hover svg {
    -webkit-transform: translate(6px, -1px);
            transform: translate(6px, -1px); }
  .splash__nav-extra a {
    display: none;
    color: #fff;
    text-decoration: none; }
    .splash__nav-extra a:hover {
      color: #f50000; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .splash__nav-extra a {
        display: block; } }
  .splash__nav-extra img {
    position: absolute;
    top: 19px;
    right: 0;
    left: 0;
    margin: auto;
    display: block; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .splash__nav-extra img {
        top: 34px; } }

body.page-splash .main-header {
  opacity: 1;
  background: transparent;
  border-bottom: 0; }
  body.page-splash .main-header .main-header__logo {
    opacity: 0; }
  body.page-splash .main-header .main-header__toggle path {
    stroke: #fff; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    body.page-splash .main-header {
      pointer-events: none;
      opacity: 0; } }

body.page-splash.nav-opened .main-header {
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7; }
  body.page-splash.nav-opened .main-header .main-header__logo {
    opacity: 1; }
  body.page-splash.nav-opened .main-header .main-header__toggle path {
    stroke: #000; }

body.page-splash .app__main-container {
  overflow: hidden;
  height: calc(101vh);
  padding-top: 0; }

body.page-splash footer {
  display: none; }

.header-fixer {
  position: fixed;
  width: 100vw;
  height: 101vh; }

.stream-link {
  margin-right: 8px;
  display: inline-block;
  width: 35px;
  height: 35px;
  border: 1px solid #bbbbbb;
  border-radius: 100%;
  background-position: 49% 49%;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  opacity: 0.8; }
  .stream-link:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    opacity: 1; }
  .stream-link.stream-link--itunes-apple-com {
    background-image: url(/assets/apple-black.png); }
  .stream-link.stream-link--amazon-com {
    background-image: url(/assets/amazon-black.png); }
  .stream-link.stream-link--fandangonow-com {
    background-image: url(/assets/fandango-black.png); }
  .stream-link.stream-link--flixfling-com {
    background-image: url(/assets/flixfling-black.png); }
  .stream-link.stream-link--netflix-com {
    background-image: url(/assets/netflix-black.png); }
  .stream-link.stream-link--hulu-com {
    background-image: url(/assets/hulu-black.png); }
  .stream-link.stream-link--hbo-com {
    background-image: url(/assets/hbo-black.png); }
  .stream-link.stream-link--play-google-com {
    background-image: url(/assets/google-black.png);
    background-position: 60% 49%; }
  .stream-link.stream-link--vudu-com {
    background-image: url(/assets/vudu-black.svg); }
  .stream-link.stream-link--microsoft-com {
    background-image: url(/assets/microsoft-black.png); }
  .stream-link.stream-link--youtube-com {
    background-image: url(/assets/youtube-black.png); }
  .stream-link.stream-link--fye-com {
    background-image: url(/assets/fye-black.png); }
  .stream-link.stream-link--pureflix-com {
    background-image: url(/assets/pureflix-black.png); }
  .stream-link.stream-link--therokuchannel-roku-com {
    background-image: url(/assets/roku-black.png); }
  .stream-link.stream-link--roku-com {
    background-image: url(/assets/roku-black.png); }
  .stream-link.stream-link--showtime-com {
    background-image: url(/assets/showtime-black.png); }
  .stream-link.stream-link--starz-com {
    background-image: url(/assets/starz-black.png); }
  .stream-link.stream-link--target-com {
    background-image: url(/assets/target-black.png); }
  .stream-link.stream-link--sundancenow-com {
    background-image: url(/assets/sundancenow-black.png); }
  .film-card-stacked .stream-link.stream-link--itunes-apple-com {
    background-image: url(/assets/apple-white.png); }
  .film-card-stacked .stream-link.stream-link--amazon-com {
    background-image: url(/assets/amazon-white.png); }
  .film-card-stacked .stream-link.stream-link--fandangonow-com {
    background-image: url(/assets/fandango-white.png); }
  .film-card-stacked .stream-link.stream-link--flixfling-com {
    background-image: url(/assets/flixfling-white.png); }
  .film-card-stacked .stream-link.stream-link--netflix-com {
    background-image: url(/assets/netflix-white.png); }
  .film-card-stacked .stream-link.stream-link--hulu-com {
    background-image: url(/assets/hulu-white.png); }
  .film-card-stacked .stream-link.stream-link--hbo-com {
    background-image: url(/assets/hbo-white.png); }
  .film-card-stacked .stream-link.stream-link--play-google-com {
    background-image: url(/assets/google-white.png);
    background-position: 60% 49%; }
  .film-card-stacked .stream-link.stream-link--vudu-com {
    background-image: url(/assets/vudu-white.svg); }
  .film-card-stacked .stream-link.stream-link--microsoft-com {
    background-image: url(/assets/microsoft-white.png); }
  .film-card-stacked .stream-link.stream-link--youtube-com {
    background-image: url(/assets/youtube-white.png); }
  .film-card-stacked .stream-link.stream-link--fye-com {
    background-image: url(/assets/fye-white.png); }
  .film-card-stacked .stream-link.stream-link--pureflix-com {
    background-image: url(/assets/pureflix-white.png); }
  .film-card-stacked .stream-link.stream-link--roku-com {
    background-image: url(/assets/roku-white.png); }
  .film-card-stacked .stream-link.stream-link--therokuchannel-roku-com {
    background-image: url(/assets/roku-white.png); }
  .film-card-stacked .stream-link.stream-link--showtime-com {
    background-image: url(/assets/showtime-white.png); }
  .film-card-stacked .stream-link.stream-link--starz-com {
    background-image: url(/assets/starz-white.png); }
  .film-card-stacked .stream-link.stream-link--target-com {
    background-image: url(/assets/target-white.png); }
  .film-card-stacked .stream-link.stream-link--sundancenow-com {
    background-image: url(/assets/sundancenow-white.png); }

.social-share__container {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e7e7e7; }

.social-share__list {
  margin: 0;
  padding: 0;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .social-share__list li {
    list-style: none; }

button.social-share__link {
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: 0;
  border: 0;
  background: none;
  font-family: inherit;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: #777; }
  button.social-share__link[disabled] {
    opacity: 0.5; }
  button.social-share__link:hover {
    color: #f50000; }

.social-share__item {
  width: 36px;
  height: 36px;
  border: 1px solid #bbb;
  border-radius: 100%;
  color: #777;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  cursor: pointer; }
  .social-share__item:hover {
    color: #f50000; }
  .social-share__item + .social-share__item {
    margin-left: 20px; }

.styled-collection {
  position: relative;
  background-color: #808080; }
  .styled-collection h1 {
    font-family: "DINPro-CondBlack";
    margin: 0;
    width: 100%;
    font-size: 70px;
    line-height: 50px;
    text-transform: uppercase; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection h1 {
        font-size: 120px;
        line-height: 84px; } }
    @media (min-width: 64rem) {
      .styled-collection h1 {
        font-size: 144px;
        line-height: 108px; } }
  .styled-collection .film-collection__view-all {
    font-family: "HelveticaNeueLTStd-MdCn";
    position: absolute;
    right: 0;
    bottom: 83px;
    left: 0;
    display: inline-block;
    width: auto;
    color: #fff;
    font-size: 15px;
    text-align: center;
    text-decoration: none; }

.styled-collection--centered-horizontal {
  background-color: #808080; }
  .styled-collection--centered-horizontal .film-card {
    margin: 0;
    background-color: transparent; }
  .styled-collection--centered-horizontal .film-card__link {
    overflow: hidden;
    width: 160px;
    height: 253px;
    background-color: #000; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal .film-card__link {
        overflow: hidden;
        width: 189px;
        height: 299px;
        background-color: #000; } }
  .styled-collection--centered-horizontal .film-collection__view-all {
    font-family: "HelveticaNeueLTStd-MdCn";
    bottom: 96px;
    left: 33px;
    text-align: left; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal .film-collection__view-all {
        right: 0;
        bottom: 96px;
        left: 0;
        text-align: center; } }
  .styled-collection--centered-horizontal .film-collection {
    padding: 40px 0 180px 0;
    background-position: center;
    background-size: cover; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal .film-collection {
        padding: 100px 0 200px 0; } }
  .styled-collection--centered-horizontal .carousel {
    margin: auto; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal .carousel {
        padding-left: 0; } }
  .styled-collection--centered-horizontal .carousel__arrow {
    bottom: -100px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal .carousel__arrow {
        bottom: -114px; } }
  @media (max-width: 47.9375rem) {
    .styled-collection--centered-horizontal .carousel__arrow--prev {
      right: 92px;
      left: auto; }
    .styled-collection--centered-horizontal .carousel__arrow--next {
      right: 24px; } }
  .styled-collection--centered-horizontal h1 {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
    margin: 0;
    padding-left: 30px;
    color: #fff;
    text-align: left; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal h1 {
        -webkit-transform: translateY(35px);
                transform: translateY(35px);
        padding: 0 80px;
        text-align: center; } }
  .styled-collection--centered-horizontal .film-card.ratings::before {
    right: 38px;
    bottom: 79px;
    background-color: transparent; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal .film-card.ratings::before {
        bottom: 75px; } }
  .styled-collection--centered-horizontal .film-card.ratings--timer::before {
    right: 10px;
    bottom: 77px;
    opacity: 1; }
  .styled-collection--centered-horizontal .film-card__caption .score-percent {
    bottom: 10px; }
  .styled-collection--centered-horizontal .film-card__caption {
    -webkit-transform: translateY(-67px);
            transform: translateY(-67px);
    width: 100%;
    background-color: #fff; }
  .styled-collection--centered-horizontal .slick-slide {
    padding: 0; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal .slick-slide {
        padding: 0; } }
    .styled-collection--centered-horizontal .slick-slide picture {
      width: 160px;
      height: 234px; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .styled-collection--centered-horizontal .slick-slide picture {
          width: 189px;
          height: 276px;
          opacity: 1;
          -webkit-transition: opacity 400ms;
          transition: opacity 400ms; } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal .slick-slide .ratings::before {
        opacity: 1;
        -webkit-transition: opacity 400ms;
        transition: opacity 400ms; } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--centered-horizontal .slick-slide figcaption span,
      .styled-collection--centered-horizontal .slick-slide figcaption div {
        opacity: 1;
        -webkit-transition: opacity 200ms;
        transition: opacity 200ms; } }
  .styled-collection--centered-horizontal .slick-active picture {
    opacity: 1;
    -webkit-transition: opacity 800ms;
    transition: opacity 800ms; }
  .styled-collection--centered-horizontal .slick-active .ratings::before {
    opacity: 1;
    -webkit-transition: opacity 500ms;
    transition: opacity 500ms;
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms; }
  .styled-collection--centered-horizontal .slick-active figcaption span,
  .styled-collection--centered-horizontal .slick-active figcaption div {
    opacity: 1;
    -webkit-transition: opacity 500ms;
    transition: opacity 500ms;
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms; }

.styled-collection--left-stacked {
  background-color: #151515; }
  .styled-collection--left-stacked .film-collection {
    padding: 72px 0 140px 0; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--left-stacked .film-collection {
        padding: 90px 0 116px 0; } }
  .styled-collection--left-stacked .film-card {
    position: relative;
    margin: 0;
    background-color: transparent; }
  .styled-collection--left-stacked .film-collection__view-all {
    font-family: "HelveticaNeueLTStd-MdCn";
    bottom: 94px;
    left: 33px;
    text-align: left; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--left-stacked .film-collection__view-all {
        right: 0;
        bottom: 75px;
        left: 0;
        text-align: center; } }
  .styled-collection--left-stacked .carousel__arrow {
    bottom: -60px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--left-stacked .carousel__arrow {
        bottom: -52px; } }
  @media (max-width: 47.9375rem) {
    .styled-collection--left-stacked .carousel__arrow--prev {
      right: 92px;
      left: auto; }
    .styled-collection--left-stacked .carousel__arrow--next {
      right: 24px; } }
  .styled-collection--left-stacked .film-card__link {
    overflow: hidden;
    width: 160px;
    height: 253px;
    -webkit-box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.4);
            box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.4);
    background-color: #000; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--left-stacked .film-card__link {
        width: 189px;
        height: 299px; } }
  .styled-collection--left-stacked h1 {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
    padding-left: 30px;
    color: #020202;
    font-size: 70px;
    line-height: 50px;
    text-align: left;
    width: 100%;
    margin: auto; }
    .styled-collection--left-stacked h1.secondTitle {
      color: #303030; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--left-stacked h1 {
        -webkit-transform: translateY(30px);
                transform: translateY(30px);
        padding-left: 1px;
        font-size: 100px;
        line-height: 71px;
        width: 636px; } }
    @media (min-width: 60rem) {
      .styled-collection--left-stacked h1 {
        font-size: 144px;
        line-height: 102px;
        width: 864px; } }
    @media (min-width: 71.875rem) {
      .styled-collection--left-stacked h1 {
        width: 1086px; } }
    @media (min-width: 125rem) {
      .styled-collection--left-stacked h1 {
        width: 1462px; } }
  .styled-collection--left-stacked .slick-list,
  .styled-collection--left-stacked .slick-track,
  .styled-collection--left-stacked .slick-slide {
    padding-bottom: 20px; }
  .styled-collection--left-stacked .film-card.ratings::before {
    bottom: 75px;
    background-color: transparent; }
  .styled-collection--left-stacked .film-card.ratings--timer::before {
    right: 10px;
    bottom: 77px;
    opacity: 1; }
  .styled-collection--left-stacked .film-card__caption .score-percent {
    bottom: 10px; }
  .styled-collection--left-stacked .film-card__caption {
    -webkit-transform: translateY(-67px);
            transform: translateY(-67px);
    width: 100%;
    background-color: #151515;
    color: #fff; }
  .styled-collection--left-stacked .slick-slide {
    padding: 0; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--left-stacked .slick-slide {
        padding: 0; } }
    .styled-collection--left-stacked .slick-slide picture {
      width: 160px;
      height: 234px; }
      @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
        .styled-collection--left-stacked .slick-slide picture {
          width: 189px;
          height: 276px;
          opacity: 1;
          -webkit-transition: opacity 400ms;
          transition: opacity 400ms; } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--left-stacked .slick-slide .ratings::before {
        opacity: 1;
        -webkit-transition: opacity 200ms;
        transition: opacity 200ms; } }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--left-stacked .slick-slide figcaption span,
      .styled-collection--left-stacked .slick-slide figcaption div {
        opacity: 1;
        -webkit-transition: opacity 200ms;
        transition: opacity 200ms; } }
  .styled-collection--left-stacked .slick-active picture {
    opacity: 1;
    -webkit-transition: opacity 800ms;
    transition: opacity 800ms; }
  .styled-collection--left-stacked .slick-active .ratings::before {
    opacity: 1;
    -webkit-transition: opacity 500ms;
    transition: opacity 500ms;
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms; }
  .styled-collection--left-stacked .slick-active figcaption span,
  .styled-collection--left-stacked .slick-active figcaption div {
    opacity: 1;
    -webkit-transition: opacity 500ms;
    transition: opacity 500ms;
    -webkit-transition-delay: 500ms;
            transition-delay: 500ms; }

.styled-collection--four-up-stacked {
  width: 100%;
  padding-bottom: 90px; }
  .styled-collection--four-up-stacked .film-collection {
    padding-top: 0; }
  @media (max-width: 47.9375rem) {
    .styled-collection--four-up-stacked {
      padding-bottom: 35px; }
      .styled-collection--four-up-stacked .carousel__item--film {
        padding: 0 28px; } }
  .styled-collection--four-up-stacked .film-collection__view-all {
    font-family: "HelveticaNeueLTStd-MdCn";
    bottom: 42px;
    left: 33px;
    text-align: left; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--four-up-stacked .film-collection__view-all {
        right: 0;
        bottom: 110px;
        left: 0;
        -webkit-transform: translateY(43px);
                transform: translateY(43px);
        text-align: center; } }
  .styled-collection--four-up-stacked h1 {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
    margin: auto;
    padding-left: 32px;
    color: #fff;
    line-height: 0.75;
    text-align: left;
    opacity: 0.15; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--four-up-stacked h1 {
        -webkit-transform: translateY(42px);
                transform: translateY(42px);
        max-width: 600px;
        padding-left: 0;
        text-align: center; } }
    @media (min-width: 64rem) {
      .styled-collection--four-up-stacked h1 {
        -webkit-transform: translateY(125px);
                transform: translateY(125px);
        max-width: 860px; } }
  .styled-collection--four-up-stacked .caption-title a {
    color: #f8f8f8;
    text-decoration: none; }
  .styled-collection--four-up-stacked .four-up-stacked {
    margin: auto;
    margin-top: 62px;
    max-width: 1200px; }
    @media (min-width: 125rem) {
      .styled-collection--four-up-stacked .four-up-stacked {
        max-width: 2000px; } }
  .styled-collection--four-up-stacked .carousel__arrow {
    bottom: -97px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .styled-collection--four-up-stacked .carousel__arrow {
        bottom: -74px; } }
  @media (max-width: 47.9375rem) {
    .styled-collection--four-up-stacked .carousel__arrow--prev {
      right: 92px;
      left: auto; }
    .styled-collection--four-up-stacked .carousel__arrow--next {
      right: 24px; } }
  .styled-collection--four-up-stacked .carousel__item--film {
    padding: 19px 25px; }
  .styled-collection--four-up-stacked .slick-slide {
    opacity: 0;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms; }
    .styled-collection--four-up-stacked .slick-slide .ratings {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: center left;
              transform-origin: center left;
      -webkit-transition: -webkit-transform 400ms;
      transition: -webkit-transform 400ms;
      transition: transform 400ms;
      transition: transform 400ms, -webkit-transform 400ms;
      -webkit-transition-delay: 200ms;
              transition-delay: 200ms; }
      .styled-collection--four-up-stacked .slick-slide .ratings .caption-title {
        opacity: 0;
        -webkit-transition: opacity 120ms;
        transition: opacity 120ms;
        -webkit-transition-delay: 30ms;
                transition-delay: 30ms; }
      .styled-collection--four-up-stacked .slick-slide .ratings .caption-release {
        opacity: 0;
        -webkit-transition: opacity 120ms;
        transition: opacity 120ms;
        -webkit-transition-delay: 70ms;
                transition-delay: 70ms; }
      .styled-collection--four-up-stacked .slick-slide .ratings .score-percent {
        opacity: 0;
        -webkit-transition: opacity 120ms;
        transition: opacity 120ms;
        -webkit-transition-delay: 120ms;
                transition-delay: 120ms; }
      .styled-collection--four-up-stacked .slick-slide .ratings::before {
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transition: -webkit-transform 120ms;
        transition: -webkit-transform 120ms;
        transition: transform 120ms;
        transition: transform 120ms, -webkit-transform 120ms;
        -webkit-transition-delay: 120ms;
                transition-delay: 120ms; }
  .styled-collection--four-up-stacked .slick-active {
    opacity: 1; }
    .styled-collection--four-up-stacked .slick-active .ratings {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: center left;
              transform-origin: center left;
      -webkit-transition: -webkit-transform 800ms;
      transition: -webkit-transform 800ms;
      transition: transform 800ms;
      transition: transform 800ms, -webkit-transform 800ms;
      -webkit-transition-delay: 400ms;
              transition-delay: 400ms; }
      .styled-collection--four-up-stacked .slick-active .ratings .caption-title {
        opacity: 1;
        -webkit-transition: opacity 400ms;
        transition: opacity 400ms;
        -webkit-transition-delay: 1150ms;
                transition-delay: 1150ms; }
      .styled-collection--four-up-stacked .slick-active .ratings .caption-release {
        opacity: 1;
        -webkit-transition: opacity 400ms;
        transition: opacity 400ms;
        -webkit-transition-delay: 1100ms;
                transition-delay: 1100ms; }
      .styled-collection--four-up-stacked .slick-active .ratings .score-percent {
        opacity: 1;
        -webkit-transition: opacity 400ms;
        transition: opacity 400ms;
        -webkit-transition-delay: 1050ms;
                transition-delay: 1050ms; }
      .styled-collection--four-up-stacked .slick-active .ratings::before {
        -webkit-transform: scale(1);
                transform: scale(1);
        -webkit-transition: -webkit-transform 300ms;
        transition: -webkit-transform 300ms;
        transition: transform 300ms;
        transition: transform 300ms, -webkit-transform 300ms;
        -webkit-transition-delay: 1250ms;
                transition-delay: 1250ms; }

.tag {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  padding: 8px 20px 4px 20px;
  border: 1px solid #bbb;
  font-family: "HelveticaNeueLTStd-Cn";
  border-radius: 14px;
  background: #fff;
  color: #555;
  font-size: 13px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  cursor: pointer; }
  .tag:hover {
    border-color: #f50000;
    color: #f50000; }

.carousel-preview-interior {
  display: grid;
  grid-gap: 44px;
  grid-auto-rows: 300px;
  grid-template-columns: repeat(1, 189px); }
  .carousel-preview-interior .carousel__item {
    display: none; }
  .carousel-preview-interior .carousel__item:nth-child(-n + 1) {
    display: block; }
  @media (min-width: 45.625rem) {
    .carousel-preview-interior {
      width: 422px;
      grid-template-columns: repeat(2, 189px); }
      .carousel-preview-interior .carousel__item:nth-child(-n + 2) {
        display: block; } }
  @media (min-width: 60rem) {
    .carousel-preview-interior {
      width: 655px;
      grid-template-columns: repeat(3, 189px); }
      .carousel-preview-interior .carousel__item:nth-child(-n + 3) {
        display: block; } }
  @media (min-width: 80rem) {
    .carousel-preview-interior {
      width: 888px;
      grid-template-columns: repeat(4, 189px); }
      .carousel-preview-interior .carousel__item:nth-child(-n + 4) {
        display: block; } }
  @media (min-width: 71.875rem) {
    .carousel-preview-interior {
      width: 1121px;
      grid-template-columns: repeat(5, 189px); }
      .carousel-preview-interior .carousel__item:nth-child(-n + 5) {
        display: block; } }
  @media (min-width: 125rem) {
    .carousel-preview-interior {
      width: 1543px;
      grid-template-columns: repeat(7, 189px); }
      .carousel-preview-interior .carousel__item:nth-child(-n + 7) {
        display: block; } }

.article {
  background: #fff; }

.article-related {
  overflow: hidden;
  padding-top: 4rem;
  padding-bottom: 1.25rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .article-related {
      padding-top: 5rem;
      padding-bottom: 3.125rem; } }

.rich-text img {
  max-width: 100%;
  height: auto; }

.articles-carousel {
  padding-top: 3.75rem;
  padding-bottom: 1.875rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .articles-carousel {
      padding-top: 4.375rem;
      padding-bottom: 3.125rem; } }

.author-articles__heading {
  font-family: "HelveticaNeueLTStd-BdCn";
  margin: auto;
  margin-top: 40px;
  margin-bottom: 30px;
  width: 1200px;
  font-size: 12px;
  text-align: left;
  text-transform: uppercase; }

.articles-articles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 100px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .articles-articles .article-card__container {
      display: grid;
      grid-gap: 36px;
      grid-template-columns: repeat(2, 350px); } }
  @media (min-width: 64rem) {
    .articles-articles .article-card__container {
      display: grid;
      grid-gap: 36px;
      grid-template-columns: repeat(2, 350px); } }
  @media (min-width: 80rem) {
    .articles-articles .article-card__container {
      display: grid;
      grid-gap: 36px;
      grid-template-columns: repeat(3, 376px); } }

.articles-recent {
  position: relative;
  padding-top: 2.625rem;
  padding-bottom: 1.25rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .articles-recent {
      overflow: hidden;
      padding-top: 4.75rem;
      padding-bottom: 3.125rem; } }

.btn--all-articles {
  margin: auto;
  margin-bottom: 80px;
  display: block;
  line-height: 52px;
  text-align: center; }

.homepage-hero-text {
  margin-top: 80px;
  margin-bottom: 20px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .homepage-hero-text {
      margin-top: 160px; } }
  .homepage-hero-text h1 {
    font-family: "DINPro-CondBlack";
    font-size: 80px;
    letter-spacing: -2px;
    line-height: 0.75;
    text-align: left;
    text-transform: uppercase; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .homepage-hero-text h1 {
        text-align: center;
        font-size: 120px; } }
    @media (min-width: 64rem) {
      .homepage-hero-text h1 {
        font-size: 144px; } }

.homepage-feature {
  padding-top: 1.5625rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .homepage-feature {
      padding-top: 1.75rem; } }

.page-loaded .buffer {
  height: 0;
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms;
  -webkit-transition: height 900ms;
  transition: height 900ms; }

.buffer {
  width: 100%;
  height: 100vh;
  background-color: white; }

.float-block {
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 112px;
  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  text-decoration: none; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .float-block {
      right: 30px;
      bottom: 30px;
      left: auto;
      width: 252px; } }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .float-block--pinned {
      position: absolute;
      bottom: 442px; } }
  .float-block a {
    text-decoration: none; }
  .float-block .title {
    font-family: "HelveticaNeueLTStd-MdCn";
    text-decoration: none;
    margin-top: 20px;
    color: #000; }
  .float-block .date {
    font-family: "HelveticaNeueLTStd-Cn";
    text-decoration: none;
    color: #555;
    font-size: 12px; }
  .float-block .closeX {
    margin: 0;
    padding: 0;
    line-height: 1;
    outline: 0;
    border: 0;
    background: none;
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    position: absolute;
    top: 0;
    right: 0;
    color: #000;
    font-size: 12px;
    z-index: 20;
    padding-right: 6px;
    padding-top: 6px; }
    .float-block .closeX[disabled] {
      opacity: 0.5; }
    .float-block .closeX:hover {
      color: #f50000; }
  .float-block .link {
    font-family: "HelveticaNeueLTStd-Cn";
    position: absolute;
    bottom: 8px;
    color: #000;
    font-size: 12px; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .float-block .link {
        right: 6px;
        bottom: 6px;
        left: auto; } }
  .float-block img {
    float: left;
    margin-right: 24px;
    -o-object-fit: cover;
       object-fit: cover; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .float-block img {
        margin-right: 12px; } }

.homepage-new-release {
  position: relative;
  padding-top: 60px;
  padding-bottom: 79px; }

.homepage-article {
  padding-top: 6.4375rem;
  background-color: #fff; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .homepage-article {
      padding-top: 6.375rem; } }

.homepage-secondary-carousel {
  padding-top: 3.75rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .homepage-secondary-carousel {
      padding-top: 5.625rem; } }

.is-last-section {
  padding: 3.75rem 0; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .is-last-section {
      padding: 5.625rem 0; } }

.homepage-secondary-carousel__cta {
  margin-top: 1.875rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .homepage-secondary-carousel__cta {
      margin-top: 3.25rem; } }

.homepage-featured__heading, .film-details-featured__heading {
  position: relative; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .homepage-featured__heading, .film-details-featured__heading {
      position: static; } }

.homepage-featured__header, .film-details-featured__header {
  max-width: 13.5rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .homepage-featured__header, .film-details-featured__header {
      max-width: 100%; } }

.homepage-featured-articles, .film-details-featured-articles {
  overflow: hidden;
  position: relative;
  padding-top: 3.75rem;
  padding-bottom: 3.125rem; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .homepage-featured-articles, .film-details-featured-articles {
      padding-top: 6.5625rem;
      padding-bottom: 3.125rem; } }

.not-found__container {
  margin-top: 2.375rem;
  margin-bottom: 0;
  color: #000; }
  @media (min-width: 64.0625rem) {
    .not-found__container {
      margin: 4.375rem 0; } }

.not-found__wrapper {
  margin: 0 auto;
  max-width: 26rem;
  padding: 0 2rem;
  text-align: center; }
  @media (min-width: 64.0625rem) {
    .not-found__wrapper {
      padding: 0; } }

.not-found__title {
  font-family: "NoeDisplay-Medium";
  margin-bottom: 2.375rem;
  color: #f50000;
  font-size: 3.125rem; }
  @media (min-width: 64.0625rem) {
    .not-found__title {
      margin-bottom: 4.375rem;
      font-size: 5rem; } }

.not-found__subtitle {
  font-family: "HelveticaNeueLTStd-Cn";
  margin-bottom: 1.5rem;
  font-size: 1.125rem; }
  @media (min-width: 64.0625rem) {
    .not-found__subtitle {
      margin-bottom: 4.375rem;
      font-size: 1.875rem; } }

.not-found__description {
  font-family: "HelveticaNeueLTStd-Lt";
  margin-bottom: 4rem; }
  @media (min-width: 64.0625rem) {
    .not-found__description {
      font-size: 1.25rem; } }

.not-found__link-wrapper {
  margin: 0 auto;
  margin-bottom: 3.125rem; }
  @media (min-width: 64.0625rem) {
    .not-found__link-wrapper {
      margin-bottom: 0; }
      .not-found__link-wrapper:hover {
        background-color: #5b1815; } }

.not-found__link {
  color: inherit;
  text-decoration: none;
  font-size: 14px; }

.page-stories .homepage-hero-text {
  margin-top: 80px;
  margin-bottom: 20px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .page-stories .homepage-hero-text {
      margin-top: 160px;
      margin-bottom: 150px; } }
  .page-stories .homepage-hero-text h1 {
    font-family: "NoeDisplay-Medium";
    font-size: 44px;
    line-height: 0.75;
    text-align: left;
    text-transform: unset;
    letter-spacing: 0; }
    @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
      .page-stories .homepage-hero-text h1 {
        text-align: center; } }

#root {
  overflow-x: hidden; }

.app__main-container {
  overflow: hidden;
  position: relative;
  margin: auto;
  display: block;
  max-width: 100%;
  min-height: 100vh;
  padding-top: 71px; }
  @media (min-width: 47.9375rem), (min-width: 900px) and (orientation: landscape) {
    .app__main-container {
      padding-top: 100px; } }

html.no-scroll {
  overflow-y: hidden; }
  html.no-scroll body {
    overflow-y: scroll; }
